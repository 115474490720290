<template>
  <a-modal
    centered
    title=""
    :width="560"
    :footer="null"
    :closable="false"
    :keyboard="false"
    :destroyOnClose="true"
    :visible="isModalVisible"
    :getContainer="() => $root.$el"
    :maskClosable="isMaskClosable"
    :wrapClassName="$style.modal"
    @cancel="handleCancel"
  >
    <keep-alive>
      <component :is="modalView" v-bind="$attrs" v-on="$listeners" />
    </keep-alive>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import { Modal } from "ant-design-vue";
import VerifyIdentity from "@/components/modal/VerifyIdentity.vue";
import IncreaseLimit from "@/components/modal/IncreaseLimit.vue";
import OopsDismiss from "@/components/modal/OopsDismiss.vue";
import OopsFailed from "@/components/modal/OopsFailed.vue";
import OopsPending from "@/components/modal/OopsPending.vue";
import DailyLimit from "@/components/modal/DailyLimit.vue";
import PurchasedImmediately from "@/components/modal/PurchasedImmediately.vue";
import PurchasedMediately from "@/components/modal/PurchasedMediately.vue";
import RemovePayment from "@/components/modal/RemovePayment.vue";
import SellSuccess from "@/components/modal/SellSuccess.vue";
import WithdrawSuccess from "@/components/modal/WithdrawSuccess.vue";
import Veriff from "@/components/modal/Veriff.vue";
import RejectedAccount from "@/components/modal/RejectedAccount.vue";
import VerifyReviewing from "@/components/modal/VerifyReviewing.vue";
import CardDailyLimit from "@/components/modal/CardDailyLimit.vue";
import AddBankAccount from "@/components/modal/AddBankAccount.vue";
import EddPendingDeposit from "@/components/modal/EddPendingDeposit.vue";
import EddError from "@/components/modal/EddError.vue";
import RiskError from "@/components/modal/RiskError.vue";
import RfiPendingDeposit from "@/components/modal/RfiPendingDeposit.vue";
import PurchasedProcessed from "@/components/modal/PurchasedProcessed.vue";
import PurchasedPending from "@/components/modal/PurchasedPending.vue";
import EnterCvv from "@/components/modal/EnterCvv.vue";
import RfiError from "@/components/modal/RfiError.vue";
import RefreshNeededPending from "@/components/modal/RefreshNeededPending.vue";
import PurchasedProcessed4AchPull from "@/components/modal/PurchasedProcessed4AchPull.vue";
import AchOrderError from "@/components/modal/AchOrderError.vue";
import ReLinkAccount from "@/components/modal/ReLinkAccount.vue";
import MakeDeposit4AchPull from "@/components/modal/MakeDeposit4AchPull.vue";

export default {
  name: "ModalBase",
  inheritAttrs: false,
  props: {
    visible: Boolean
  },
  data() {
    return {};
  },
  components: {
    [Modal.name]: Modal,
    VerifyIdentity,
    IncreaseLimit,
    OopsDismiss,
    OopsFailed,
    OopsPending,
    DailyLimit,
    PurchasedImmediately,
    PurchasedMediately,
    RemovePayment,
    SellSuccess,
    WithdrawSuccess,
    Veriff,
    RejectedAccount,
    VerifyReviewing,
    CardDailyLimit,
    AddBankAccount,
    EddPendingDeposit,
    EddError,
    RiskError,
    RfiPendingDeposit,
    PurchasedProcessed,
    PurchasedPending,
    EnterCvv,
    RfiError,
    RefreshNeededPending,
    PurchasedProcessed4AchPull,
    AchOrderError,
    ReLinkAccount,
    MakeDeposit4AchPull
  },
  computed: {
    ...mapState({
      uiSize: state => state.clientInfo.uiSize,
      kycStatus: state => state.kycStatus,
      userPreference: state => state.user_preference,
      currencies: state => state.currencies,
      modalView: state => state.modalView
    }),
    isModalVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    },
    isMaskClosable() {
      return ["Veriff", "VerifyIdentity"].includes(this.modalView);
    }
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" module>
.modal {
  :global {
    .ant-modal-content {
      background: transparent;
      box-shadow: none;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}
</style>
