// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BannerLeft_banner_1EB_S{max-width:calc(2.875rem + 19.5vw);margin-bottom:calc(1.29375rem + .525vw);padding:1.125rem 1.25rem 1.25rem;background-color:var(--white);border-radius:.625rem;position:relative}@media(min-width:1200px){.BannerLeft_banner_1EB_S{max-width:17.5rem;margin-bottom:1.6875rem}}.BannerLeft_banner_1EB_S:before{border:2px solid var(--primary);border-radius:.625rem;content:\"\";position:absolute;top:4px;left:4px;right:4px;bottom:4px}.BannerLeft_banner_1EB_S a{text-decoration:underline}.BannerLeft_banner_1EB_S .BannerLeft_circle_19MhU{font-size:1rem;margin-right:.5rem;color:var(--success)}.BannerLeft_banner_1EB_S .ant-list-sm .ant-list-item{padding-bottom:2px;padding-top:2px}", ""]);
// Exports
exports.locals = {
	"banner": "BannerLeft_banner_1EB_S",
	"circle": "BannerLeft_circle_19MhU"
};
module.exports = exports;
