<template>
  <section :class="$style.orderDetail">
    <div :class="$style.orderInfo">
      <span :class="$style.title">{{ $t("Order type") }}:</span>
      <div :class="$style.orderType">{{ $t(tradeSide) | uppercase }}</div>
    </div>
    <div :class="$style.orderInfo">
      <span :class="$style.title">{{ $t("Order amount") }}:</span>
      <div :class="$style.amountInfo">
        <span>{{ getOrderAmount }}</span>
      </div>
    </div>
    <div :class="$style.orderInfo">
      <span :class="$style.title">
        {{ $t("You will receive") }}:
        <span :class="$style.estimated">
          {{ isAchPullTabSelected ? `(${$t("Estimated")})` : "" }}
        </span>
      </span>

      <a-spin v-if="quoteLoadTimer">
        <a-icon slot="indicator" :component="IconWait" spin />
      </a-spin>
      <div v-else :class="$style.amountInfo">
        <span>{{ getReceiveCount }}</span>
      </div>
    </div>
    <div :class="$style.viewMoreContainer">
      <div :class="$style.viewMore" @click="handleViewMore">
        <span>{{ $t("View fees") }}</span>
        <icon-arrow
          :class="[isViewMore ? $style.iconArrowUp : $style.iconArrowDown]"
        />
      </div>
    </div>

    <div v-if="isViewMore">
      <div :class="$style.orderFee">
        <span :class="$style.title">
          {{ $t("Network fees") }}:
          <icon-error
            @mouseenter="isShowNetworkTips = true"
            @mouseleave="isShowNetworkTips = false"
            :class="$style.iconTips"
          />
          <span :class="$style.estimated">
            {{ isAchPullTabSelected ? `(${$t("Estimated")})` : "" }}
          </span>
        </span>
        <a-spin v-if="quoteLoadTimer">
          <a-icon slot="indicator" :component="IconWait" spin />
        </a-spin>
        <span v-else :class="$style.fee">{{ getNetworkFee }}</span>
        <template v-if="!isAchPullTabSelected">
          <div
            :class="$style.tipsContent"
            v-show="isShowNetworkTips"
            v-html="$t('trade_confirm_desc.network_fees')"
          />
        </template>
        <template v-else>
          <div :class="$style.tipsContent" v-show="isShowNetworkTips">
            {{ $t("trade_ach_pull_confirm_desc.network_fees") }}
          </div>
        </template>
      </div>
      <div :class="$style.orderFee">
        <span :class="$style.title">
          {{ $t("Processing fees") }}:
          <icon-error
            @mouseenter="isShowProcessingTips = true"
            @mouseleave="isShowProcessingTips = false"
            :class="$style.iconTips"
          />
        </span>
        <a-spin v-if="quoteLoadTimer">
          <a-icon slot="indicator" :component="IconWait" spin />
        </a-spin>
        <span v-else :class="$style.fee">{{ getProcessingFee }}</span>
        <template v-if="!isAchPullTabSelected">
          <div :class="$style.tipsContent" v-show="isShowProcessingTips">
            {{ $t("trade_confirm_desc.processing_fees") }}
          </div>
        </template>
        <template v-else>
          <div :class="$style.tipsContent" v-show="isShowProcessingTips">
            {{ $t("trade_ach_pull_confirm_desc.processing_fees") }}
          </div>
        </template>
      </div>
    </div>
    <div :class="$style.orderRate">
      <span>{{ $t("Exchange rate") }}: </span>
      <a-spin v-if="quoteLoadTimer">
        <a-icon slot="indicator" :component="IconWait" spin />
      </a-spin>
      <div v-else :class="$style.exchangeRate" v-html="getTradeRate"></div>
    </div>

    <div
      v-if="!isAchPullTabSelected"
      :class="$style.orderTips"
      v-html="$t('trade_confirm_desc.exchange_rate')"
    />
    <div
      v-else
      :class="$style.orderTips"
      v-html="$t('trade_ach_pull_confirm_desc.exchange_rate')"
    />
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { Spin, Icon } from "ant-design-vue";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";
import IconArrow from "@/assets/img/common/icon-arrow.svg?inline";
import IconError from "@/assets/img/common/icon-error.svg?inline";

export default {
  name: "OrderSummary",
  props: {},
  data() {
    return {
      isViewMore: false,
      isShowNetworkTips: false,
      isShowProcessingTips: false,
      IconWait
    };
  },
  computed: {
    ...mapState([
      "tradeQuantity",
      "tradeSide",
      "tradePair",
      "modalView",
      "currencies",
      "currencyUnitList",
      "approxInfo",
      "quoteLoadTimer",
      "defaultPaymentTabsActived"
    ]),
    ...mapGetters({
      asset: "asset",
      allowAchPull: "allowAchPull"
    }),
    getOrderAmount() {
      const { currencyUnitList, asset, tradeQuantity } = this;
      return `${currencyUnitList[asset]} ${tradeQuantity} ${asset}`;
    },
    getReceiveCount() {
      const { tradePair, approxInfo } = this;
      return (
        approxInfo?.quantity && `${approxInfo?.quantity ?? ""} ${tradePair}`
      );
    },
    getNetworkFee() {
      const { currencyUnitList, asset, approxInfo } = this;
      return (
        approxInfo?.network_fee &&
        `${currencyUnitList[asset]} ${approxInfo?.network_fee ?? ""} ${asset}`
      );
    },
    getProcessingFee() {
      const { currencyUnitList, asset, approxInfo } = this;
      return (
        approxInfo?.processing_fee &&
        `${currencyUnitList[asset]} ${approxInfo?.processing_fee ??
          ""} ${asset}`
      );
    },
    getTradeRate() {
      const { currencyUnitList, asset, approxInfo, tradePair } = this;
      return (
        approxInfo?.price &&
        `<span>${currencyUnitList[asset]} ${approxInfo?.price ??
          ""}</span> ${asset} = <span>1</span> ${tradePair}`
      );
    },
    isAchPullTabSelected() {
      return (
        this.allowAchPull && this.defaultPaymentTabsActived === "bank_transfer"
      );
    }
  },
  components: {
    [Icon.name]: Icon,
    [Spin.name]: Spin,
    IconArrow,
    IconError
  },
  methods: {
    ...mapActions(["updateApproxInfo", "updateQuoteLoadTimer"]),
    handleViewMore() {
      this.isViewMore = !this.isViewMore;
    }
  },
  created() {},
  mounted() {},
  activated() {},
  deactivated() {
    this.isViewMore = false;
    this.updateApproxInfo({});
    this.updateQuoteLoadTimer(false);
  }
};
</script>
<style module lang="scss">
@import "~rfs/scss";

.orderDetail {
  @include rfs(22.5rem, max-width);

  margin: auto;

  .orderInfo {
    @include rfs(0.9375rem, margin-bottom);

    &:not(:first-child) {
      @include rfs(0.4375rem, margin-bottom);
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      @include rfs(0.875rem);
      font-weight: 500;
      color: var(--dark);
    }

    .orderType {
      @include rfs(0.875rem);
      @include rfs(0 0.1875rem, padding);
      @include rfs(1.25rem, line-height);

      border-radius: 0.25rem;
      color: var(--white);
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      background: var(--info);
    }

    .amountInfo {
      display: flex;
      flex-direction: column;

      span {
        @include rfs(0.875rem);

        color: var(--dark);
        text-align: right;
      }
    }

    :global {
      .ant-spin-dot {
        @include rfs(1rem);
      }
    }
  }

  .estimated {
    @include rfs(4px, margin-left);

    font-weight: 500;

    color: var(--grey-cool-light);
  }

  .viewMoreContainer {
    @include rfs(1.0625rem, margin-bottom);

    display: flex;
  }

  .viewMore {
    @include rfs(90px, width);

    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      @include rfs(0.875rem);
      @include rfs(0.25rem, margin-right);

      font-weight: 500;
      color: var(--grey-cool-light);
      text-decoration: underline;

      &:hover {
        color: var(--primary-3);
      }

      &:active {
        color: var(--dark);
      }
    }

    .iconArrowDown {
      @include rfs(0.875rem, max-width);
      color: var(--grey-cool-light);
      transition: 0.2s;
      transform-origin: center;
      transform: rotate(0deg);
    }

    .iconArrowUp {
      @include rfs(0.875rem, max-width);
      color: var(--grey-cool-light);
      transition: 0.2s;
      transform-origin: center;
      transform: rotate(180deg);
    }
  }

  .orderFee {
    @include rfs(0.5rem, margin-bottom);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .title {
      @include rfs(0.875rem);

      font-weight: 500;
      color: var(--dark);
      display: flex;
      align-items: center;
      .iconTips {
        @include rfs(1rem, width);
        @include rfs(0.5rem, margin-left);

        color: var(--silver);
        cursor: pointer;

        &:hover {
          @include rfs(1.2rem, width);

          box-shadow: 0 0 0 0.1rem var(--white), 0 0 0 0.2rem var(--primary);
          border-radius: 50%;
        }
      }
    }
    .fee {
      @include rfs(0.875rem);

      color: var(--dark);
      text-align: right;
    }

    .tipsContent {
      @include rfs(0.75rem);
      @include rfs(0.625rem, padding);

      color: var(--white);
      position: absolute;
      border-radius: 0.625rem;
      background: #797980;
      z-index: 2;
      letter-spacing: -0.2px;
      top: 2rem;
      text-align: left;
    }

    :global {
      .ant-spin-dot {
        @include rfs(1rem);
      }
    }
  }

  .orderRate {
    @include rfs(0.875rem);
    @include rfs(1.25rem, margin-bottom);

    color: var(--grey-cool);
    text-align: left;
    display: flex;
    align-items: center;
    :global {
      .ant-spin-dot {
        @include rfs(1rem);
      }
    }
    & > span:first-child {
      @include rfs(0.5rem, margin-right);
      color: var(--grey-cool);
    }
    span {
      color: var(--dark);
    }
  }

  .orderTips {
    @include rfs(0.75rem);
    @include rfs(2.1875rem, margin-bottom);

    letter-spacing: -0.26px;
    color: var(--grey-cool);
    text-align: left;
  }
}
</style>
