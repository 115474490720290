<template>
  <section :class="$style.tabListWrapper">
    <div :class="$style.tabsList">
      <TabsItem
        v-for="(item, index) in paymentList"
        :key="index"
        :itemData="item"
        @change:tabsItem="changeTabsItem"
      />
    </div>
    <div>
      <slot></slot>
    </div>
  </section>
</template>

<script>
import TabsItem from "./TabsItem.vue";
export default {
  name: "TradePaymentList.TradeTabs",
  props: {
    paymentList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {},
  components: {
    TabsItem
  },
  methods: {
    changeTabsItem(groupType) {
      this.$emit("change:tabsItem", groupType);
    }
  },
  created() {},
  mounted() {}
};
</script>
<style module lang="scss">
@import "~rfs/scss";

.tabListWrapper {
  // @include rfs(50px, margin-bottom);
}

.tabsList {
  @include rfs(20px, margin-bottom);

  display: flex;
  align-items: stretch;
  justify-content: center;
}
</style>
