<template>
  <a-modal
    centered
    title=""
    v-model="isModalVisible"
    :getContainer="() => $root.$el"
    :width="560"
    :bodyStyle="{ padding: 0, position: 'relative' }"
    :footer="null"
    :closable="false"
    :maskClosable="false"
    :destroyOnClose="true"
    @cancel="onCancel"
  >
    <iframe
      allow="payment"
      title="checkout redirect"
      :class="$style.iframe"
      :src="urlRedirect"
      @load="load3DsFrames"
    />
    <a-spin v-if="iFrameLoading">
      <a-icon slot="indicator" :component="IconWait" spin />
    </a-spin>
  </a-modal>
</template>

<script>
import { Spin, Modal, Icon } from "ant-design-vue";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";

export default {
  name: "ModalCheckout",
  props: {
    visible: Boolean,
    urlRedirect: String
  },
  data() {
    return {
      iFrameLoading: false,
      IconWait
    };
  },
  computed: {
    isModalVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  watch: {
    isModalVisible(value) {
      if (value) {
        this.initData();
      }
    }
  },
  components: {
    [Icon.name]: Icon,
    [Modal.name]: Modal,
    [Spin.name]: Spin
  },
  methods: {
    onCancel() {
      this.isModalVisible = false;
      this.unbindMessageEvent();
      this.$emit("cancel");
    },
    load3DsFrames() {
      this.iFrameLoading = false;
    },
    handleMutilEvent(eventType, eventData) {
      this.$emit(`checkout:${eventType}`, eventData);
    },
    handleMessage(event) {
      const reLegendTrading = /legendtrading\.com$/i;
      const TARGET_REDIRECT = "checkout-redirect";
      const { origin, data: response } = event;
      if (!reLegendTrading.test(origin)) {
        // message is not from LegendTrading origin
        return;
      } else if (response.target !== TARGET_REDIRECT) {
        // message target is not checkout-redirect
        return;
      } else {
        // any valid message from redirect
        try {
          if (["success", "failed", "pending"].includes(response.status)) {
            this.handleMutilEvent(`${response.status}`, response);
          } else {
            throw new Error(response.message);
          }
        } catch (err) {
          this.handleMutilEvent("error", err);
        } finally {
          this.handleMutilEvent("complete", null);
          this.unbindMessageEvent();
        }
      }
    },
    bindMessageEvent() {
      window.addEventListener("message", this.handleMessage, false);
    },
    unbindMessageEvent() {
      window.removeEventListener("message", this.handleMessage, false);
    },
    initData() {
      this.iFrameLoading = true;
      this.bindMessageEvent();
    }
  }
};
</script>

<style module lang="scss">
@import "~rfs/scss";
.iframe {
  background: transparent;
  border: 0 none;
  display: block;
  min-height: 400px;
  position: relative;
  width: 100%;
  z-index: 1;
  & + :global(.ant-spin) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
  }
}
</style>
