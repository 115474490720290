<template>
  <section :class="$style.wrapper">
    <h2>{{ $t("Oops") }}</h2>

    <p v-if="message">{{ message }}</p>
    <p v-else v-html="$t('alter_default_message')"></p>

    <BaseButton @click="handleClick">{{ $t("DISMISS") }}</BaseButton>
  </section>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "Modal.OopsDismiss",
  components: {
    BaseButton
  },
  data() {
    return {};
  },
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.$emit("ok");
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);

  h2 {
    @include rfs(1.75rem, margin-bottom);

    color: var(--dark);
    text-align: center;
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);

    text-align: center;
    color: var(--grey-cool);
  }
}
</style>
