<template>
  <section :class="$style.home">
    <account-info :class="$style.account" />
    <section :class="$style.homeContent">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
      <section :class="$style.btmContent">
        <need-help :class="$style.needHelp" />
        <powered-by :class="$style.poweredBy" />
      </section>
    </section>

    <modal-base
      :visible.sync="isBaseModalVisible"
      @cancel="onCancel"
      @ok="onOk"
    />
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import PAYMENT from "@/store/payment/index";
import REGISTER from "@/store/register/index";
import DEPOSIT from "@/store/deposit/index";
import KYC from "@/store/kyc/index";
import store from "@/store/index";
import AccountInfo from "@/components/AccountInfo.vue";
import PoweredBy from "@/components/PoweredBy.vue";
import NeedHelp from "@/components/NeedHelp.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import { veriffMixin } from "@/mixin/veriffMixin";
import { reLoadMixin } from "@/mixin/reLoadMixin";

export default {
  name: "Home",
  components: {
    AccountInfo,
    PoweredBy,
    NeedHelp,
    ModalBase
  },
  data() {
    return {
      isBaseModalVisible: false
    };
  },
  mixins: [veriffMixin, reLoadMixin],
  computed: {
    ...mapGetters({
      isVerifyFinished: "isVerifyFinished",
      isDisabled: "isAccountDisabled",
      isRejected: "isAccountRejected"
    }),
    ...mapState(["needReload", "needReloadAfterVerify"])
  },
  watch: {
    needReloadAfterVerify(value) {
      if (value) {
        this.handleReLoad();
      }
    }
  },
  methods: {
    ...mapActions(["updateModalView", "updateMenuHeaderTitle"]),
    onCancel() {
      this.isBaseModalVisible = false;
    },
    onOk() {
      this.isBaseModalVisible = false;
    },
    showRejectModal() {
      if (this.isRejected) {
        this.updateModalView("RejectedAccount");
        this.isBaseModalVisible = true;
        return false;
      } else {
        return true;
      }
    }
  },
  activated() {
    this.updateMenuHeaderTitle("");
    this.handleReLoad();
  },
  deactivated() {},
  beforeRouteEnter(to, from, next) {
    next(vm => (vm.isBaseModalVisible = false));
  },
  beforeRouteLeave(to, from, next) {
    return this.showGlobalModal(to) && next();
  },
  beforeRouteUpdate(to, from, next) {
    return this.showGlobalModal(to) && next();
  },
  created() {
    if (!store.hasModule("PAYMENT")) {
      store.registerModule("PAYMENT", PAYMENT);
    }

    if (!store.hasModule("REGISTER")) {
      store.registerModule("REGISTER", REGISTER);
    }

    if (!store.hasModule("DEPOSIT")) {
      store.registerModule("DEPOSIT", DEPOSIT);
    }

    if (!store.hasModule("KYC")) {
      store.registerModule("KYC", KYC);
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.home {
  @include rfs(0.3125rem 10rem 11.625rem 10rem, padding);
  @include rfs(0.3125rem, padding-top);

  display: flex;
  z-index: 1;
  justify-content: space-between;

  .homeContent {
    @include rfs(47.5rem, max-width);

    display: flex;
    flex-direction: column;

    .btmContent {
      @include rfs(1.25rem, margin-top);
      display: flex;

      .needHelp {
        color: var(--grey-cool);

        flex: 1;
      }
      .poweredBy {
        flex: 2;
      }
    }
  }
}
</style>
