<template>
  <section :class="$style.wrapper">
    <h2 v-html="$t('modal_messsages.rejected_account.title')"></h2>
    <icon-alert :class="$style.iconAlert" />

    <p v-html="$t('modal_messsages.rejected_account.content')"></p>

    <i18n path="modal_messsages.rejected_account.desc" tag="p">
      <template #br><br /></template>
      <template #here>
        <span @click.prevent="toggleZendesk(!open)">{{ $t("here") }}</span>
      </template>
    </i18n>

    <BaseButton type="primary" @click="handleClick">{{
      $t("BACK TO DASHBOARD")
    }}</BaseButton>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseButton from "@/components/BaseButton.vue";
import IconAlert from "@/assets/img/common/icon-alert.svg?inline";

export default {
  name: "Modal.RejectedAccount",
  components: {
    BaseButton,
    IconAlert
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      open: state => state.isZendeskOpened
    })
  },
  watch: {},
  methods: {
    ...mapActions(["toggleZendesk"]),
    handleClick() {
      this.$emit("ok");
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);

  & > h2 {
    @include rfs(1.25rem, margin-bottom);

    color: var(--dark);
    text-align: center;
  }

  p {
    @include rfs(1.25rem);
    @include rfs(3.125rem, margin-bottom);

    text-align: center;
    color: var(--grey-cool);

    span {
      color: var(--primary);

      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: var(--primary-3);
      }

      &:active {
        color: var(--dark);
      }
    }
  }

  .iconAlert {
    @include rfs(4.375rem, max-width);
    @include rfs(1rem, margin-bottom);
  }

  .loadingTips {
    @include rfs(2.875rem, margin-top);
    @include rfs(0.5rem, margin-bottom);
  }
}
</style>
