// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModalCheckout_iframe_1LUvf{background:transparent;border:0 none;display:block;min-height:400px;position:relative;width:100%;z-index:1}.ModalCheckout_iframe_1LUvf+.ant-spin{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%) scale(2);transform:translate(-50%,-50%) scale(2)}", ""]);
// Exports
exports.locals = {
	"iframe": "ModalCheckout_iframe_1LUvf"
};
module.exports = exports;
