<template>
  <section>
    <slot name="list-header"></slot>
    <slot name="data-list"></slot>
    <slot name="credit_card_empty"></slot>
    <slot name="link_account_empty"></slot>
  </section>
</template>

<script>
export default {
  name: "TradePaymentList.TradeTabPane",
  props: {},
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style scoped lang="scss"></style>
