export const CHANGE_CURRENT_STEP_4_INDIVIDUAL =
  "CHANGE_CURRENT_STEP_4_INDIVIDUAL";
export const CHANGE_CURRENT_STEP_4_INSTITUTION =
  "CHANGE_CURRENT_STEP_4_INSTITUTION";
export const CHANGE_ACCOUNT_EMAIL = "CHANGE_ACCOUNT_EMAIL";
export const CHANGE_APPLY_TYPE = "CHANGE_APPLY_TYPE";
export const CHANGE_CURRENT_STEP_4_INDIVIDUAL_ADDITIONAL =
  "CHANGE_CURRENT_STEP_4_INDIVIDUAL_ADDITIONAL";
export const SET_IDV_COMPLETED = "SET_IDV_COMPLETED";
export const CHANGE_STEP_HIDDEN_STATUS = "CHANGE_STEP_HIDDEN_STATUS";
