<template>
  <section :class="$style.tradeWrapper">
    <label>{{ $t("Make a trade") | uppercase }}</label>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Trade",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    ...mapActions(["updateMenuHeaderTitle"])
  },
  activated() {
    this.updateMenuHeaderTitle("");
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.tradeWrapper {
  & > label {
    @include rfs(1.5625rem);
    @include rfs(0.4375rem, margin-bottom);
    @include rfs(2.5rem, margin-left);

    display: block;
    font-weight: 600;
    color: var(--legend-c-text);
    line-height: 2.1875rem;
  }
}
</style>
