<template>
  <section :class="$style.stepList">
    <section :class="$style.stepBody">
      <div :class="$style.stepBodyHeader">
        <div :class="$style.title">CHECKOUT</div>
        <div :class="$style.count">{{ currentStep }}/{{ stepCount }}</div>
      </div>
      <div :class="$style.stepBodyContent">
        <template v-for="(item, index) in registerStepLists4Individual">
          <section v-if="!item.hidden" :key="index" :class="$style.stepItem">
            <div :class="$style.itemLeft">
              <div
                :class="{
                  [$style.itemAction]: true,
                  [$style.itemSel]: item.status === 'finish'
                }"
              >
                <IconCheckWhite v-if="item.status === 'finish'" />
              </div>
            </div>

            <div :class="$style.itemRight">
              <div :class="$style.itemTitle">{{ item.title }}</div>
              <div :class="$style.itemDesc">{{ item.desc }}</div>
            </div>
          </section>
        </template>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState } from "vuex";
import IconCheckWhite from "@/assets/img/common/icon-check-white.svg?inline";

export default {
  name: "Register.DynamicStepList",
  props: {},
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState("REGISTER", ["registerStepLists4Individual"]),
    stepCount() {
      const filterArr = this.registerStepLists4Individual.filter(
        item => item.hidden === false
      );
      return filterArr.length;
    },
    currentStep() {
      const filterSteps = this.registerStepLists4Individual.filter(
        item => item.status === "finish"
      );
      return filterSteps.length;
    }
  },
  components: {
    IconCheckWhite
  },
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style module lang="scss">
@import "~rfs/scss";

.stepList {
  position: absolute;
  left: 4.375rem;
  top: 3.75rem;
  min-width: rfs-value(280px);
  border-radius: rfs-value(10px);

  .stepBody {
    @include rfs(10px 20px, padding);

    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: rfs-value(10px);
    box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
    .stepBodyHeader {
      @include rfs(18px);
      @include rfs(20px, margin-bottom);

      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      color: var(--dark);

      .title {
      }
      .count {
        @include rfs(36px, margin-left);
      }
    }
    .stepBodyContent {
      .stepItem {
        &:not(:last-child) {
          @include rfs(10px, margin-bottom);
        }

        @include rfs(10px 10px 20px 10px, padding);

        display: flex;
        border-radius: rfs-value(10px);
        background-color: rgba(237, 237, 244, 0.4);
        .itemLeft {
          @include rfs(15px, margin-right);

          .itemAction {
            @include rfs(24px, width);
            @include rfs(24px, height);

            background-color: var(--white);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .itemSel {
            background-color: var(--success);
          }
        }
        .itemRight {
          @include rfs(14px);

          text-align: left;
          .itemTitle {
            @include rfs(5px, margin-bottom);

            font-weight: bold;
            color: var(--dark);
          }
          .itemDesc {
            max-width: 180px;
            color: #1f1f1f;
          }
        }
      }
    }
  }
}
</style>
