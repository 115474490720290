import {
  CHANGE_CURRENT_STEP_4_INDIVIDUAL,
  CHANGE_CURRENT_STEP_4_INSTITUTION,
  CHANGE_ACCOUNT_EMAIL,
  CHANGE_APPLY_TYPE,
  CHANGE_CURRENT_STEP_4_INDIVIDUAL_ADDITIONAL,
  SET_IDV_COMPLETED,
  CHANGE_STEP_HIDDEN_STATUS
} from "./mutation-types";

// inital state
const state = {
  applyType: -1, //-1 无类型 0 个人 1 公司
  enableVeriff: true,
  currentStep4Individual: 0,
  currentStep4Institution: 0,
  accountEmail: "",
  idvCompleted: false,
  kycStepLists4Individual: [
    {
      view: "PersonalInfo",
      status: "process",
      title: "Personal Info",
      valid: false
    },
    {
      view: "BankingInfo",
      status: "wait",
      title: "Banking Info",
      valid: false
    },
    {
      view: "Questionnaire",
      status: "wait",
      title: "Questionnaire",
      valid: false
    },
    {
      view: "Identification",
      status: "wait",
      title: "Identification",
      valid: false
    }
  ],
  kycStepLists4Institution: [
    {
      view: "GeneralInfo",
      status: "process",
      title: "General",
      valid: false
    },
    {
      view: "Documents",
      status: "wait",
      title: "Documentation",
      valid: false
    },
    {
      view: "BankingInfo",
      status: "wait",
      title: "Banking Info",
      valid: false
    },
    {
      view: "Questionnaire",
      status: "wait",
      title: "Questionnaire",
      valid: false
    },
    {
      view: "Representives",
      status: "wait",
      title: "Representives",
      valid: false
    },
    {
      view: "BeneficialOwners",
      status: "wait",
      title: "Beneficial Owners",
      valid: false
    }
  ],
  kycStepLists4IndividualAdditional: [
    {
      name: "additional.individual.personalInfo",
      status: "process", // process | finish
      title: "BASIC INFORMATION",
      desc: "Check your name and DOB are correct",
      hidden: false
    },
    {
      name: "additional.individual.addressInfo",
      status: "process",
      title: "ADDRESS",
      desc: "Enter or Check your address",
      hidden: false
    },
    {
      name: "additional.individual.kycSurvey",
      status: "process",
      title: "QUESTIONNAIRE",
      desc: "Fast survey for a full experience",
      hidden: false
    },
    {
      name: "additional.individual.verifyIdentify",
      status: "process",
      title: "VERIFY IDENTITY",
      desc: "Take a selfie and photos your ID",
      hidden: false
    }
  ]
};
// getters
const getters = {};
// actions
const actions = {
  changeCurrentStep4Individual({ commit }, stepData) {
    commit(CHANGE_CURRENT_STEP_4_INDIVIDUAL, stepData);
  },
  changeCurrentStep4Institution({ commit }, stepData) {
    commit(CHANGE_CURRENT_STEP_4_INSTITUTION, stepData);
  },
  changeAccountEmail({ commit }, accountEmail) {
    commit(CHANGE_ACCOUNT_EMAIL, accountEmail);
  },
  changeApplyType({ commit }, applyType) {
    commit(CHANGE_APPLY_TYPE, applyType);
  },
  changeCurrentStep4IndividualAdditional({ commit }, stepData) {
    commit(CHANGE_CURRENT_STEP_4_INDIVIDUAL_ADDITIONAL, stepData);
  },
  setIdvCompleted({ commit }, value) {
    commit(SET_IDV_COMPLETED, value);
  },
  changeStepHiddenStatus({ commit }, value) {
    commit(CHANGE_STEP_HIDDEN_STATUS, value);
  }
};
// mutations
const mutations = {
  [CHANGE_APPLY_TYPE](state, applyType) {
    state.applyType = applyType;
  },
  [CHANGE_CURRENT_STEP_4_INDIVIDUAL](state, stepData) {
    if (state.currentStep4Individual > stepData) {
      // 说明是后退的操作
      state.kycStepLists4Individual[state.currentStep4Individual].status =
        "wait";
      state.kycStepLists4Individual[stepData].status = "process";
    } else {
      // 说明是前进的操作
      state.kycStepLists4Individual[state.currentStep4Individual].status =
        "finish";
      state.kycStepLists4Individual[stepData].status = "process";
    }
    state.currentStep4Individual = stepData;
  },
  [CHANGE_CURRENT_STEP_4_INSTITUTION](state, stepData) {
    if (state.currentStep4Institution > stepData) {
      // 说明是后退的操作
      state.kycStepLists4Institution[state.currentStep4Institution].status =
        "wait";
      state.kycStepLists4Institution[stepData].status = "process";
    } else {
      // 说明是前进的操作
      state.kycStepLists4Institution[state.currentStep4Institution].status =
        "finish";
      state.kycStepLists4Institution[stepData].status = "process";
    }
    state.currentStep4Institution = stepData;
  },
  [CHANGE_ACCOUNT_EMAIL](state, accountEmail) {
    state.accountEmail = accountEmail;
  },
  [CHANGE_CURRENT_STEP_4_INDIVIDUAL_ADDITIONAL](state, value) {
    state.kycStepLists4IndividualAdditional[value].status = "finish";
  },
  [SET_IDV_COMPLETED](state, value) {
    state.idvCompleted = value;
  },
  [CHANGE_STEP_HIDDEN_STATUS](state, value) {
    // 写死即可
    state.kycStepLists4IndividualAdditional[3].hidden = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
