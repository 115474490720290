import TradeBuyRouter from "./tradeBuy";
import TradeBuyAchPullRouter from "./tradeBuyAchPull";
import DepositAchPullRouter from "./depositAchPull";
import TradeEntry from "../../views/trade/TradeEntry.vue";
import BuyConfirm from "../../views/trade/BuyConfirm.vue";

const routes = [
  {
    path: "",
    name: "home.trade",
    component: TradeEntry
  },
  {
    path: "entry",
    name: "home.trade.entry",
    component: TradeEntry
  },
  {
    path: "buy",
    component: BuyConfirm,
    children: TradeBuyRouter
  },
  {
    path: "sell",
    name: "home.trade.sell",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/trade/SellConfirm.vue"
      )
  },
  ...TradeBuyAchPullRouter,
  ...DepositAchPullRouter
];

export default routes;
