// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrderTypeSelector_selector_oSFE1 .OrderTypeSelector_orderType_2Mkql{margin-bottom:calc(1.3rem + .6vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}@media(min-width:1200px){.OrderTypeSelector_selector_oSFE1 .OrderTypeSelector_orderType_2Mkql{margin-bottom:1.75rem}}.OrderTypeSelector_selector_oSFE1 .OrderTypeSelector_orderType_2Mkql .OrderTypeSelector_item_2RLza{font-size:.875rem;width:calc(1.75rem + 6vw);height:calc(1.4375rem + 2.25vw);border-radius:.625rem;line-height:3.125rem;background-color:var(--silver);color:var(--white);font-weight:700;cursor:pointer}@media(min-width:1200px){.OrderTypeSelector_selector_oSFE1 .OrderTypeSelector_orderType_2Mkql .OrderTypeSelector_item_2RLza{width:6.25rem;height:3.125rem}}.OrderTypeSelector_selector_oSFE1 .OrderTypeSelector_orderType_2Mkql .OrderTypeSelector_item_2RLza:not(:last-child){margin-right:1.25rem}.OrderTypeSelector_selector_oSFE1 .OrderTypeSelector_orderType_2Mkql .OrderTypeSelector_buy_3lCwi{background-color:var(--info)}.OrderTypeSelector_selector_oSFE1 .OrderTypeSelector_orderType_2Mkql .OrderTypeSelector_sell_1EF16{background-color:var(--success)}", ""]);
// Exports
exports.locals = {
	"selector": "OrderTypeSelector_selector_oSFE1",
	"orderType": "OrderTypeSelector_orderType_2Mkql",
	"item": "OrderTypeSelector_item_2RLza",
	"buy": "OrderTypeSelector_buy_3lCwi",
	"sell": "OrderTypeSelector_sell_1EF16"
};
module.exports = exports;
