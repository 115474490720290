<template>
  <section :class="$style.selector">
    <div :class="$style.orderType">
      <div
        @click="onChooseOrderType('Buy')"
        :class="{
          [$style.item]: true,
          [$style.buy]: tradeType === 'Buy'
        }"
      >
        {{ $t("Buy") | uppercase }}
      </div>
      <div
        v-if="!noSell && disabledCreditcard"
        @click="onChooseOrderType('Sell')"
        :class="{
          [$style.item]: true,
          [$style.sell]: tradeType === 'Sell'
        }"
      >
        {{ $t("Sell") | uppercase }}
      </div>
    </div>

    <component :is="selectOrderTypeView" :tradeType="tradeType" />
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BuyPanel from "@/components/BuyPanel.vue";
import SellPanel from "@/components/SellPanel.vue";

export default {
  name: "OrderTypeSelector",
  components: {
    BuyPanel,
    SellPanel
  },
  data() {
    return {
      tradeType: "Buy"
    };
  },
  computed: {
    ...mapGetters(["disabledCreditcard"]),
    ...mapState({
      noSell: state => state.clientInfo.noSell
    }),
    selectOrderTypeView() {
      const { tradeType } = this;
      return `${tradeType}Panel`;
    }
  },
  watch: {},
  methods: {
    ...mapActions(["updateTradeSide"]),
    onChooseOrderType(item) {
      this.tradeType = item;
      this.updateTradeSide(item);
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.selector {
  .orderType {
    @include rfs(1.75rem, margin-bottom);

    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      @include rfs(0.875rem);
      @include rfs(6.25rem, width);
      @include rfs(3.125rem, height);

      border-radius: 0.625rem;
      line-height: 3.125rem;
      background-color: var(--silver);
      color: var(--white);
      font-weight: bold;
      cursor: pointer;

      &:not(:last-child) {
        @include rfs(1.25rem, margin-right);
      }
    }

    .buy {
      background-color: var(--info);
    }

    .sell {
      background-color: var(--success);
    }
  }
}
</style>
