<template>
  <section :class="$style.wrapper">
    <h3>{{ $t("How much?") }}</h3>
    <div :class="$style.content">
      <a-input v-model="quantity">
        <template slot="suffix">
          <label :class="$style.maxCrypto" @click="inputMaxAmount">
            {{ $t("MAX") }}
          </label>
        </template>
      </a-input>
    </div>
    <div v-if="errors">
      <div :class="$style.errorMsg">
        <icon-error />
        <span>{{ errors }}</span>
      </div>
    </div>
    <h3 :class="$style.approx">
      {{ $t("Approx") }} {{ approxAmount }}
      <a-spin :spinning="approxLoading">
        <a-icon slot="indicator" type="loading" style="font-size: 30px" spin />
      </a-spin>
    </h3>

    <a-carousel
      v-if="getAssetList.length"
      arrows
      :class="$style.carouselClass"
      :dots="false"
      :slidesToShow="showCarouselCount"
      :slidesToScroll="showCarouselCount"
      :variableWidth="true"
      :infinite="true"
    >
      <icon-arrow-left slot="prevArrow" :class="$style.prevArrow" />
      <icon-arrow-left slot="nextArrow" :class="$style.nextArrow" />
      <div
        v-for="item in getAssetList"
        :key="item"
        :class="{
          [$style.itemNor]: true,
          [$style.itemSel]: item === asset
        }"
        @click="onSelectCurrency(item)"
        style="width:52px"
      >
        <div class="fi" :class="flagClass(item)"></div>
        <p :class="$style.currencyItem">
          {{ item }}
        </p>
      </div>
    </a-carousel>

    <BaseButton
      type="primary"
      :class="$style.baseButton"
      :passed="isPassed"
      @click="onHandleClick"
    >
      {{ $t("REVIEW ORDER") }}
    </BaseButton>
  </section>
</template>

<script>
import { debounce } from "lodash-es";
import { mapGetters, mapState, mapActions } from "vuex";
import { Notification, Carousel, Icon, Input, Spin } from "ant-design-vue";
import IconError from "@/assets/img/common/icon-error.svg?inline";
import BaseButton from "@/components/BaseButton.vue";
import IconArrowLeft from "@/assets/img/common/icon-arrow.svg?inline";
import { queryQuoteForCrypto, saveUserPreference } from "@/api";
import { rfq as rfqKyc } from "@/api/kyc";
import { setCurrencyFlag, displayErrors } from "@/utils";
import { commonMixin } from "@/mixin/commonMixin";

export default {
  name: "SellPanel",
  mixins: [commonMixin],
  components: {
    [Carousel.name]: Carousel,
    [Icon.name]: Icon,
    [Input.name]: Input,
    [Spin.name]: Spin,
    BaseButton,
    IconArrowLeft,
    IconError
  },
  data() {
    return {
      approxAmount: "",
      approxLoading: false,
      errors: "",
      currentAsset: "",
      setCurrencyFlag
    };
  },
  props: {
    tradeType: [String]
  },
  inject: {
    assetList: {
      default: () => {
        return [];
      }
    }
  },
  computed: {
    ...mapState([
      "pairs",
      "tradeQuantity",
      "tradePair",
      "currencies",
      "currencyUnitList"
    ]),
    ...mapGetters({
      asset: "asset",
      balance: "balance",
      isEnabled: "isAccountEnabled"
    }),
    isPassed() {
      return !(!!this.quantity && !this.errors && !this.approxLoading);
    },
    flagClass() {
      return currency => {
        return `fi-${setCurrencyFlag(currency)}`;
      };
    },
    getAssetList() {
      const assetArray = this.assetList();
      const currencyArray = this.getCurrencies.map(subItem => {
        return subItem.currency;
      });
      const intersection = assetArray.filter(v => currencyArray.includes(v));
      return intersection;
    },
    getCurrencies() {
      const filterCurrencies = this.currencies.filter(
        currency => currency.legend_liquidity === true
      );
      const findIndex = filterCurrencies.findIndex(
        item => item.currency === this.asset
      );
      if (findIndex < 0) this.updateAsset(filterCurrencies[0].currency);
      return filterCurrencies;
    },
    quantity: {
      get() {
        return this.tradeQuantity;
      },
      set(value) {
        this.updateTradeQuantity(value);
      }
    },
    ownBalance() {
      const _balance = this.balance;
      const existBalance = _balance.crypto_exchange[this.tradePair];
      return (existBalance && existBalance.amount) || 0;
    },
    showCarouselCount() {
      const len = this.getAssetList.length;
      return Math.min(len, 6);
    },
    currency: {
      get() {
        return this.asset;
      },
      set(value) {
        if (this.isEnabled) {
          this.handleSaveUserPreference(value);
        } else {
          this.updateAsset(value);
        }
      }
    },
    debouncedHandleApprox() {
      return debounce(this.handleApprox, 1000);
    }
  },
  watch: {
    tradeQuantity: {
      handler(value) {
        if (!value) {
          this.errors = "";
          this.approxAmount = "";
          return;
        }
        const re = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if (!Number.isNaN(value) && re.test(value)) {
          this.errors = "";
          this.updateApprox();
        } else {
          this.approxAmount = "";
          this.errors = this.$t("amount format errors");
        }
      },
      immediate: true
    },
    tradePair() {
      this.updateApprox();
    },
    currency() {
      this.updateApprox();
    }
  },
  methods: {
    ...mapActions(["updateAsset", "updateTradeQuantity"]),
    inputMaxAmount() {
      this.quantity = this.ownBalance;
    },
    onSelectCurrency(currentAsset) {
      this.currency = currentAsset;
    },
    onHandleClick() {
      this.$router.push("/home/trade/sell");
    },
    formatQuantity(quantity = "") {
      let number = Number.parseFloat(quantity) || 0;
      number = this.$options.filters.formatBalance(number);
      return number;
    },
    async updateApprox() {
      this.approxLoading = true;
      await this.debouncedHandleApprox();
    },
    rfq(params) {
      return this.isEnabled ? queryQuoteForCrypto(params) : rfqKyc(params);
    },
    async handleApprox() {
      try {
        const result = await this.rfq({
          pair: `${this.tradePair}${this.asset}`,
          side: this.tradeType.toLowerCase(),
          quantity: Number.parseFloat(this.quantity)
        });
        if (!result.errors) {
          //这个quantity是询价后接口返回的法币的金额,不要跟Input输入的价格搞混了
          let { size: fiatQuantity } = result;
          fiatQuantity = this.formatQuantity(fiatQuantity);
          const amountStr = `${
            this.currencyUnitList[this.asset]
          }${fiatQuantity}`;
          this.approxAmount = amountStr;
          this.errors = "";
        } else {
          Notification.error({
            message: "Error",
            description: JSON.stringify(result.errors),
            placement: "bottomRight"
          });
        }
      } catch (err) {
        const result = err?.response?.data;
        const minimumQuantity = this.getMinimumQuantitybyPairsInfo(
          this.pairs,
          this.tradePair,
          this.asset
        );
        this.approxAmount = "";
        this.errors =
          result?.errors?.quantity?.[0] ??
          `Minimum transaction size is ${
            this.currencyUnitList[this.currency]
          }${minimumQuantity}`;
      } finally {
        this.approxLoading = false;
      }
    },
    async handleSaveUserPreference(asset) {
      try {
        const params = {
          key: "default_fiat",
          value: asset
        };
        const result = await saveUserPreference(params);
        if (!result.errors) {
          this.updateAsset(asset);
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
$darkish-pink-color: #dc4f6b;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 1.25rem;
  }

  .content {
    @include rfs(18.4375rem, width);
    @include rfs(1.625rem, margin-bottom);

    :global {
      .ant-input-affix-wrapper {
        .ant-input {
          @include rfs(3.125rem, height);
          @include rfs(1.25rem);
          @include rfs(1rem, padding-left);

          border: solid 0.0625rem #cecbcb;
          border-radius: 0.625rem;
          color: var(--dark);
          font-weight: 500;
        }

        .ant-input-suffix {
          top: 57%;
          right: 0.9375rem;
        }
      }
    }
  }

  .maxCrypto {
    @include rfs(0.875rem);
    @include rfs(0 0.5rem, padding);

    background: var(--darkish-pink);
    border-radius: 0.25rem;
    color: var(--white);
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background: #{adjust-color($darkish-pink-color, $alpha: -0.2)};
    }

    &:active {
      background: var(--white);
      color: var(--darkish-pink);
      box-shadow: 0 0.125rem 0.25rem 0 var(--dark-70-5);
    }
  }

  .approx {
    @include rfs(0.75rem, padding);

    border-bottom: solid 0.0625rem #ededf4;
  }

  .carouselClass {
    @include rfs(19.125rem, width);
    @include rfs(3.125rem, margin-bottom);

    :global {
      .slick-slider {
        @include rfs(0 1.125rem, padding);
      }

      .slick-prev,
      .slick-next {
        top: 20%;

        &:hover {
          color: var(--dark);
        }
      }

      .slick-track {
        display: flex;
      }

      .slick-slide {
        @include rfs(5rem, height);
        @include rfs(0.3125rem, padding-top);

        text-align: center;
        background: var(--white);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .prevArrow {
    transform: rotate(90deg);
    color: var(--dark);
  }

  .nextArrow {
    transform: rotate(-90deg);
    color: var(--dark);
  }

  .itemNor {
    cursor: pointer;

    & > div {
      @include rfs(1.625rem, width);
      @include rfs(1.625rem, height);

      border-radius: 50%;
      background-size: cover;
      // box-shadow: 0.0625rem 0.0625rem 0.1875rem 0rem var(--silver);

      line-height: 1.875rem;
      opacity: 0.3;
    }

    p {
      @include rfs(0.875rem);
      @include rfs(0.4375rem, margin-top);
      @include rfs(0rem, margin-bottom);

      color: #ededf4;
    }
  }

  .itemNor:hover {
    & > div {
      box-shadow: 0 0 0.4375rem 0.25rem var(--primary-3);
    }
  }

  .itemSel {
    & > div {
      opacity: 1;
      box-shadow: 0 0 0 0.2rem var(--white), 0 0 0 0.3rem var(--primary);
    }

    p {
      @include rfs(0.875rem);
      @include rfs(0.4375rem, margin-top);
      @include rfs(0rem, margin-bottom);

      color: var(--grey-cool);
    }
  }
  .itemSel:hover {
    & > div {
      box-shadow: 0 0 0 0.2rem var(--white), 0 0 0 0.3rem var(--primary);
    }
  }

  .errorMsg {
    @include rfs(23.75rem, max-width);
    @include margin-bottom(0.5rem);
    @include rfs(0.625rem 1rem, padding);

    border-radius: 0.625rem;
    background: var(--pale-grey);
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      @include rfs(1.25rem, min-width);
      @include rfs(1.25rem, height);
      @include rfs(1.375rem, margin-right);

      color: var(--grey-cool);
    }

    span {
      color: var(--grey-cool);
      text-align: left;
    }
  }

  .baseButton {
    margin: auto;
  }
}
</style>
