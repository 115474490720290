const routes = [
  {
    path: "/deposit/achPull/ssnInfo",
    name: "deposit.achPull.ssnInfo",
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../../views/deposit/achPull/SsnInfo.vue"
      ),
    meta: {
      type: "achPull_deposit_in_trade",
      title: ""
    }
  },
  {
    path: "/deposit/achPull/verifyIdentify",
    name: "deposit.achPull.verifyIdentify",
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../../views/deposit/achPull/VerifyIdentify.vue"
      ),
    meta: {
      type: "achPull_deposit_in_trade",
      title: "VERIFY IDENTITY"
    }
  },
  {
    path: "/deposit/achPull/autoVerify",
    name: "deposit.achPull.autoVerify",
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../../views/deposit/achPull/AutoVerify.vue"
      ),
    meta: {
      type: "achPull_deposit_in_trade",
      title: "VERIFY YOUR IDENTITY"
    }
  },
  {
    path: "/deposit/achPull/verifySuccess",
    name: "deposit.achPull.verifySuccess",
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../../views/deposit/achPull/VerifySuccess.vue"
      ),
    meta: {
      type: "achPull_deposit_in_trade",
      showBackBtn: false
    }
  },
  {
    path: "/deposit/achPull/verifyFailed",
    name: "deposit.achPull.verifyFailed",
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../../views/deposit/achPull/VerifyFailed.vue"
      ),
    meta: {
      type: "achPull_deposit_in_trade",
      showBackBtn: false
    }
  }
];

export default routes;
