<template>
  <section :class="$style.powered" @click="toLegend">
    {{ $t("Powered by") }} <logo-gold />
  </section>
</template>

<script>
import LogoGold from "@/assets/img/logo-gold.svg?inline";

export default {
  name: "PoweredBy",
  props: {
    size: {
      type: String,
      default: "large"
    }
  },
  components: {
    LogoGold
  },
  computed: {
    style() {
      return { fontSize: "1rem" };
    }
  },
  methods: {
    toLegend() {
      window.open("https://legendtrading.com/", "_blank");
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.powered {
  @include rfs(0.75);

  font-weight: bold;
  color: var(--legend-c-text);

  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    @include rfs(8.125rem, min-width);
    @include rfs(auto, height);
    @include rfs(0.5625rem, margin-left);
  }
}
</style>
