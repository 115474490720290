import { mapGetters, mapState, mapActions } from "vuex";
import {
  getPairsInfo as getPairsTrade,
  getCurrencies as getCurrenciesTrade,
  getBalance as getTradeBalance,
  getUserInfo as getTradeUserInfo
} from "@/api";
import {
  getPairsInfo as getPairsKyc,
  getCurrencies as getCurrenciesKyc,
  getBalance as getKycBalance,
  getUserInfo as getKycUserInfo,
  exchangeToken
} from "@/api/kyc";
import { displayErrors, getCurrencyUnitList } from "@/utils";

export const reLoadMixin = {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isAccountDisabled", "isAccountEnabled", "asset"]),
    ...mapState([
      "clientInfo",
      "kycStatus",
      "nextKycStep",
      "connectStatus",
      "needReload",
      "needReloadAfterVerify",
      "paymentType",
      "tokenType"
    ])
  },
  methods: {
    ...mapActions([
      "updateNeedLoad",
      "updateKycStatus",
      "updateConnectStatus",
      "updateAccountEmail",
      "updateAccountType",
      "updateExchangeConfig",
      "setToken",
      "setPairs",
      "updateUserPreference",
      "setCurrencies",
      "updateCurrencyUnitList",
      "updateAsset",
      "updateNextKycStep",
      "setBalance",
      "updateNeedReloadAfterVerify",
      "updateExchangeName",
      "updateOriginalNextKycStep",
      "updateTokenType"
    ]),
    isNeedLoad(immediate = false) {
      if (immediate) {
        this.updateNeedLoad(true);
      } else {
        if (["Pending", "QA"].includes(this.connectStatus)) {
          this.updateNeedReloadAfterVerify(true);
        } else if (this.nextKycStep === "enter_bank") {
          this.updateNeedLoad(true);
        } else if (
          ["questionnaire", "rejected_questionnaire"].includes(this.nextKycStep)
        ) {
          this.updateNeedLoad(true);
        } else if (
          this.isAccountDisabled ||
          (this.kycStatus === "In Progress" && this.nextKycStep !== "verify_id")
        ) {
          this.updateNeedReloadAfterVerify(true);
        } else if (
          this.kycStatus === "In Progress" &&
          ["verify_id", "redo_verify_id"].includes(this.nextKycStep)
        ) {
          this.updateNeedReloadAfterVerify(true);
        } else {
          this.updateNeedLoad(false);
        }
      }
    },
    getPairs() {
      return this.isAccountEnabled ? getPairsTrade() : getPairsKyc();
    },
    getCurrency() {
      return this.isAccountEnabled ? getCurrenciesTrade() : getCurrenciesKyc();
    },
    getBalance() {
      return this.isAccountEnabled ? getTradeBalance() : getKycBalance();
    },
    async getRefreshToken() {
      try {
        const params = {
          appId: this.clientInfo.appId,
          appUid: this.clientInfo.appUid
        };
        const result = await exchangeToken(params);
        if (!result.errors) {
          this.setToken(result.access_token);
          this.updateTokenType(result.token_type);

          // 这里得提前调用，是否支持ach，才能在选择支付列表的地方正确的展示
          const [pairs, currencies, balance] = await Promise.all([
            this.getPairs(),
            this.getCurrency(),
            this.getBalance()
          ]);

          const currencyUnitList = getCurrencyUnitList(currencies);

          this.setPairs(pairs);
          this.setCurrencies(currencies);
          this.updateCurrencyUnitList(currencyUnitList);
          this.setBalance(balance);
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      }
    },
    async getUserInfo() {
      try {
        const params = {
          appId: this.clientInfo.appId,
          appUid: this.clientInfo.appUid
        };
        const result =
          this.tokenType === "Bearer"
            ? await getTradeUserInfo(params)
            : await getKycUserInfo(params);
        if (!result.errors) {
          const {
            kyc_status,
            connect_status,
            account_email,
            account_type,
            exchange_config,
            user_preference,
            next_kyc_step = "questionnaire"
          } = result;

          this.updateNeedLoad(false);
          this.updateNeedReloadAfterVerify(false);
          this.updateNextKycStep(next_kyc_step);
          this.updateKycStatus(kyc_status);
          this.updateConnectStatus(connect_status);

          this.updateOriginalNextKycStep(next_kyc_step); // 将exchange后的状态同步过来,针对WEB-375，ps:其实这个状态可能后续就用不到了

          this.updateUserPreference(user_preference ?? { default_fiat: null });

          if (account_email) {
            this.updateAccountEmail(account_email);
          }
          this.updateAccountType(account_type?.toLowerCase() ?? "individual");

          this.updateExchangeName(exchange_config?.exchange_name ?? "");
          this.updateExchangeConfig(exchange_config);

          if (
            this.isAccountDisabled ||
            (this.kycStatus === "In Progress" &&
              this.nextKycStep !== "verify_id")
          ) {
            return;
          }
          const [pairs, currencies, balance] = await Promise.all([
            this.getPairs(),
            this.getCurrency(),
            this.getBalance()
          ]);

          const currencyUnitList = getCurrencyUnitList(currencies);

          this.setPairs(pairs);
          this.setCurrencies(currencies);
          this.updateCurrencyUnitList(currencyUnitList);
          this.setBalance(balance);

          if (this.paymentType === "") {
            const currencyInfo = currencies.find(
              item => item.currency === this.user_preference?.default_fiat
            );
            if (!currencyInfo) {
              // 没有找到，使用currencies的第一个数据做为默认的法币
              const defaultAsset = currencies[0].currency;
              this.updateAsset(defaultAsset);
            } else {
              // 找到了就用当前的数据做为默认法币
              const defaultAsset = currencyInfo.currency;
              this.updateAsset(defaultAsset);
            }
          }
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
        this.setBalance({
          crypto_exchange: {},
          crypto: {},
          fiat: {}
        });
      }
    },
    handleReLoad() {
      if (this.needReload || this.needReloadAfterVerify) {
        this.getUserInfo();
      }
    }
  }
};
