// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AccountInfo_account_3eN96{-webkit-box-flex:1;-ms-flex:1;flex:1}", ""]);
// Exports
exports.locals = {
	"account": "AccountInfo_account_3eN96"
};
module.exports = exports;
