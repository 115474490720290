<template>
  <section :class="$style.wrapper">
    <h2>{{ achOrderErrorInfo.subject || $t(subject) }}</h2>

    <p v-if="achOrderErrorInfo.message">
      {{ achOrderErrorInfo.message }}
    </p>

    <BaseButton @click="$emit('ok')">{{ $t("Okay") | uppercase }}</BaseButton>
  </section>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "Modal.AchOrderError",
  components: {
    BaseButton
  },
  data() {
    return {
      subject: "System Error"
    };
  },
  props: {
    achOrderErrorInfo: {
      type: Object,
      default: () => {
        return { subject: "", message: "" };
      }
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);

  h2 {
    @include rfs(2rem);
    @include rfs(1.75rem, margin-bottom);

    color: var(--dark);
    text-align: center;
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);

    text-align: center;
    color: var(--grey-cool);
  }
}
</style>
