// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OopsPending_wrapper_VWhUF{padding:calc(1.375rem + 1.5vw) calc(1.325rem + .9vw) calc(1.4375rem + 2.25vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--white)}@media(min-width:1200px){.OopsPending_wrapper_VWhUF{padding:2.5rem 2rem 3.125rem}}.OopsPending_wrapper_VWhUF h2{font-size:calc(1.325rem + .9vw);margin:0 0 calc(1.3rem + .6vw);color:var(--dark);line-height:1.2;text-align:center}@media(min-width:1200px){.OopsPending_wrapper_VWhUF h2{font-size:2rem;margin:0 0 1.75rem}}.OopsPending_wrapper_VWhUF svg{max-width:calc(1.675rem + 5.1vw);margin:.5rem auto calc(1.375rem + 1.5vw)}@media(min-width:1200px){.OopsPending_wrapper_VWhUF svg{max-width:5.5rem;margin:.5rem auto 2.5rem}}.OopsPending_wrapper_VWhUF p{font-size:1.25rem;margin-bottom:1.25rem;text-align:center;color:var(--grey-cool)}.OopsPending_wrapper_VWhUF section{margin:0 0 calc(1.325rem + .9vw);padding:1rem;background-color:var(--disable);border-radius:var(--border-radius);color:var(--grey-cool)}@media(min-width:1200px){.OopsPending_wrapper_VWhUF section{margin:0 0 2rem}}.OopsPending_wrapper_VWhUF section p{margin:0}.OopsPending_wrapper_VWhUF section .OopsPending_message_3Ohb8{font-size:.875rem;color:var(--grey-cool);text-align:left;white-space:pre-wrap}", ""]);
// Exports
exports.locals = {
	"wrapper": "OopsPending_wrapper_VWhUF",
	"message": "OopsPending_message_3Ohb8"
};
module.exports = exports;
