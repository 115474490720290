import { v4 as uuidv4 } from "uuid";
import Veriff from "@veriff/js-sdk";
import { createVeriffFrame as createFrame } from "@veriff/incontext-sdk";
import { getVeriffSession as kycGetVeriffSession } from "@/api/kyc";
import { getVeriffSession as tradeGetVeriffSession } from "@/api";

// getVeriffUrl
function startVeriffFromLocal(parentId = "veriff-root", handler) {
  const veriff = Veriff({
    apiKey: "31ba0712-fa39-4946-81db-e3ee3d36a0a3",
    parentId,
    onSession(err, response) {
      // received the response, verification can be started now
      // console.log(err, response);
      if (err) {
        // console.error(err);
      } else {
        handler(response?.verification?.url);
      }
    }
  });
  veriff.setParams({
    person: {
      givenName: " ",
      lastName: " "
    },
    vendorData: uuidv4()
  });
  veriff.mount({
    // submitBtnText: "Get verified"
  });
  return veriff;
}

// async function getVeriffSession(type) {
//   // get veriff session
//   // return await getVeriffStatus(type);

//   // create or get veriff session
//   return await getVeriffUrl(type);
// }

export async function initVeriff({ id, handler, type, eventType = "kyc" }) {
  try {
    // get veriff status and url
    if (eventType === "kyc") {
      const { url, qrcode = "" } = await kycGetVeriffSession(type);
      handler(url, qrcode);
    } else {
      const result = await tradeGetVeriffSession(type);
      const { url, qrcode = "" } = result.data;
      handler(url, qrcode);
    }
  } catch {
    // create session from local
    startVeriffFromLocal(id, handler);
  }
}

export function createVeriffFrame(url = "", onEvent) {
  const veriffFrame = createFrame({
    url,
    onEvent
  });
  return veriffFrame;
}
