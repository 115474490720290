<template>
  <section>
    <section :class="$style.wrapper">
      <a-dropdown
        v-model="isAccountMenuOpen"
        :getPopupContainer="() => $root.$el"
        :overlayClassName="$style.localeDropdown"
      >
        <div :class="$style.dropdownTitle">
          <a-icon type="global" :class="$style.iconGlobal" />
          <span>{{ locale2Language[$i18n.locale] }}</span>
          <icon-arrow-down
            :class="{
              [$style.iconArrow]: true,
              [$style.iconArrowDown]: isAccountMenuOpen
            }"
          />
        </div>
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item v-for="locale in localesList" :key="locale">
            <span>
              {{ locale2Language[locale] }}
            </span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </section>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { Menu, Dropdown, Icon } from "ant-design-vue";
import IconArrowDown from "@/assets/img/common/icon-arrow.svg?inline";
import { locale2Language } from "@/utils";

export default {
  name: "LocalesSelector",
  props: {
    localesList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isAccountMenuOpen: false,
      locale2Language
    };
  },
  computed: {
    ...mapState(["clientInfo"])
  },
  components: {
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Dropdown.name]: Dropdown,
    [Dropdown.Button.name]: Dropdown.Button,
    [Icon.name]: Icon,
    IconArrowDown
  },
  methods: {
    handleMenuClick({ key }) {
      this.$i18n.locale = key;
      this.isAccountMenuOpen = false;
    }
  },
  created() {},
  mounted() {}
};
</script>
<style lang="scss" module>
@import "~rfs/scss";

.wrapper {
  @include rfs(6.5rem, min-width);

  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: 600;
  background-color: var(--white);
  color: var(--dark);
  border-radius: 0.625rem;
  transition: transform 0.5s;
  -moz-transition: -moz-transform 0.5s; /* Firefox 4 */
  -webkit-transition: -webkit-transform 0.5s; /* Safari 和 Chrome */
  -o-transition: -o-transform 0.5s;

  &:hover {
    box-shadow: 0 0 0.4375rem 0 var(--primary-3);
  }

  &:active {
    color: var(--primary);

    svg {
      color: var(--dark);
    }
  }

  .dropdownTitle {
    display: flex;
    align-items: center;
    .iconGlobal {
      @include rfs(0.5rem, margin-right);

      svg {
        font-size: 1.25rem;
      }
    }
    span {
      @include rfs(0.5rem, margin-right);

      display: block;
      flex: 1;
      text-align: center;
      font-weight: normal;
    }
    .iconArrow {
      @include rfs(0.5rem, height);
      @include rfs(0.9375rem, width);

      transition: 0.2s;
      transform-origin: center;
    }
  }

  .iconArrowDown {
    transition: 0.2s;
    transform-origin: center;
    transform: rotate(180deg);
  }
}

.localeDropdown {
  :global {
    .ant-dropdown {
      @include rfs(10.1875rem, max-width);

      width: 100%;
    }

    .ant-dropdown-menu {
      @include rfs(-1rem, margin-left);
      @include rfs(0.6rem, margin-top);

      padding: 0;
      border-radius: 0.625rem;
    }

    .ant-dropdown-menu-item {
      @include rfs(0.875rem);
      @include rfs(0.375rem 0.75rem, padding);

      cursor: pointer;
      color: var(--dark);
      font-weight: 600;

      &:hover {
        background-color: transparent;
        border-radius: 0.625rem;
        color: var(--primary);
      }
    }

    .ant-dropdown-trigger.ant-dropdown-open {
      color: var(--primary);
      cursor: pointer;

      svg {
        color: var(--dark);
      }
    }
  }
}
</style>
