<template>
  <section>
    <trade-rfq :class="$style.rfq" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { queryPaymentmethods } from "@/api";
import { displayErrors } from "@/utils";
import TradeRfq from "@/components/TradeRfq.vue";

export default {
  name: "TradeEntry",
  components: {
    TradeRfq
  },
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapGetters({
      isEnabled: "isAccountEnabled"
    })
  },
  watch: {},
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        this.isEnabled && (await queryPaymentmethods());
      } catch (err) {
        displayErrors(err);
      } finally {
        this.isLoading = false;
      }
    }
  },
  created() {},
  mounted() {},
  activated() {
    this.fetchData();
  },
  beforeDestroy() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
</style>
