<template>
  <section :class="$style.wrapper">
    <h2>{{ $t("modal_messsages.verify_identity.title") }}</h2>

    <p>
      {{ $t("modal_messsages.verify_identity.content") }}
    </p>

    <BaseButton
      type="primary"
      :class="$style.baseButton"
      @click="handleClick"
      >{{ $t("COMPLETE VERIFICATION") }}</BaseButton
    >

    <p @click="handleCancel">
      {{ $t("I will do it later") }}
    </p>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "Modal.VerifyIdentity",
  components: {
    BaseButton
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions(["updateModalView"]),
    handleClick() {
      this.updateModalView("Veriff");
    },
    handleCancel() {
      this.$emit("cancel");
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);

  h2 {
    @include rfs(1.75rem, margin-bottom);

    color: var(--dark);
    text-align: center;
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);

    text-align: center;
    color: var(--grey-cool);
  }

  .baseButton {
    @include rfs(1.875rem, margin-bottom);
  }

  p:last-child {
    @include rfs(0.875rem);
    @include rfs(0rem, margin-bottom);

    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary);

    &:hover {
      color: var(--primary-3);
    }

    &:active {
      color: var(--dark);
    }
  }
}
</style>
