<template>
  <section :class="$style.wrapper">
    <h2>
      {{ $t("purchased_processed_title") }}
    </h2>
    <icon-success :class="$style.iconSuccess" />
    <div :class="$style.desc">
      {{ $t("purchased_processed_content") }}
    </div>
    <BaseButton @click="handleClick" :class="$style.baseButton">
      {{ $t("OK") }}
    </BaseButton>
  </section>
</template>

<script>
import { Icon } from "ant-design-vue";
import BaseButton from "@/components/BaseButton.vue";
import IconSuccess from "@/assets/img/common/icon-success.svg?inline";

export default {
  name: "Modal.PurchasedProcessed",
  components: {
    [Icon.name]: Icon,
    BaseButton,
    IconSuccess
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.$emit("ok");
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);

  h2 {
    @include rfs(1.75rem, margin-bottom);

    color: var(--dark);
    text-align: center;
  }

  .desc {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);
    @include rfs(1.25rem, margin-top);

    text-align: center;
    color: var(--grey-cool);
  }

  .iconSuccess {
    @include rfs(4.375rem, max-width);
  }

  .baseButton {
    @include rfs(1.875rem, margin-bottom);
  }
}
</style>
