const routes = [
  {
    path: "",
    name: "home.withdraw",
    component: () =>
      import(
        /* webpackChunkName: "withdraw" */ "../../views/withdraw/AssetWithdraw.vue"
      )
  },
  {
    path: "entry",
    name: "home.withdraw.entry",
    component: () =>
      import(
        /* webpackChunkName: "withdraw" */ "../../views/withdraw/AssetWithdraw.vue"
      )
  },
  {
    path: "fiat/destination",
    name: "home.withdraw.fiat.destination",
    component: () =>
      import(
        /* webpackChunkName: "withdraw" */ "../../views/withdraw/FiatDestination.vue"
      )
  },
  {
    path: "fiat/confirm",
    name: "home.withdraw.fiat.confirm",
    component: () =>
      import(
        /* webpackChunkName: "withdraw" */ "../../views/withdraw/FiatConfirm.vue"
      )
  },
  {
    path: "crypto/destination",
    name: "home.withdraw.crypto.destination",
    component: () =>
      import(
        /* webpackChunkName: "withdraw" */ "../../views/withdraw/CryptoDestination.vue"
      )
  },
  {
    path: "crypto/confirm",
    name: "home.withdraw.crypto.confirm",
    component: () =>
      import(
        /* webpackChunkName: "withdraw" */ "../../views/withdraw/CryptoConfirm.vue"
      )
  }
];

export default routes;
