// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddBankAccount_wrapper_Pjdq3{padding:calc(1.375rem + 1.5vw) calc(1.45625rem + 2.475vw) calc(1.4375rem + 2.25vw) calc(1.45625rem + 2.475vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--white)}@media(min-width:1200px){.AddBankAccount_wrapper_Pjdq3{padding:2.5rem 3.3125rem 3.125rem 3.3125rem}}.AddBankAccount_wrapper_Pjdq3>h2{margin-bottom:calc(1.3rem + .6vw);color:var(--dark);text-align:center}@media(min-width:1200px){.AddBankAccount_wrapper_Pjdq3>h2{margin-bottom:1.75rem}}.AddBankAccount_wrapper_Pjdq3 p{font-size:1.25rem;margin-bottom:calc(1.3rem + .6vw);text-align:center;color:var(--grey-cool)}@media(min-width:1200px){.AddBankAccount_wrapper_Pjdq3 p{margin-bottom:1.75rem}}.AddBankAccount_wrapper_Pjdq3 .AddBankAccount_baseButton_3qf8g{margin-bottom:calc(1.3125rem + .75vw)}@media(min-width:1200px){.AddBankAccount_wrapper_Pjdq3 .AddBankAccount_baseButton_3qf8g{margin-bottom:1.875rem}}.AddBankAccount_wrapper_Pjdq3 p:last-child{font-size:.875rem;margin-bottom:0;font-weight:700;text-decoration:underline;cursor:pointer;color:var(--primary)}.AddBankAccount_wrapper_Pjdq3 p:last-child:hover{color:var(--primary-3)}.AddBankAccount_wrapper_Pjdq3 p:last-child:active{color:var(--dark)}", ""]);
// Exports
exports.locals = {
	"wrapper": "AddBankAccount_wrapper_Pjdq3",
	"baseButton": "AddBankAccount_baseButton_3qf8g"
};
module.exports = exports;
