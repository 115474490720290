<template>
  <section v-if="showBanner" :class="$style.banner">
    <a-carousel autoplay vertical :dots="false" :autoplaySpeed="10000">
      <p
        v-for="{ banner_id, text, url } in filteredBanner"
        :key="banner_id"
        :class="$style.slide"
      >
        <a v-if="url" target="_blank" :href="url">{{ text }}</a>
        <span v-else>{{ text }}</span>
      </p>
    </a-carousel>
    <a-button ghost type="link" size="small" @click="visible = false">
      {{ $t("hide message") }}
    </a-button>
  </section>
</template>

<script>
import { Button, Carousel } from "ant-design-vue";
import { mapGetters } from "vuex";

export default {
  name: "BannerTop",
  components: {
    [Button.name]: Button,
    [Carousel.name]: Carousel
  },
  data() {
    return {
      visible: true
    };
  },
  computed: {
    ...mapGetters({
      banner: "activeBanner"
    }),
    filteredBanner() {
      if (Array.isArray(this.banner)) {
        return this.banner.filter(n => {
          const isTop = n.position === "Top";
          const isAlive = true;
          return isTop && isAlive;
        });
      } else {
        return [];
      }
    },
    showBanner() {
      return this.visible && this.filteredBanner.length > 0;
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.banner {
  @include rfs(1.5rem, height);
  background-color: var(--legend-c-bg);
  position: relative;
  & > :global(.ant-btn) {
    @include rfs(0.75rem);
    @include rfs(1.5rem, height);
    box-sizing: border-box;
    color: var(--dark-80);
    font-weight: 400;
    padding: 0;
    position: absolute;
    right: 0;
    text-decoration: underline;
    top: 0;
    &:hover {
      box-shadow: none;
    }
  }

  & > :global(.ant-carousel .slick-vertical .slick-slide > div) {
    font-size: 0;
  }
  & > :global(.ant-carousel .slick-vertical .slick-slide),
  & .slide {
    @include rfs(1.5rem, height);
    @include rfs(1.5rem, line-height);
    text-align: center;
    overflow: hidden;
  }
  .slide {
    @include rfs(1rem);
    color: var(--legend-c-text);
    margin: 0;
    padding: 0 8em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
