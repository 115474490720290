<template>
  <section :class="$style.selector">
    <div :class="$style.list">
      <div
        v-for="(item, index) in pairList"
        :key="index"
        :class="[
          $style.item,
          selectCrypto === item ? $style.itemSel : $style.itemNor
        ]"
        @click="onChooseCrypto(item)"
      >
        <div :class="$style.itemContent">
          <cryptoicon
            :symbol="item"
            :color="selectCrypto === item ? '' : '#ceced0'"
            generic
          />
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CryptoSelector",
  components: {},
  props: {
    maxDisplayCount: {
      type: [Number, String],
      default: 4
    },
    defaultCrypto: {
      type: String,
      default: ""
    },
    pairList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    selectCrypto: {
      get() {
        return this.defaultCrypto;
      },
      set(value) {
        this.$emit("update:defaultCrypto", value);
      }
    }
  },
  watch: {},
  methods: {
    onChooseCrypto(item) {
      this.selectCrypto = item;
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.selector {
  @include rfs(1.75rem, margin-bottom);

  .list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .item {
      @include rfs(6.25rem, width);
      @include rfs(6.25rem, height);
      @include rfs(1.25rem, margin-bottom);

      border-radius: 0.625rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
      cursor: pointer;

      &:not(:last-child) {
        @include rfs(1.25rem, margin-right);
      }

      &:nth-child(5n) {
        @include rfs(0rem, margin-right);
      }

      &:hover {
        box-shadow: 0 0 0.4375rem 0 var(--primary-3);
      }

      .itemContent {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          @include rfs(0.875rem);
          @include rfs(0.625rem, margin-top);

          font-weight: 500;
          color: var(--dark);
        }
      }
    }

    .itemSel {
      border: solid 0.125rem var(--primary);
    }
  }
}
</style>
