<template>
  <section>
    <div
      :class="{
        [$style.wrapper]: true,
        [$style.wrapperExtend]: isBgExtend,
        [$style.wrapperFaqMenuBg]: isFaqView
      }"
    >
      <div :class="{ [$style.container]: !isFaqView && !isBgExtend }">
        <div :class="$style.content">
          <IconBgLine :class="$style.iconBg" v-if="isBgExtend" />

          <template v-if="!isRefusesView">
            <div
              v-if="isShowBackBtn"
              :class="{
                [$style.backBtnContainer]: true,
                [$style.backBtnContainerPayment]: isAddPaymentsView
              }"
              @click="handleBack"
            >
              <icon-arrow-down :class="$style.iconBack" />
              <span>{{ $t("Back") | uppercase }}</span>
            </div>
            <div :class="$style.menuTitle" v-else>
              {{ $t(menuHeaderTitle) }}
            </div>
            <div :class="$style.info">
              <div
                v-if="!isPlaidPage"
                :title="$t('header_menu.TRADE')"
                :class="{
                  [$style.col]: true,
                  [$style.colActive]: showTradeMenuStyle
                }"
                @click="showTradeView"
              >
                {{ $t("header_menu['TRADE']") }}
              </div>
              <div
                v-if="!isPlaidPage && disabledCreditcard"
                :title="$t('header_menu.WITHDRAW')"
                :class="{
                  [$style.col]: true,
                  [$style.colActive]: showWithdrawMenuStyle
                }"
                @click="showWithdrawView"
              >
                {{ $t("header_menu['WITHDRAW']") }}
              </div>
              <div :class="$style.col" :title="$t('header_menu.ACCOUNT')">
                <a-dropdown
                  :getPopupContainer="() => $root.$el"
                  :overlayClassName="$style.customDropdown"
                  v-model="isAccountMenuOpen"
                >
                  <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="account.userinfo">
                      <span>
                        {{ $t("header_menu['ACCOUNT INFO']") }}
                      </span>
                    </a-menu-item>
                    <a-menu-item key="account.paymentList">
                      <span>
                        {{ $t("header_menu['PAYMENT METHODS']") }}
                      </span>
                    </a-menu-item>
                    <a-menu-item key="trade.history">
                      <span>
                        {{ $t("header_menu['TRADE HISTORY']") }}
                      </span>
                    </a-menu-item>
                    <a-menu-item key="transfer.history">
                      <span>
                        {{ $t("header_menu['TRANSFER HISTORY']") }}
                      </span>
                    </a-menu-item>
                    <a-menu-item key="faq">
                      <span>
                        {{ $t("header_menu['FAQ']") }}
                      </span>
                    </a-menu-item>
                  </a-menu>
                  <div :class="$style.dropdownTitle">
                    <span>{{ accountEmail }}</span>
                    <icon-arrow-down
                      :class="{ [$style.iconArrowDown]: isAccountMenuOpen }"
                    />
                  </div>
                </a-dropdown>
              </div>
              <LocalesSelector v-if="!noLangs" :localesList="localesList" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Menu, Dropdown, Icon, Button, Alert } from "ant-design-vue";
import IconArrowDown from "@/assets/img/common/icon-arrow.svg?inline";
import IconBgLine from "@/assets/img/common/icon-bg-line.svg?inline";
import LocalesSelector from "@/components/LocalesSelector.vue";

export default {
  name: "HeaderMenu",
  components: {
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Dropdown.name]: Dropdown,
    [Dropdown.Button.name]: Dropdown.Button,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Alert.name]: Alert,
    IconArrowDown,
    IconBgLine,
    LocalesSelector
  },
  computed: {
    ...mapGetters(["disabledCreditcard", "allowedLangs"]),
    ...mapState({
      accountEmail: state => state.accountEmail,
      menuHeaderTitle: state => state.menuHeaderTitle,
      noLangs: state => state.clientInfo.noLangs
    }),
    isShowBackBtn() {
      return (
        [
          "account.userinfo",
          "trade.history",
          "transfer.history",
          "account.paymentList",
          "account.balance",
          "faq"
        ].includes(this.$route?.name) ||
        this.$route.name?.startsWith("payment.")
      );
    },
    isRefusesView() {
      return (
        this.$route?.name?.startsWith("refuses") ||
        this.$route?.name?.startsWith("forbidden")
      );
    },
    isFaqView() {
      return this.$route?.name?.startsWith("faq");
    },
    isBgExtend() {
      return [
        "home",
        "trade.index",
        "account.userinfo",
        "trade.history",
        "transfer.history",
        "account.paymentList",
        "home.trade",
        "home.trade.entry",
        "home.trade.buy",
        "home.trade.sell",
        "home.withdraw",
        "home.withdraw.fiat.destination",
        "home.withdraw.fiat.confirm",
        "home.withdraw.crypto.destination",
        "home.withdraw.crypto.confirm",
        "account.balance",
        "refuses",
        "forbidden"
      ].includes(this.$route.name);
    },
    showTradeMenuStyle() {
      return [
        "home",
        "trade.index",
        "home.trade",
        "home.trade.entry",
        "home.trade.buy",
        "home.trade.sell"
      ].includes(this.$route.name);
    },
    showWithdrawMenuStyle() {
      return [
        "home.withdraw",
        "home.withdraw.fiat.destination",
        "home.withdraw.fiat.confirm",
        "home.withdraw.crypto.destination",
        "home.withdraw.crypto.confirm"
      ].includes(this.$route.name);
    },
    localesList() {
      return this.allowedLangs;
    },
    isPlaidPage() {
      const { name } = this.$route;
      return name === "plaid";
    },
    isAddPaymentsView() {
      return this.$route.name.startsWith("payment.");
    }
  },
  watch: {},
  data() {
    return {
      locale: "en",
      langs: ["en", "zh"],
      selCurrencyIndex: -1,
      isAccountMenuOpen: false
    };
  },
  methods: {
    handleBack() {
      if (this.isAddPaymentsView) {
        this.$router.back();
      } else {
        this.$router.push({ name: "home.trade" });
      }
    },
    handleMenuClick({ key }) {
      if (this.$route.name === key) return;
      this.$router.push({ name: key });
    },
    showWithdrawView() {
      const { name } = this.$route;
      if (name !== "home.withdraw") {
        this.$router.push({ name: "home.withdraw" });
      }
    },
    showTradeView() {
      const { name } = this.$route;
      if (name !== "home.trade") {
        this.$router.push({ name: "home.trade" });
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.wrapper {
  color: var(--black);

  display: flex;
  flex-flow: column nowrap;
  position: relative;
  z-index: 1;

  .container {
    background: rgba(255, 255, 255, 0.75); //var(--legend-menu-c-bg);
  }
  .content {
    @include rfs(5.3125rem, height);

    max-width: var(--max-width);
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .menuTitle {
      @include rfs(10rem, margin-left);
      @include rfs(1.5625rem);

      font-weight: 600;
      color: var(--legend-c-text);
    }
    .backBtnContainer {
      @include rfs(0.875rem);
      @include rfs(10rem, margin-left);
      @include rfs(0.5rem 1rem 0.5rem 0.625rem, padding);
      @include rfs(88px, min-width);

      display: flex;
      align-items: center;
      background: var(--white);
      border-radius: 0.625rem;
      color: var(--dark);
      font-weight: 600;
      cursor: pointer;
      position: relative;
      justify-content: center;

      &:hover {
        box-shadow: 0 0 0.4375rem 0 var(--primary-3);
      }

      &:active {
        color: var(--primary);

        svg {
          color: var(--dark);
        }
      }

      .iconBack {
        @include rfs(0.5rem, height);
        @include rfs(0.9375rem, width);
        @include rfs(0.5rem, margin-right);
        transform: rotate(90deg);
        // position: absolute;
        // top: 0.5rem;
        // left: 0;
      }
    }

    .backBtnContainerPayment {
      @include rfs(2.5rem, margin-left);
    }

    .info {
      @include padding-right(10rem);
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 1;
      .col {
        @include font-size(0.875rem);
        @include rfs(0.5rem 1rem, padding);

        cursor: pointer;
        font-weight: 600;
        background-color: var(--white);
        color: var(--dark);
        border-radius: 0.625rem;
        transition: transform 0.5s;
        -moz-transition: -moz-transform 0.5s; /* Firefox 4 */
        -webkit-transition: -webkit-transform 0.5s; /* Safari 和 Chrome */
        -o-transition: -o-transform 0.5s;

        &:not(:last-child) {
          @include margin-right(0.625rem);
        }

        &:hover {
          box-shadow: 0 0 0.4375rem 0 var(--primary-3);
        }

        &:active {
          color: var(--primary);

          svg {
            color: var(--dark);
          }
        }

        .dropdownTitle {
          span {
            @include rfs(0.5rem, margin-right);

            font-weight: normal;
          }

          svg {
            @include rfs(0.5rem, height);
            @include rfs(0.9375rem, width);

            transition: 0.2s;
            transform-origin: center;
          }
        }

        .iconArrowDown {
          transition: 0.2s;
          transform-origin: center;
          transform: rotate(180deg);
        }
      }
      .colActive {
        color: var(--primary);
      }
    }
  }
}

.iconBg {
  @include rfs(16.125rem, max-height);

  position: absolute;
  margin: auto;
  // z-index: 0;
  top: 0;
  left: 0;
}

.wrapperExtend {
  @include rfs(13.9375rem, height);
  background-image: linear-gradient(280deg, var(--primary-2), var(--white));
  overflow: hidden;
}
.wrapperFaqMenuBg {
  background-color: transparent;
}

.customDropdown {
  :global {
    .ant-dropdown {
      @include rfs(10.1875rem, max-width);

      width: 100%;
    }

    .ant-dropdown-menu {
      @include rfs(-1rem, margin-left);
      @include rfs(0.6rem, margin-top);

      padding: 0;
      border-radius: 0.625rem;
    }

    .ant-dropdown-menu-item {
      @include rfs(0.875rem);
      @include rfs(0.375rem 0.75rem, padding);

      cursor: pointer;
      color: var(--dark);
      font-weight: 600;

      &:hover {
        background-color: transparent;
        border-radius: 0.625rem;
        color: var(--primary);
      }
    }

    .ant-dropdown-trigger.ant-dropdown-open {
      color: var(--primary);
      cursor: pointer;

      svg {
        color: var(--dark);
      }
    }
  }
}
</style>
