// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NeedHelp_help_3x53L{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.NeedHelp_help_3x53L svg{max-width:1.125rem}.NeedHelp_help_3x53L h6{font-size:.875rem;margin-bottom:0;margin-left:1rem;color:currentColor}.NeedHelp_help_3x53L h6 span{font-weight:600;color:var(--primary);text-decoration:underline;cursor:pointer}.NeedHelp_help_3x53L h6 span:hover{color:var(--primary-3)}.NeedHelp_help_3x53L h6 span:active{color:var(--legend-c-text)}", ""]);
// Exports
exports.locals = {
	"help": "NeedHelp_help_3x53L"
};
module.exports = exports;
