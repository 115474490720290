import Vue from "vue";
import Vuex from "vuex";
import Color from "color";
import dayjs from "dayjs";
import acceptLanguage from "accept-language";
import { once, isNil, omitBy } from "lodash-es";
import { interval } from "rxjs";
import APICONFIG from "./config";
import { sortSupportLocales } from "@/i18n";

const bindOnce = once(() => {
  window.zE("webWidget:on", "close", () => {
    window.zE("webWidget", "hide");
    store.dispatch("toggleZendesk", false);
  });
});

const state = {
  isLoading: false,
  maintenance: false,
  isZendeskOpened: false,
  tsInMinutes: 0,
  themeColor: "#b39058",
  bgThemeColor: "light",
  // kycStatus: "Not Started", // Not Started, Started, In Progress, Submitted, In Review, Approved, Rejected
  kycStatus: "Not Started", // Not Started, Started, In Progress, Submitted, In Review, Approved, Rejected
  nextKycStep: "enter_email", //please check debugPanel.vue
  originalNextKycStep: "enter_email", //please check debugPanel.vue,
  connectStatus: "", // Pending
  accountEmail: "",
  accountEmailHash: "",
  accountType: "", // Individual, Entity
  expiresIn: 0,
  expiresAt: "",
  tokenExpiresDate: "",
  token: "",
  refreshToken: "",
  pairs: [],
  asset: "",
  balance: {
    crypto_exchange: {},
    crypto: {},
    fiat: {}
  },
  exchangeConfig: {
    auto_withdrawal: false,
    trade_confirmation_popup: "",
    minimum_trading_size: "50"
  },
  clientInfo: {
    email: "", // support@legendtrading.com
    langs: "",
    noLegend: false,
    noSell: false,
    noTrial: false,
    noLangs: false,
    uiSize: "large",
    primaryColor: "",
    bgPrimaryColor: "",
    requestForQuotation: "",
    customRouteEntry: "",
    urlAssets: "", // use "INTERNAL" for routing of trade.wallet
    urlDeposit: "", // use "INTERNAL" for routing of trade.wallet
    timestamp: "",
    signature: "",
    appId: "",
    appUid: "",
    appUrl: "",
    appKey: "",
    appEmail: "",
    appPhone: "",
    appState: ""
  },
  user_preference: {
    banner: null,
    default_fiat: null,
    skip_bank_disclaimer: false,
    skip_wire_disclaimer: false
  },
  currencies: [],
  minimumTradingSize: 0,
  menuHeaderTitle: "",
  baseModalVisible: false,
  modalView: "",
  tradeSide: "Buy",
  tradeQuantity: "",
  tradePair: "",
  paymentFrom: "", // trade-buy，withdraw-fiat 添加 add payment method 后需要回到原来得路由位置
  // 1.是否需要重新调用sign+authorize接口，
  // 2.从nextKycStep="enter_bank"变到下一个状态时（也就是add payment methord成功）
  // 3.从nextKycStep="questionnaire"变到下一个状态时
  // 4.从kycStatus:In Progress nextKycStep="verify_id" 变到下一个状态时
  needReload: false,
  needReloadAfterVerify: false,
  exchangeName: "",
  paymentType: "credit_card", // "deposit" | "credit_card" | ""
  tokenType: "", // Bearer | Kyc
  isHighRiskIp: false,
  ipAddressInfo: {
    country: "",
    state: ""
  },
  currencyUnitList: {},
  forbiddenInfo: {
    title: "",
    message: ""
  },
  userInfo: {
    edd: {},
    rfi: {}
  },
  selectedBankTransferInfo: {},
  defaultPaymentTabsActived: "balance",
  approxInfo: {},
  quoteLoadTimer: false,
  defaultPaymentItemActived: {
    balance: {
      payment_method_id: "",
      more: false,
      type: ""
    },
    bank_transfer: {
      payment_method_id: "",
      more: false,
      type: ""
    },
    credit_card: {
      payment_method_id: "",
      more: false,
      type: ""
    }
  },
  tradeBuyAchPullProcesses: [],
  tradeBuyAchPullProcessIndex: 0,
  tradeBuyAchPullProcessSteps: {
    ssn: "home.trade.buy.achPull.ssnInfo",
    nationality: "home.trade.buy.achPull.ssnInfo",
    state: "home.trade.buy.achPull.ssnInfo",
    verify_id: "home.trade.buy.achPull.verifyIdentify",
    link_account: "plaid"
  },
  accountStatus: "",
  eventType: {
    KYC: "kyc",
    ACHPULL: "achpull"
  },
  checkKycProcessList: [],
  kycProcessSteps: {
    email_verified: "individual.enterEmail",
    first_name: "individual.personalInfo",
    middle_name: "individual.personalInfo",
    last_name: "individual.personalInfo",
    birthday: "individual.personalInfo",
    nationality: "individual.personalInfo",
    country: "individual.userAddress",
    city: "individual.userAddress",
    state: "individual.userAddress",
    address_line_1: "individual.userAddress",
    address_line_2: "individual.userAddress",
    zipcode: "individual.userAddress",
    phone_number: "individual.phoneInfo",
    question_naire: "individual.kycSurvey",
    veriff_id: "individual.verifyIdentify"
  },
  tradeBuyAchPullInKycProcesses: [],
  tradeBuyAchPullInKycProcessIndex: 0,
  tradeBuyAchPullInKycProcessSteps: {
    ssn: "individual.achPull.ssnInfo",
    phone: "individual.achPull.ssnInfo",
    verify_id: "individual.achPull.verifyIdentify",
    link_account: "plaid"
  },
  paymentAchPullProcesses: [],
  paymentAchPullProcessIndex: 0,
  paymentAchPullProcessSteps: {
    ssn: "payment.achPull.ssnInfo",
    nationality: "payment.achPull.ssnInfo",
    state: "payment.achPull.ssnInfo",
    verify_id: "payment.achPull.verifyIdentify",
    link_account: "plaid"
  },
  depositAchPullProcesses: [],
  depositAchPullProcessIndex: 0,
  depositAchPullProcessSteps: {
    ssn: "deposit.achPull.ssnInfo",
    nationality: "deposit.achPull.ssnInfo",
    state: "deposit.achPull.ssnInfo",
    verify_id: "deposit.achPull.verifyIdentify",
    link_account: "plaid"
  },
  hasLinkBankAccount: false,
  quoteSubscribe: null,
  quoteErrorMessage: "",
  quoteParams: {
    pair: "",
    side: "",
    size: "",
    payment_method_id: ""
  },
  depositAchPullInKycProcesses: [],
  depositAchPullInKycProcessIndex: 0,
  depositAchPullInKycProcessSteps: {
    ssn: "individual.deposit.achPull.ssnInfo",
    phone: "individual.deposit.achPull.ssnInfo",
    verify_id: "individual.deposit.achPull.verifyIdentify",
    link_account: "plaid"
  }
};

const TYPES = {
  TOGGLE_LOADING: "TOGGLE_LOADING",
  TOGGLE_MAINTENANCE: "TOGGLE_MAINTENANCE",
  TOGGLE_ZENDESK: "TOGGLE_ZENDESK",
  UPDATE_TS_IN_MINUTES: "UPDATE_TS_IN_MINUTES",
  UPDATE_KYC_STATUS: "UPDATE_KYC_STATUS",
  UPDATE_CONNECT_STATUS: "UPDATE_CONNECT_STATUS",
  UPDATE_NEXT_KYC_STEP: "UPDATE_NEXT_KYC_STEP",
  UPDATE_ACCOUNT_EMAIL: "UPDATE_ACCOUNT_EMAIL",
  UPDATE_ACCOUNT_TYPE: "UPDATE_ACCOUNT_TYPE",
  UPDATE_EXPIRES_IN: "UPDATE_EXPIRES_IN",
  UPDATE_EXPIRES_AT: "UPDATE_EXPIRES_AT",
  UPDATE_EXCHANGE_CONFIG: "UPDATE_EXCHANGE_CONFIG",
  UPDATE_CLIENT_INFO: "UPDATE_CLIENT_INFO",
  SET_THEME_COLOR: "SET_THEME_COLOR",
  SET_BG_THEME_COLOR: "SET_BG_THEME_COLOR",
  SET_UI_SIZE: "SET_UI_SIZE",
  SET_TOKEN: "SET_TOKEN",
  SET_PAIRS: "SET_PAIRS",
  UPDATE_ASSET: "UPDATE_ASSET",
  SET_BALANCE: "SET_BALANCE",
  UPDATE_USER_PREFERENCE: "UPDATE_USER_PREFERENCE",
  SET_CURRENCIES: "SET_CURRENCIES",
  UPDATE_MINIMUM_TRADEING_SIZE: "UPDATE_MINIMUM_TRADEING_SIZE",
  UPDATE_TRADE_STEP_VIEW: "UPDATE_TRADE_STEP_VIEW",
  UPDATE_MENU_HEADER_TITLE: "UPDATE_MENU_HEADER_TITLE",
  UPDATE_MODAL_VIEW: "UPDATE_MODAL_VIEW",
  UPDATE_TRADE_SIDE: "UPDATE_TRADE_SIDE",
  UPDATE_TRADE_QUANTITY: "UPDATE_TRADE_QUANTITY",
  UPDATE_TRADE_PAIR: "UPDATE_TRADE_PAIR",
  UPDATE_BASE_MODAL_VISIBLE: "UPDATE_BASE_MODAL_VISIBLE",
  UPDATE_PAYMENT_FROM: "UPDATE_PAYMENT_FROM",
  UPDATE_NEED_LOAD: "UPDATE_NEED_LOAD",
  UPDATE_NEED_LOAD_AFTER_VERIFY: "UPDATE_NEED_LOAD_AFTER_VERIFY",
  UPDATE_EXCHANGE_NAME: "UPDATE_EXCHANGE_NAME",
  UPDATE_PAYMENT_TYPE: "UPDATE_PAYMENT_TYPE",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  UPDATE_TOKEN_EXPIRES_DATE: "UPDATE_TOKEN_EXPIRES_DATE",
  UPDATE_TOKEN_TYPE: "UPDATE_TOKEN_TYPE",
  UPDATE_IS_HIGH_RISK_IP: "UPDATE_IS_HIGH_RISK_IP",
  UPDATE_IP_ADDRESS_INFO: "UPDATE_IP_ADDRESS_INFO",
  UPDATE_ORIGINAL_NEXT_KYC_STEP: "UPDATE_ORIGINAL_NEXT_KYC_STEP",
  UPDATE_CURRENCY_UNIT_LIST: "UPDATE_CURRENCY_UNIT_LIST",
  UPDATE_FORBIDDEN_INFO: "UPDATE_FORBIDDEN_INFO",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  UPDATE_SELECTED_BANK_TRANSFER_INFO: "UPDATE_SELECTED_BANK_TRANSFER_INFO",
  UPDATE_DEFAULT_PAYMENT_TABS_ACTIVED: "UPDATE_DEFAULT_PAYMENT_TABS_ACTIVED",
  UPDATE_APPROX_INFO: "UPDATE_APPROX_INFO",
  UPDATE_QUOTE_LOAD_TIMER: "UPDATE_QUOTE_LOAD_TIMER",
  UPDATE_DEFAULT_PAYMENT_ITEM_ACTIVED: "UPDATE_DEFAULT_PAYMENT_ITEM_ACTIVED",
  UPDATE_TRADE_BUY_ACH_PULL_PROCESS_INDEX:
    "UPDATE_TRADE_BUY_ACH_PULL_PROCESS_INDEX",
  SET_TRADE_BUY_ACH_PULL_PROCESSES: "SET_TRADE_BUY_ACH_PULL_PROCESSES",
  UPDATE_ACCOUNT_STATUS: "UPDATE_ACCOUNT_STATUS",
  SET_CHECK_KYC_PROCESS_LIST: "SET_CHECK_KYC_PROCESS_LIST",
  UPDATE_TRADE_BUY_ACH_PULL_IN_KYC_PROCESS_INDEX:
    "UPDATE_TRADE_BUY_ACH_PULL_IN_KYC_PROCESS_INDEX",
  SET_TRADE_BUY_ACH_PULL_IN_KYC_PROCESSES:
    "SET_TRADE_BUY_ACH_PULL_IN_KYC_PROCESSES",
  SET_PAYMENT_ACH_PULL_PROCESSES: "SET_PAYMENT_ACH_PULL_PROCESSES",
  UPDATE_PAYMENT_ACH_PULL_PROCESS_INDEX:
    "UPDATE_PAYMENT_ACH_PULL_PROCESS_INDEX",
  SET_DEPOSIT_ACH_PULL_PROCESSES: "SET_DEPOSIT_ACH_PULL_PROCESSES",
  UPDATE_DEPOSIT_ACH_PULL_PROCESS_INDEX:
    "UPDATE_DEPOSIT_ACH_PULL_PROCESS_INDEX",
  UPDATE_HAS_LINK_BANK_ACCOUNT: "UPDATE_HAS_LINK_BANK_ACCOUNT",
  UPDATE_QUOTE_SUBSCRIBE: "UPDATE_QUOTE_SUBSCRIBE",
  UPDATE_QUOTE_ERROR_MESSAGE: "UPDATE_QUOTE_ERROR_MESSAGE",
  UPDATE_QUOTE_PARAMS: "UPDATE_QUOTE_PARAMS",
  SET_DEPOSIT_ACH_PULL_IN_KYC_PROCESSES:
    "SET_DEPOSIT_ACH_PULL_IN_KYC_PROCESSES",
  UPDATE_DEPOSIT_ACH_PULL_IN_KYC_PROCESS_INDEX:
    "UPDATE_DEPOSIT_ACH_PULL_IN_KYC_PROCESS_INDEX"
};

const mutations = {
  [TYPES.TOGGLE_LOADING](state, value) {
    if (typeof value === "boolean") {
      state.isLoading = value;
    } else {
      state.isLoading = !state.isLoading;
    }
  },
  [TYPES.TOGGLE_MAINTENANCE](state, value) {
    if (typeof value === "boolean") {
      state.maintenance = value;
    } else {
      state.maintenance = !state.maintenance;
    }
  },
  [TYPES.TOGGLE_ZENDESK](state, value) {
    const open = value ?? !state.isZendeskOpened;
    if (open === state.isZendeskOpened) {
      return;
    } else if (open) {
      window.zE("webWidget", "open");
      window.zE("webWidget", "show");
    } else {
      window.zE("webWidget", "hide");
      window.zE("webWidget", "close");
    }
    state.isZendeskOpened = open;
  },
  [TYPES.UPDATE_TS_IN_MINUTES](state, value) {
    state.tsInMinutes = value;
  },
  [TYPES.UPDATE_KYC_STATUS](state, value) {
    state.kycStatus = value;
  },
  [TYPES.UPDATE_NEXT_KYC_STEP](state, value) {
    state.nextKycStep = value;
  },
  [TYPES.UPDATE_CONNECT_STATUS](state, value) {
    state.connectStatus = value ?? "";
  },
  [TYPES.UPDATE_ACCOUNT_EMAIL](state, value = "") {
    state.accountEmail = value;
  },
  [TYPES.UPDATE_ACCOUNT_TYPE](state, value) {
    state.accountType = value;
  },
  [TYPES.UPDATE_EXPIRES_IN](state, value = 0) {
    state.expiresIn = value;
  },
  [TYPES.UPDATE_EXPIRES_AT](state, value = 0) {
    state.expiresAt = value;
  },
  [TYPES.UPDATE_EXCHANGE_CONFIG](state, value) {
    state.exchangeConfig = { ...state.exchangeConfig, ...value };
  },
  [TYPES.UPDATE_CLIENT_INFO](state, value) {
    const info = { ...value };

    if (info.urlAssets === "") {
      if (info.appId === "Bitrue") {
        info.urlAssets = "https://www.bitrue.com/assets";
      } else {
        // eslint-disable-next-line
        console.error("[url-assets] is unset, fallback with [app-url].");
        info.urlAssets = value.appUrl;
      }
    }
    if (info.urlDeposit === "") {
      if (info.appId === "Bitrue") {
        info.urlDeposit =
          "https://www.bitrue.com/assets/deposit?symbol={{SYMBOL}}";
      } else {
        // eslint-disable-next-line
        console.error("[url-deposit] is unset, fallback with [app-url].");
        info.urlDeposit = info.appUrl;
      }
    }
    state.clientInfo = { ...state.clientInfo, ...omitBy(info, isNil) };
  },
  [TYPES.SET_THEME_COLOR](state, value) {
    state.themeColor = value;

    window?.zE?.("webWidget", "updateSettings", {
      webWidget: {
        color: { theme: Color(value).hex() }
      }
    });
  },
  [TYPES.SET_BG_THEME_COLOR](state, value) {
    state.bgThemeColor = value;
  },
  [TYPES.SET_UI_SIZE](state, value) {
    state.clientInfo.uiSize = value;
  },
  [TYPES.SET_TOKEN](state, value) {
    state.token = value;
  },
  [TYPES.SET_REFRESH_TOKEN](state, value) {
    state.refreshToken = value;
  },
  [TYPES.SET_PAIRS](state, value) {
    state.pairs = value;
  },
  [TYPES.UPDATE_ASSET](state, value) {
    state.asset = value;
  },
  [TYPES.SET_BALANCE](state, value) {
    state.balance = { ...state.balance, ...value };
  },
  [TYPES.UPDATE_USER_PREFERENCE](state, value) {
    state.user_preference = { ...state.user_preference, ...value };
  },
  [TYPES.SET_CURRENCIES](state, value) {
    state.currencies = value;
  },
  [TYPES.UPDATE_MINIMUM_TRADEING_SIZE](state, value) {
    state.minimumTradingSize = value;
  },
  [TYPES.UPDATE_MENU_HEADER_TITLE](state, value) {
    state.menuHeaderTitle = value;
  },
  [TYPES.UPDATE_MODAL_VIEW](state, value) {
    state.modalView = value;
  },
  [TYPES.UPDATE_TRADE_SIDE](state, value) {
    state.tradeSide = value;
  },
  [TYPES.UPDATE_TRADE_QUANTITY](state, value) {
    state.tradeQuantity = value;
  },
  [TYPES.UPDATE_TRADE_PAIR](state, value) {
    state.tradePair = value;
  },
  [TYPES.UPDATE_BASE_MODAL_VISIBLE](state, value) {
    state.baseModalVisible = value;
  },
  [TYPES.UPDATE_PAYMENT_FROM](state, value) {
    state.paymentFrom = value;
  },
  [TYPES.UPDATE_NEED_LOAD](state, value) {
    state.needReload = value;
  },
  [TYPES.UPDATE_NEED_LOAD_AFTER_VERIFY](state, value) {
    state.needReloadAfterVerify = value;
  },
  [TYPES.UPDATE_EXCHANGE_NAME](state, value) {
    state.exchangeName = value;
  },
  [TYPES.UPDATE_PAYMENT_TYPE](state, value) {
    state.paymentType = value;
  },
  [TYPES.UPDATE_TOKEN_EXPIRES_DATE](state, value) {
    state.tokenExpiresDate = value;
  },
  [TYPES.UPDATE_TOKEN_TYPE](state, value) {
    state.tokenType = value;
  },
  [TYPES.UPDATE_IS_HIGH_RISK_IP](state, value) {
    state.isHighRiskIp = value;
  },
  [TYPES.UPDATE_IP_ADDRESS_INFO](state, value) {
    state.ipAddressInfo = { ...state.ipAddressInfo, ...value };
  },
  [TYPES.UPDATE_ORIGINAL_NEXT_KYC_STEP](state, value) {
    state.originalNextKycStep = value;
  },
  [TYPES.UPDATE_CURRENCY_UNIT_LIST](state, value) {
    state.currencyUnitList = { ...state.currencyUnitInfo, ...value };
  },
  [TYPES.UPDATE_FORBIDDEN_INFO](state, value) {
    state.forbiddenInfo = { ...state.forbiddenInfo, ...value };
  },
  [TYPES.UPDATE_USER_INFO](state, value) {
    state.userInfo = { ...state.userInfo, ...value };
  },
  [TYPES.UPDATE_SELECTED_BANK_TRANSFER_INFO](state, value) {
    state.selectedBankTransferInfo = {
      ...state.selectedBankTransferInfo,
      ...value
    };
  },
  [TYPES.UPDATE_DEFAULT_PAYMENT_TABS_ACTIVED](state, value) {
    state.defaultPaymentTabsActived = value;
  },
  [TYPES.UPDATE_APPROX_INFO](state, value) {
    state.approxInfo = { ...state.approxInfo, ...value };
  },
  [TYPES.UPDATE_QUOTE_LOAD_TIMER](state, value) {
    state.quoteLoadTimer = value;
  },
  [TYPES.UPDATE_DEFAULT_PAYMENT_ITEM_ACTIVED](state, value) {
    state.defaultPaymentItemActived = {
      ...state.defaultPaymentItemActived,
      ...value
    };
  },
  [TYPES.UPDATE_TRADE_BUY_ACH_PULL_PROCESS_INDEX](state, value) {
    state.tradeBuyAchPullProcessIndex = value;
  },
  [TYPES.SET_TRADE_BUY_ACH_PULL_PROCESSES](state, value) {
    state.tradeBuyAchPullProcesses = value;
  },
  [TYPES.UPDATE_ACCOUNT_STATUS](state, value) {
    state.accountStatus = value;
  },
  [TYPES.SET_CHECK_KYC_PROCESS_LIST](state, value) {
    state.checkKycProcessList = value;
  },
  [TYPES.UPDATE_TRADE_BUY_ACH_PULL_IN_KYC_PROCESS_INDEX](state, value) {
    state.tradeBuyAchPullInKycProcessIndex = value;
  },
  [TYPES.SET_TRADE_BUY_ACH_PULL_IN_KYC_PROCESSES](state, value) {
    state.tradeBuyAchPullInKycProcesses = value;
  },
  [TYPES.SET_PAYMENT_ACH_PULL_PROCESSES](state, value) {
    state.paymentAchPullProcesses = value;
  },
  [TYPES.UPDATE_PAYMENT_ACH_PULL_PROCESS_INDEX](state, value) {
    state.paymentAchPullProcessIndex = value;
  },
  [TYPES.SET_DEPOSIT_ACH_PULL_PROCESSES](state, value) {
    state.depositAchPullProcesses = value;
  },
  [TYPES.UPDATE_DEPOSIT_ACH_PULL_PROCESS_INDEX](state, value) {
    state.depositAchPullProcessIndex = value;
  },
  [TYPES.UPDATE_HAS_LINK_BANK_ACCOUNT](state, value) {
    state.hasLinkBankAccount = value;
  },
  [TYPES.UPDATE_QUOTE_SUBSCRIBE](state, value) {
    state.quoteSubscribe = value;
  },
  [TYPES.UPDATE_QUOTE_ERROR_MESSAGE](state, value) {
    state.quoteErrorMessage = value;
  },
  [TYPES.UPDATE_QUOTE_PARAMS](state, value) {
    state.quoteParams = {
      ...state.quoteParams,
      ...value
    };
  },
  [TYPES.SET_DEPOSIT_ACH_PULL_IN_KYC_PROCESSES](state, value) {
    state.depositAchPullInKycProcesses = value;
  },
  [TYPES.UPDATE_DEPOSIT_ACH_PULL_IN_KYC_PROCESS_INDEX](state, value) {
    state.depositAchPullInKycProcessIndex = value;
  }
};

const actions = {
  toggleLoading({ commit }, value) {
    commit(TYPES.TOGGLE_LOADING, value);
  },
  toggleMaintenance({ commit }, value) {
    commit(TYPES.TOGGLE_MAINTENANCE, value);
  },
  toggleZendesk({ commit }, value) {
    if (!window.zE) {
      return;
    } else {
      bindOnce();
      commit(TYPES.TOGGLE_ZENDESK, value);
    }
  },
  updateTsInMinutes({ commit }, value) {
    commit(TYPES.UPDATE_TS_IN_MINUTES, value);
  },
  updateKycStatus({ commit }, value) {
    commit(TYPES.UPDATE_KYC_STATUS, value);
  },
  updateNextKycStep({ commit }, value) {
    commit(TYPES.UPDATE_NEXT_KYC_STEP, value);
  },
  updateConnectStatus({ commit }, value) {
    commit(TYPES.UPDATE_CONNECT_STATUS, value);
  },
  updateAccountEmail({ commit }, value) {
    commit(TYPES.UPDATE_ACCOUNT_EMAIL, value);
  },
  updateAccountType({ commit }, value) {
    commit(TYPES.UPDATE_ACCOUNT_TYPE, value);
  },
  updateExpiresIn({ commit }, value) {
    commit(TYPES.UPDATE_EXPIRES_IN, value);
  },
  updateExpiresAt({ commit }, value) {
    commit(TYPES.UPDATE_EXPIRES_AT, value);
  },
  updateExchangeConfig({ commit }, value) {
    commit(TYPES.UPDATE_EXCHANGE_CONFIG, value);
  },
  updateClientInfo({ commit }, value) {
    commit(TYPES.UPDATE_CLIENT_INFO, value);
  },
  setThemeColor({ commit }, value) {
    commit(TYPES.SET_THEME_COLOR, value);
  },
  setBgThemeColor({ commit }, value) {
    commit(TYPES.SET_BG_THEME_COLOR, value);
  },
  setUiSize({ commit }, value) {
    commit(TYPES.SET_UI_SIZE, value);
  },
  setToken({ commit }, value) {
    commit(TYPES.SET_TOKEN, value);
  },
  setRefreshToken({ commit }, value) {
    commit(TYPES.SET_REFRESH_TOKEN, value);
  },
  setPairs({ commit }, value) {
    commit(TYPES.SET_PAIRS, value);
  },
  updateAsset({ commit }, value) {
    commit(TYPES.UPDATE_ASSET, value);
  },
  setBalance({ commit }, value) {
    commit(TYPES.SET_BALANCE, value);
  },
  updateUserPreference({ commit }, value) {
    commit(TYPES.UPDATE_USER_PREFERENCE, value);
  },
  setCurrencies({ commit }, value) {
    commit(TYPES.SET_CURRENCIES, value);
  },
  updateMinimumTradingSize({ commit }, value) {
    commit(TYPES.UPDATE_MINIMUM_TRADEING_SIZE, value);
  },
  updateMenuHeaderTitle({ commit }, value) {
    commit(TYPES.UPDATE_MENU_HEADER_TITLE, value);
  },
  updateModalView({ commit }, value) {
    commit(TYPES.UPDATE_MODAL_VIEW, value);
  },
  updateTradeSide({ commit }, value) {
    commit(TYPES.UPDATE_TRADE_SIDE, value);
  },
  updateTradeQuantity({ commit }, value) {
    commit(TYPES.UPDATE_TRADE_QUANTITY, value);
  },
  updateTradePair({ commit }, value) {
    commit(TYPES.UPDATE_TRADE_PAIR, value);
  },
  updateBaseModalVisible({ commit }, value) {
    commit(TYPES.UPDATE_BASE_MODAL_VISIBLE, value);
  },
  updatePaymentFrom({ commit }, value) {
    commit(TYPES.UPDATE_PAYMENT_FROM, value);
  },
  updateNeedLoad({ commit }, value) {
    commit(TYPES.UPDATE_NEED_LOAD, value);
  },
  updateNeedReloadAfterVerify({ commit }, value) {
    commit(TYPES.UPDATE_NEED_LOAD_AFTER_VERIFY, value);
  },
  updateExchangeName({ commit }, value) {
    commit(TYPES.UPDATE_EXCHANGE_NAME, value);
  },
  updatePaymentType({ commit }, value) {
    commit(TYPES.UPDATE_PAYMENT_TYPE, value);
  },
  updateTokenExpiresDate({ commit }, value) {
    commit(TYPES.UPDATE_TOKEN_EXPIRES_DATE, value);
  },
  updateTokenType({ commit }, value) {
    commit(TYPES.UPDATE_TOKEN_TYPE, value);
  },
  async setupRfqParams({ commit, getters }) {
    if (getters.rfqParams) {
      const { fiat, crypto, amount, payment = "deposit" } = getters.rfqParams;
      if (typeof fiat !== "string") {
        // eslint-disable-next-line
        console.error("Invalid [fiat] in request-for-quotation parameters");
      } else if (typeof crypto !== "string") {
        // eslint-disable-next-line
        console.error("Invalid [crypto] in request-for-quotation parameters");
      } else if (typeof amount !== "string") {
        // eslint-disable-next-line
        console.error("Invalid [amount] in request-for-quotation parameters");
      } else if (!["credit_card", "deposit"].includes(payment)) {
        // eslint-disable-next-line
        console.error("Invalid [payment] in request-for-quotation parameters");
      } else {
        // setup rfq params
        commit(TYPES.UPDATE_ASSET, fiat);
        commit(TYPES.UPDATE_TRADE_PAIR, crypto);
        commit(TYPES.UPDATE_TRADE_QUANTITY, amount);
        commit(TYPES.UPDATE_PAYMENT_TYPE, payment);
      }

      // clear rfq params
      await Vue.nextTick();
      commit(TYPES.UPDATE_CLIENT_INFO, { requestForQuotation: "" });
    }
  },
  updateIsHighRiskIp({ commit }, value) {
    commit(TYPES.UPDATE_IS_HIGH_RISK_IP, value);
  },
  updateIpAddressInfo({ commit }, value) {
    commit(TYPES.UPDATE_IP_ADDRESS_INFO, value);
  },
  updateOriginalNextKycStep({ commit }, value) {
    commit(TYPES.UPDATE_ORIGINAL_NEXT_KYC_STEP, value);
  },
  updateCurrencyUnitList({ commit }, value) {
    commit(TYPES.UPDATE_CURRENCY_UNIT_LIST, value);
  },
  updateForbiddenInfo({ commit }, value) {
    commit(TYPES.UPDATE_FORBIDDEN_INFO, value);
  },
  updateUserInfo({ commit }, value) {
    commit(TYPES.UPDATE_USER_INFO, value);
  },
  updateSelectedBankTransferInfo({ commit }, value) {
    commit(TYPES.UPDATE_SELECTED_BANK_TRANSFER_INFO, value);
  },
  updateDefaultPaymentTabsActived({ commit }, value) {
    commit(TYPES.UPDATE_DEFAULT_PAYMENT_TABS_ACTIVED, value);
  },
  updateApproxInfo({ commit }, value) {
    commit(TYPES.UPDATE_APPROX_INFO, value);
  },
  updateQuoteLoadTimer({ commit }, value) {
    commit(TYPES.UPDATE_QUOTE_LOAD_TIMER, value);
  },
  updateDefaultPaymentItemActived({ commit }, value) {
    commit(TYPES.UPDATE_DEFAULT_PAYMENT_ITEM_ACTIVED, value);
  },
  updateTradeBuyAchPullProcessIndex({ commit }, value = 0) {
    commit(TYPES.UPDATE_TRADE_BUY_ACH_PULL_PROCESS_INDEX, value);
  },
  setTradeBuyAchPullProcesses({ commit }, value) {
    commit(TYPES.SET_TRADE_BUY_ACH_PULL_PROCESSES, value);
  },
  updateAccountStatus({ commit }, value) {
    commit(TYPES.UPDATE_ACCOUNT_STATUS, value);
  },
  setCheckKycProcessList({ commit }, value) {
    commit(TYPES.SET_CHECK_KYC_PROCESS_LIST, value);
  },
  updateTradeBuyAchPullInKycProcessIndex({ commit }, value = 0) {
    commit(TYPES.UPDATE_TRADE_BUY_ACH_PULL_IN_KYC_PROCESS_INDEX, value);
  },
  setTradeBuyAchPullInKycProcesses({ commit }, value) {
    commit(TYPES.SET_TRADE_BUY_ACH_PULL_IN_KYC_PROCESSES, value);
  },
  setPaymentAchPullProcesses({ commit }, value) {
    commit(TYPES.SET_PAYMENT_ACH_PULL_PROCESSES, value);
  },
  updatePaymentAchPullProcessIndex({ commit }, value = 0) {
    commit(TYPES.UPDATE_PAYMENT_ACH_PULL_PROCESS_INDEX, value);
  },
  setDepositAchPullProcesses({ commit }, value) {
    commit(TYPES.SET_DEPOSIT_ACH_PULL_PROCESSES, value);
  },
  updateDepositAchPullProcessIndex({ commit }, value = 0) {
    commit(TYPES.UPDATE_DEPOSIT_ACH_PULL_PROCESS_INDEX, value);
  },
  updateLinkBankAccount({ commit }, value) {
    commit(TYPES.UPDATE_HAS_LINK_BANK_ACCOUNT, value);
  },
  updateQuoteSubscribe({ commit }, value) {
    commit(TYPES.UPDATE_QUOTE_SUBSCRIBE, value);
  },
  updateQuoteErrorMessage({ commit }, value) {
    commit(TYPES.UPDATE_QUOTE_ERROR_MESSAGE, value);
  },
  updateQuoteParams({ commit }, value) {
    commit(TYPES.UPDATE_QUOTE_PARAMS, value);
  },
  setDepositAchPullInKycProcesses({ commit }, value) {
    commit(TYPES.SET_DEPOSIT_ACH_PULL_IN_KYC_PROCESSES, value);
  },
  updateDepositAchPullInKycProcessIndex({ commit }, value = 0) {
    commit(TYPES.UPDATE_DEPOSIT_ACH_PULL_IN_KYC_PROCESS_INDEX, value);
  }
};

const getters = {
  urlAssets: state => state.clientInfo.urlAssets,
  urlDeposit: state => state.clientInfo.urlDeposit,
  isAccountDisabled: ({ kycStatus }) =>
    ["Not Started", "Started"].includes(kycStatus),
  isAccountEnabled: ({ kycStatus }) => ["Approved"].includes(kycStatus),
  isAccountRejected: ({ kycStatus }) => ["Rejected"].includes(kycStatus),
  isAccountPending: ({ kycStatus }) =>
    ["In Progress", "Submitted", "In Review"].includes(kycStatus),
  needContinueKyc: ({ kycStatus }) =>
    ["Not Started", "Started", "In Progress"].includes(kycStatus),
  autoWithdrawal: state => state.exchangeConfig.auto_withdrawal,
  asset: state => state.asset,
  balance: state => state.balance,
  activeBanner: ({ tsInMinutes, user_preference }) => {
    if (Array.isArray(user_preference.banner)) {
      return user_preference.banner.filter(n => {
        const dtStart = dayjs(n.start).valueOf();
        const dtEnd = dayjs(n.end).valueOf();
        // console.log("active banner timestamp", tsInMinutes, dtStart, dtEnd);
        return tsInMinutes >= dtStart && tsInMinutes <= dtEnd;
      });
    } else {
      return [];
    }
  },
  isVerifyFinished: ({ nextKycStep }) =>
    !["reviewing_id", "verify_id"].includes(nextKycStep),
  isSurveyStarted: ({ nextKycStep }) =>
    ["questionnaire", "rejected_questionnaire"].includes(nextKycStep),
  isSurveyPending: ({ nextKycStep }) =>
    ["submitted_questionnaire", "reviewing_questionnaire"].includes(
      nextKycStep
    ),
  isSurveyApproved: ({ nextKycStep }) =>
    ["approved_questionnaire"].includes(nextKycStep),
  rfqParams: ({ clientInfo }) => {
    try {
      if (clientInfo.requestForQuotation) {
        return JSON.parse(clientInfo.requestForQuotation);
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  },
  allowedLangs: ({ clientInfo }) => {
    const supportLocales = sortSupportLocales();

    try {
      if (clientInfo.langs) {
        const defaultLangs = JSON.parse(clientInfo.langs);
        acceptLanguage.languages(supportLocales);

        const resultSet = new Set();
        defaultLangs.map(defaultLang => {
          resultSet.add(acceptLanguage.get(defaultLang));
        });
        const resultLangs = Array.from(resultSet);
        const clientLang = acceptLanguage.get(clientInfo.lang);
        if (!resultLangs.includes(clientLang)) {
          // 用户传递的优先级最高放在第一个
          resultLangs.unshift(clientLang);
        }
        return resultLangs;
      } else {
        return supportLocales;
      }
    } catch (err) {
      return supportLocales;
    }
  },
  disabledCreditcard: ({ exchangeConfig }) => {
    try {
      const allowedPaymentMethods =
        exchangeConfig?.allowed_payment_methods ?? [];
      const len = allowedPaymentMethods.length ?? 0;
      return !(len === 1 && allowedPaymentMethods.includes("creditcard"));
    } catch (err) {
      return false;
    }
  },
  allowUsdWireTransfer: ({ exchangeConfig }) => {
    const defaultSwitch = "off";
    const toggle = exchangeConfig?.allow_usd_wire_transfer ?? defaultSwitch;
    const value = toggle === "on" ? true : false;
    return {
      toggle,
      value
    };
  },
  isEddPendingStatus: ({ userInfo }) => {
    try {
      const { edd } = userInfo;
      return JSON.stringify(edd) !== "{}";
    } catch (err) {
      return false;
    }
  },
  isRfiPendingStatus: ({ userInfo }) => {
    try {
      const { rfi } = userInfo;
      return JSON.stringify(rfi) !== "{}";
    } catch (err) {
      return false;
    }
  },
  allowCreditcard: ({ exchangeConfig }) => {
    const allowedPaymentMethods = exchangeConfig?.allowed_payment_methods ?? [];
    return allowedPaymentMethods.includes("creditcard");
  },
  allowAchPull: ({ asset, tradePair, pairs }) => {
    const pair = `${tradePair}${asset}`;
    const pairInfo = pairs.find(itemPair => {
      return Object.keys(itemPair).find(subPair => {
        return subPair === pair;
      });
    });
    if (!pairInfo) return false;

    const allowedPaymentMethods = pairInfo[pair]?.allowed_payment_methods ?? [];
    return allowedPaymentMethods.includes("ach");
  },
  allowPagsmile: ({ asset, tradePair, pairs }) => {
    const pair = `${tradePair}${asset}`;
    const pairInfo = pairs.find(itemPair => {
      return Object.keys(itemPair).find(subPair => {
        return subPair === pair;
      });
    });
    if (!pairInfo) return false;

    const allowedPaymentMethods = pairInfo[pair]?.allowed_payment_methods ?? [];
    return allowedPaymentMethods.includes("local_payments");
  }
};

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    APICONFIG
  }
});

interval(1000).subscribe(() => {
  const now = (dt => dt - (dt % 60000))(new Date());
  store.dispatch("updateTsInMinutes", now);
});

export default store;
