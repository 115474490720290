const routes = [
  {
    path: "/individual/deposit",
    name: "individual.deposit",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/Deposit.vue"
      )
  },
  {
    path: "/individual/bankTransfer/notice",
    name: "individual.bankTransfer.notice",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/bankTransfer/BankTransferNotice.vue"
      )
  },
  {
    path: "/individual/bankTransfer/confirm",
    name: "individual.bankTransfer.confirm",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/bankTransfer/ConfirmDeposit.vue"
      )
  },
  {
    path: "/individual/bankTransfer/finished",
    name: "individual.bankTransfer.finished",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/bankTransfer/DepositFinished.vue"
      )
  },
  {
    path: "/individual/wireTransfer/notice",
    name: "individual.wireTransfer.notice",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/wireTransfer/WireNotice.vue"
      )
  },
  {
    path: "/individual/wireTransfer/chooseCurrency",
    name: "individual.wireTransfer.chooseCurrency",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/wireTransfer/ChooseCurrency.vue"
      )
  },
  {
    path: "/individual/wireTransfer/confirm",
    name: "individual.wireTransfer.confirm",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/wireTransfer/ConfirmDeposit.vue"
      )
  },
  {
    path: "/individual/wireTransfer/finished",
    name: "individual.wireTransfer.finished",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/wireTransfer/DepositFinished.vue"
      )
  },
  {
    path: "/individual/achpull/enterAmount",
    name: "individual.achpull.enterAmount",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/achPull/EnterAmount.vue"
      )
  },
  {
    path: "/individual/achpull/confirmDesposit",
    name: "individual.achpull.confirmDesposit",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/achPull/ConfirmDeposit.vue"
      )
  },
  {
    path: "/individual/achpull/verifyEmail",
    name: "individual.achpull.verifyEmail",
    meta: { type: "achPull_deposit_in_kyc" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/achPull/VerifyEmail.vue"
      )
  },
  {
    path: "/individual/deposit/achPull/ssnInfo",
    name: "individual.deposit.achPull.ssnInfo",
    meta: {
      title: "LINK YOUR BANK ACCOUNT",
      type: "achPull_deposit_in_kyc"
    },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../../views/register/individual/deposit/SsnInfo.vue"
      )
  },
  {
    path: "/individual/deposit/achPull/verifyIdentify",
    name: "individual.deposit.achPull.verifyIdentify",
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../../../views/register/individual/deposit/VerifyIdentify.vue"
      ),
    meta: {
      type: "achPull_deposit_in_kyc",
      title: "VERIFY YOUR IDENTITY",
      showBackBtn: true
    }
  },
  {
    path: "/individual/deposit/achPull/autoVerify",
    name: "individual.deposit.achPull.autoVerify",
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../../../views/register/individual/deposit/AutoVerify.vue"
      ),
    meta: {
      type: "achPull_deposit_in_kyc",
      title: "VERIFY YOUR IDENTITY",
      showBackBtn: true
    }
  },
  {
    path: "/individual/deposit/achPull/verifyFailed",
    name: "individual.deposit.achPull.verifyFailed",
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../../../views/register/individual/deposit/VerifyFailed.vue"
      ),
    meta: {
      type: "achPull_deposit_in_kyc",
      showBackBtn: true
    }
  }
];

export default routes;
