<template>
  <section :class="$style.wrapper">
    <a-select
      :style="{ 'min-width': canTradePaireEdit ? '100px' : '70px' }"
      :dropdownMatchSelectWidth="false"
      :getPopupContainer="() => $root.$el"
      :dropdownClassName="$style.currencyDropdown"
      :disabled="!canTradePaireEdit"
      :showArrow="canTradePaireEdit"
      :value="asset"
      @change="onChange"
    >
      <a-icon slot="suffixIcon" :component="CaretDown" />
      <a-select-option v-for="item in assetList" :key="item">
        <span
          v-if="assetType === 'fiat'"
          class="fi"
          :class="[$style.flagBg, flagClass(item)]"
        />
        <cryptoicon
          v-if="assetType === 'crypto'"
          generic
          :symbol="item"
          :class="$style.cryptoBg"
        />
        <span :class="$style.currencyItem">
          {{ item }}
        </span>
      </a-select-option>
    </a-select>
  </section>
</template>

<script>
import { Spin, Icon, Select } from "ant-design-vue";
import { setCurrencyFlag } from "@/utils";
import CaretDown from "@/assets/img/common/caret-down.svg?inline";

export default {
  name: "Register.AssetSelector",
  model: {
    prop: "asset",
    event: "change"
  },
  props: {
    canTradePaireEdit: [Boolean],
    asset: [String],
    assetType: [String],
    assetList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      CaretDown
    };
  },
  computed: {
    flagClass() {
      return currency => {
        return `fi-${setCurrencyFlag(currency)}`;
      };
    }
  },
  components: {
    [Icon.name]: Icon,
    [Spin.name]: Spin,
    [Select.name]: Select,
    [Select.Option.name]: Select.Option
  },
  methods: {
    onChange(value) {
      this.$emit("change", value);
    }
  },
  created() {},
  mounted() {}
};
</script>
<style module lang="scss">
@import "~rfs/scss";

.wrapper {
  .flagBg,
  .cryptoBg {
    @include rfs(16px, width);
    @include rfs(16px, height);
    @include rfs(6px, margin-right);

    border-radius: 50%;
    background-size: cover;
    box-shadow: 0.0625rem 0.0625rem 0.1875rem 0rem var(--silver);
  }
  .currencyItem {
    @include rfs(14px);

    color: var(--dark);
  }

  :global(.ant-select-selection) {
    background-color: #f7f7f7;
    border-radius: rfs-value(15px);
  }

  :global(.flagBg) {
    @include rfs(16px, width);
    @include rfs(16px, height);
    @include rfs(4px, margin-right);

    border-radius: 50%;
    background-size: cover;
    box-shadow: 0.0625rem 0.0625rem 0.1875rem 0rem var(--silver);
  }

  :global(.ant-select-selection-selected-value) {
    svg {
      vertical-align: middle;
    }

    display: flex;
    align-items: center;
  }

  :global(.ant-select-arrow-icon svg) {
    @include rfs(8px, height);
  }
}

.currencyDropdown {
  background: transparent;

  :global(.ant-select-dropdown-menu-item) {
    @include rfs(3rem, height);
    @include rfs(0.4375rem 0.75rem, padding);

    display: flex;
    align-items: center;
    // justify-content: space-between;

    & > svg {
      @include rfs(16px, width);
      @include rfs(16px, height);
      @include rfs(6px, margin-right);

      border-radius: 50%;
      background-size: cover;
      box-shadow: 0.0625rem 0.0625rem 0.1875rem 0rem var(--silver);
    }

    & > span {
      @include rfs(16px, width);
      @include rfs(16px, height);
      @include rfs(6px, margin-right);

      border-radius: 50%;
      background-size: cover;
      box-shadow: 0.0625rem 0.0625rem 0.1875rem 0rem var(--silver);
    }

    span:last-child {
      @include rfs(16px);

      text-align: left;
      color: var(--dark);
    }
  }
  :global {
    .ant-select-dropdown-content {
      background: transparent;

      .ant-select-dropdown-menu {
        border-radius: 0.625rem;
        border: solid 0.0625rem var(--silver);
        background: var(--white);

        /* 滚动条整体部分 */
        &::-webkit-scrollbar {
          @include rfs(0.1875rem, width);
          @include rfs(1.1875rem, height);

          border-radius: 0.625rem;
          background-color: var(--silver);
        }
        /* scroll轨道背景 */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);

          background-color: var(--white);
        }

        /* 滚动条中能上下移动的小块 */
        &::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
          background-color: var(--silver);
        }

        &::-webkit-scrollbar-button {
          // background-color: #7c2929;
        } /* 滑轨两头的监听按钮颜色 */
      }
    }
  }
}
</style>
