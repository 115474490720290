import {
  UPDATE_CURRENT_STEP_INDEX,
  UPDATE_REGISTER_EMAIL,
  UPDATE_REGISTER_PHONE,
  UPDATE_FIRST_NAME,
  CHANGE_CURRENT_STEP_4_INDIVIDUAL_STATUS
} from "./mutation-types";

// inital state
const state = {
  registerEmail: "",
  registerPhone: {
    dialCode: "",
    phone: ""
  },
  currentStepIndex: 0,
  firstName: "",
  registerStepLists4Individual: [
    {
      name: "individual.personalInfo",
      status: "process", // process | finish
      title: "BASIC INFO",
      desc: "Enter your name, DOB, and address",
      includes: ["individual.personalInfo", "individual.userAddress"],
      hidden: false
    },
    {
      name: "individual.kycSurvey",
      status: "process",
      title: "QUESTIONNAIRE",
      desc: "Fast survey for a full experience",
      includes: ["individual.kycSurvey"],
      hidden: false
    },
    {
      name: "individual.verifyIdentify",
      status: "process",
      title: "VERIFY IDENTITY",
      desc: "Take a selfie and photos your ID",
      includes: ["individual.verifyIdentify"],
      hidden: false
    },
    {
      name: "individual.buyConfirm",
      status: "process",
      title: "PAYMENT",
      desc: "Choose a payment method",
      includes: ["individual.buyConfirm"],
      hidden: false
    }
  ]
};
// getters
const getters = {};
// actions
const actions = {
  updateCurrentStepIndex({ commit }, value) {
    commit(UPDATE_CURRENT_STEP_INDEX, value);
  },
  updateResigsterEmail({ commit }, value) {
    commit(UPDATE_REGISTER_EMAIL, value);
  },
  updateRegisterPhone({ commit }, data) {
    commit(UPDATE_REGISTER_PHONE, data);
  },
  updateFirstName({ commit }, value) {
    commit(UPDATE_FIRST_NAME, value);
  },
  changeCurrentStep4IndividualStatus({ commit }, value) {
    commit(CHANGE_CURRENT_STEP_4_INDIVIDUAL_STATUS, value);
  }
};
// mutations
const mutations = {
  [UPDATE_CURRENT_STEP_INDEX](state, value) {
    state.currentStepIndex = value;
  },
  [UPDATE_REGISTER_EMAIL](state, value) {
    state.registerEmail = value;
  },
  [UPDATE_REGISTER_PHONE](state, data) {
    state.registerPhone = { ...state.registerPhone, ...data };
  },
  [UPDATE_FIRST_NAME](state, value) {
    state.firstName = value;
  },
  [CHANGE_CURRENT_STEP_4_INDIVIDUAL_STATUS](state, value) {
    state.registerStepLists4Individual[value].status = "finish";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
