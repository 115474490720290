<template>
  <section>
    <p>{{ $t("SAVED PAYMENT METHODS") }}</p>
    <TradePaymentList
      :isLoading="isLoading"
      :isEnoughBalance="isEnoughBalance"
      :paymentList="groupPaymentList"
    />

    <div :class="$style.userAgreement">
      <div
        :class="$style.achPullTips"
        v-if="!isAchPullAccountDataListEmpty && isAchPullTabSelected"
      >
        I authorize Bakkt Marketplace, LLC to transfer ${{ tradeQuantity }} from
        my account held at Bakkt Marketplace, LLC to Bakkt Crypto Solutions, LLC
        to pay for my purchase of {{ tradePair }}. The actual quantity of coins
        purchased may change due to volatility in the price of {{ tradePair }},
        but your order will be executed based on the best price available to
        Bakkt Crypto Solutions, LLC. Cryptocurrency transactions are not FDIC or
        SIPC insured. <span @click.stop="toDisclosures">View Disclosures</span>.
      </div>

      <BaseCheckBox
        v-if="!isAchPullTabSelected"
        :class="$style.checkBox"
        :checked.sync="checked"
        @click="onHandleChange"
      >
        <i18n path="trade_buy_confirm_agreement" tag="div">
          <template #agreement>
            <span @click.stop="toUserAgreement">{{
              $t("User Agreement")
            }}</span>
          </template>
        </i18n>
      </BaseCheckBox>
    </div>

    <div v-if="showErrorMessage" :class="$style.errors">
      <icon-error :class="$style.iconTips" />
      <span>{{ quoteErrorMessage }}</span>
    </div>

    <BaseButton
      type="primary"
      :class="$style.baseButton"
      :loading="spinning || isLoading || quoteLoadTimer"
      :passed="!isPassed"
      @click="handleOrderConfirm"
    >
      {{ $t("Confirm") | uppercase }}
    </BaseButton>

    <div :class="$style.cancelButton" @click="onHandleCancel">
      {{ $t("Cancel") | uppercase }}
    </div>

    <ModalBase
      type="payment"
      :message="msgRedirect"
      :orderConfirmInfo="orderConfirmInfo"
      :spinning="spinning"
      :visible.sync="isBaseModalVisible"
      :failedInfo="failedInfo"
      :pendingInfo="pendingInfo"
      :eddFailedInfo="eddFailedInfo"
      :rfiFailedInfo="rfiFailedInfo"
      :riskInfo="riskInfo"
      :cardInfo="activeCreditCardPayment"
      :achOrderErrorInfo="achOrderErrorInfo"
      @cancel="onCancel"
      @ok="onOk"
      @navigate="toTradeHistory"
      @reLink="toReLink"
    />

    <ModalCheckout
      :visible.sync="isIframeVisible"
      :urlRedirect="urlRedirect"
      @checkout:success="onCheckoutRespSuccess"
      @checkout:failed="onCheckoutRespFailed"
      @checkout:pending="onCheckoutRespPending"
      @checkout:error="onCheckoutRespError"
      @checkout:complete="onCheckoutRespComplete"
      @cancel="onIframeCancel"
    />
  </section>
</template>

<script>
import { debounce } from "lodash-es";
import { mapGetters, mapState, mapActions } from "vuex";
import { Spin, Modal, Icon } from "ant-design-vue";
import emitter from "@/utils/eventBus";
import TradePaymentList from "@/components/tradePaymentList/Index.vue";
import BaseCheckBox from "@/components/BaseCheckBox.vue";
import BaseButton from "@/components/BaseButton.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import ModalCheckout from "@/components/checkout/ModalCheckout.vue";
import IconError from "@/assets/img/common/icon-error.svg?inline";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";
import {
  queryTradePaymentMethods,
  submitOrderForFiat,
  confirmWithdraw
} from "@/api";
import { displayErrors, paymentConfig } from "@/utils";
import { currencyLoadMixin } from "@/mixin/currencyLoadMixin";
import { commonMixin } from "@/mixin/commonMixin";
import { quoteMixin } from "@/mixin/quoteMixin";

export default {
  name: "Trade.Payment.List",
  inject: ["publishCheckoutRiskData"],
  props: {
    workflow: [String]
  },
  mixins: [currencyLoadMixin, commonMixin, quoteMixin],
  data() {
    return {
      isLoading: false,
      checked: false,
      paymentConfig,
      groupPaymentList: [],
      timeInterval: 15000,
      source: null,
      subscription: null,
      isBaseModalVisible: false,
      spinning: false,
      orderConfirmInfo: {},
      IconWait,
      isIframeVisible: false,
      urlRedirect: "",
      msgRedirect: "",
      failedInfo: {
        subject: "",
        message: ""
      },
      pendingInfo: {
        subject: "",
        message: ""
      },
      eddFailedInfo: {
        subject: "",
        message: ""
      },
      rfiFailedInfo: {
        subject: "",
        message: "",
        rfiUrl: ""
      },
      riskInfo: {
        subject: "",
        message: ""
      },
      achOrderErrorInfo: {}
    };
  },
  computed: {
    ...mapState([
      "tradeQuantity",
      "tradeSide",
      "tradePair",
      "modalView",
      "currencies",
      "currencyUnitList",
      "defaultPaymentTabsActived",
      "quoteLoadTimer",
      "defaultPaymentItemActived",
      "paymentFrom",
      "quoteErrorMessage",
      "quoteParams"
    ]),
    ...mapState({
      appState: state => state.clientInfo.appState
    }),
    ...mapGetters({
      autoWithdrawal: "autoWithdrawal",
      asset: "asset",
      balance: "balance",
      allowCreditcard: "allowCreditcard",
      allowAchPull: "allowAchPull",
      allowPagsmile: "allowPagsmile"
    }),
    quantity: {
      get() {
        return this.tradeQuantity;
      },
      set(value) {
        this.updateTradeQuantity(value);
      }
    },
    isEnoughBalance() {
      return parseFloat(this.tradeQuantity) <= parseFloat(this.ownBalance);
    },
    ownBalance() {
      const asset = this.asset;
      const _balance = this.balance;
      const existBalance = _balance.fiat[asset];
      return (existBalance && existBalance.amount) || 0;
    },
    activeCreditCardPayment() {
      const creditCardPaymentInfo = this.activePayment("credit_card");

      const selectCreditCardPayment = creditCardPaymentInfo?.list?.find(
        item => item.checked
      );
      return selectCreditCardPayment;
    },
    isCardPayment() {
      return this.defaultPaymentTabsActived === "credit_card";
    },
    isPassed() {
      const paymentInfo = this.activePayment(this.defaultPaymentTabsActived);
      const isPaymentPassed = paymentInfo?.list?.some(item => item.checked);

      if (this.isAchPullTabSelected) {
        return isPaymentPassed;
      } else {
        return isPaymentPassed && this.checked;
      }
    },
    debouncedHandleConfirm() {
      return debounce(this.onOrderConfirm, 1000);
    },
    showErrorMessage() {
      return this.quoteErrorMessage !== ""; //不为空就展示
    },
    isAchPullTabSelected() {
      const defaultPaymentItemInfo = this.defaultPaymentItemActived[
        this.defaultPaymentTabsActived
      ];
      const { type } = defaultPaymentItemInfo;
      // 如果tab是bank_transfer 或者 是tab是balance但是item是ach_balance
      // 也可以直接用type来判断，支持achPull并且 type是ach
      return (
        (this.allowAchPull &&
          this.defaultPaymentTabsActived === "bank_transfer") ||
        ["ach_balance"].includes(type)
      );
    },
    isAchPullAccountDataListEmpty() {
      const filterPayment = this.groupPaymentList.find(
        item => item.groupType === this.defaultPaymentTabsActived
      );
      const dataList = filterPayment?.list ?? [];

      return (
        dataList.length === 0 &&
        this.defaultPaymentTabsActived === "bank_transfer"
      );
    }
  },
  components: {
    [Icon.name]: Icon,
    [Modal.name]: Modal,
    [Spin.name]: Spin,
    BaseCheckBox,
    BaseButton,
    ModalBase,
    ModalCheckout,
    IconError,
    TradePaymentList
  },
  methods: {
    ...mapActions([
      "updateTradeQuantity",
      "updateModalView",
      "updatePaymentFrom",
      "updateSelectedBankTransferInfo",
      "updateApproxInfo",
      "updateQuoteLoadTimer",
      "updateDefaultPaymentItemActived",
      "updateDefaultPaymentTabsActived",
      "updateQuoteErrorMessage",
      "updateQuoteParams"
    ]),
    onHandleChange(value) {
      this.checked = !value;
    },
    handleOrderConfirm() {
      if (this.isCardPayment) {
        this.updateModalView("EnterCvv");
        this.isBaseModalVisible = true;
      } else {
        this.debouncedHandleConfirm();
      }
    },
    onCheckoutRespSuccess(response) {
      this.handleOrderResponse(response.data);
    },
    onCheckoutRespPending(response) {
      this.handlePendingResponse(response);
    },
    onCheckoutRespFailed(response) {
      this.handleFailedResponse(response);
    },
    onCheckoutRespError(err) {
      this.displayErrors(err);
    },
    onCheckoutRespComplete() {
      this.isIframeVisible = false;
      this.spinning = false;
    },
    handleFailedResponse(response) {
      this.failedInfo.subject = response?.subject ?? "";
      this.failedInfo.message = response?.message ?? "";
      this.updateModalView("OopsFailed");
      this.isBaseModalVisible = true;
    },
    handleOrderRedirect(url) {
      this.urlRedirect = url;
      this.isIframeVisible = true;
    },
    handleOrderResponse(result) {
      const modalView = this.autoWithdrawal
        ? "PurchasedMediately"
        : "PurchasedImmediately";
      this.updateModalView(modalView);
      this.isBaseModalVisible = true;
      this.orderConfirmInfo = result;
      this.cleanup();
      this.reloadBalance();
      this.reloadCurrency();
      this.spinning = false;
    },
    handlePendingResponse(response) {
      // show a specific modal for payment pending
      this.pendingInfo.subject = response?.subject ?? "";
      this.pendingInfo.message = response?.message ?? "";
      this.updateModalView("OopsPending");
      this.isBaseModalVisible = true;
      this.cleanup();
      this.reloadBalance();
      this.reloadCurrency();
      this.spinning = false;
    },
    handleRiskErrors(errorData) {
      this.riskInfo.subject = errorData?.errors?.risk ?? "";
      this.riskInfo.message = errorData?.message ?? "";
      this.updateModalView("RiskError");
      this.isBaseModalVisible = true;
    },
    handleEddErrors(errorData) {
      this.eddFailedInfo.subject = errorData?.errors?.edd ?? "";
      this.eddFailedInfo.message = errorData?.message ?? "";
      this.updateModalView("EddError");
      this.isBaseModalVisible = true;
    },
    handleRfiErrors(errorData) {
      this.rfiFailedInfo.subject = errorData?.errors?.rfi ?? "";
      this.rfiFailedInfo.message = errorData?.message ?? "";
      this.rfiFailedInfo.rfiUrl = errorData?.data?.url ?? "";
      this.updateModalView("RfiError");
      this.isBaseModalVisible = true;
    },
    handlePaymentErrors() {
      this.updateModalView("OopsFailed");
      this.isBaseModalVisible = true;
    },
    handleAchOrderErrors(errorData) {
      this.achOrderErrorInfo.subject = errorData?.subject ?? "Oops";
      this.achOrderErrorInfo.message = errorData?.message ?? "System Error";
      if (errorData?.errors?.token) {
        this.updateModalView("ReLinkAccount");
        this.isBaseModalVisible = true;
      } else {
        this.updateModalView("AchOrderError");
        this.isBaseModalVisible = true;
      }
    },
    async handleBalanceAndCardProcess(cvv) {
      try {
        this.spinning = true;
        // additional_data 后续需要加一下
        // 根据选择的支付方式，决定是否需要传递additional_data
        const params = cvv
          ? { ...this.quoteParams, app_state: this.appState, cvv }
          : { ...this.quoteParams, app_state: this.appState };
        const device_session_id = await this.publishCheckoutRiskData();
        // console.log(">>>>>>>>>>>> device_session_id: ", device_session_id);
        const result = await submitOrderForFiat({
          ...params,
          device_session_id
        });
        if (result.redirect_url) {
          // Checkout Prism
          // response.status === 202
          this.handleOrderRedirect(result.redirect_url);
        } else if (!result.errors) {
          this.handleOrderResponse(result);
        } else {
          this.spinning = false;
          displayErrors(result);
        }
      } catch (err) {
        this.spinning = false;
        const errorData = err?.response?.data;
        if (errorData?.message?.includes?.("payment")) {
          // show a specific modal for payment failed
          this.handlePaymentErrors();
        } else if (errorData?.errors?.rfi) {
          this.handleRfiErrors(errorData);
        } else if (errorData?.errors?.edd) {
          this.handleEddErrors(errorData);
        } else if (errorData?.errors?.risk) {
          this.handleRiskErrors(errorData);
        } else {
          displayErrors(err);
        }
      }
    },
    handleThirdPartyProcess() {
      this.updatePaymentFrom("");
      if (this.workflow === "trade") {
        this.$router.push({ name: "home.trade.buy.payment.bankinfo" });
      } else {
        this.$router.push({ name: "individual.trade.buy.payment.bankinfo" });
      }
    },
    async handleAchPullProcess() {
      try {
        this.spinning = true;
        const params = {
          ...this.quoteParams,
          app_state: this.appState,
          type: "ach"
        };
        const result = await submitOrderForFiat(params);
        if (!result.errors) {
          if (result.code === "301004") {
            //  需要邮箱验证
            const { verify_id } = result;
            let pathName = "";
            if (this.workflow === "trade") {
              pathName = "home.trade.buy.achPull.verifyEmail";
            } else {
              pathName = "individual.achPull.verifyEmail";
            }
            this.$router.push({
              name: pathName,
              params: { verify_id }
            });
          } else if (result.code === "301003") {
            // 需要手机验证
          } else {
            const { tradeId } = result;
            let pathName = "";

            if (this.workflow === "trade") {
              pathName = "home.trade.buy.achPull.orderSummary";
            } else {
              pathName = "individual.achPull.orderSummary";
            }

            this.$router.push({
              name: pathName,
              params: { trade_id: tradeId }
            });
          }
        } else {
          displayErrors(result);
        }
      } catch (err) {
        const errorData = err?.response?.data;
        this.handleAchOrderErrors(errorData);
      } finally {
        this.spinning = false;
      }
    },
    async handleAchBalanceProcess() {
      try {
        this.spinning = true;
        const params = {
          ...this.quoteParams,
          app_state: this.appState,
          type: "ach_balance"
        };
        const result = await submitOrderForFiat(params);
        if (!result.errors) {
          if (result.code === "301004") {
            //  需要邮箱验证
            const { verify_id } = result;
            this.$router.push({
              name: "home.trade.buy.achPull.verifyEmail",
              params: { verify_id }
            });
          } else if (result.code === "301003") {
            // 需要手机验证
          } else {
            const { tradeId } = result;

            this.$router.push({
              name: "home.trade.buy.achPull.orderSummary",
              params: { trade_id: tradeId }
            });
          }
        } else {
          displayErrors(result);
        }
      } catch (err) {
        const errorData = err?.response?.data;
        this.handleAchOrderErrors(errorData);
      } finally {
        this.spinning = false;
      }
    },
    async onOrderConfirm(cvv) {
      if (this.isCardPayment) {
        // get sardine session
        emitter.emit("legend-sardine:load", "payment");
        // load checkout risk script
        emitter.emit("legend-checkout:load-risk");
      }

      const defaultPaymentItemType = this.defaultPaymentItemActived[
        this.defaultPaymentTabsActived
      ].type;
      const isAchBalance = defaultPaymentItemType === "ach_balance";

      if (["credit_card", "balance"].includes(defaultPaymentItemType)) {
        this.handleBalanceAndCardProcess(cvv);
      } else if (
        this.defaultPaymentTabsActived === "bank_transfer" &&
        this.asset === "USD"
      ) {
        this.handleAchPullProcess();
      } else if (isAchBalance) {
        this.handleAchBalanceProcess();
      } else {
        // pagsmile
        this.handleThirdPartyProcess();
      }
    },
    onHandleCancel() {
      this.$router.push({ name: "home.trade" });
    },
    setDefaultActivedPaymentMethodInfo(groupType, paymentInfo) {
      let defaultPaymentItemActived = { ...this.defaultPaymentItemActived };
      defaultPaymentItemActived[groupType].payment_method_id =
        paymentInfo?.payment_method_id ?? "";
      defaultPaymentItemActived[groupType].type = paymentInfo?.type ?? "";

      this.updateDefaultPaymentItemActived({ ...defaultPaymentItemActived });
    },
    generatePaymentInfo(arrayList, groupType, paymentType) {
      let paymentList = arrayList
        .filter(item => paymentType.includes(item.type))
        .map(item => {
          const checked =
            this.defaultPaymentItemActived[groupType].payment_method_id ===
            item?.payment_method_id;
          return { ...item, checked: checked };
        });

      const findIndex = paymentList.findIndex(item => item.checked);
      if (findIndex < 0) {
        // 没有默认值就使用第一个为默认选中
        paymentList = paymentList.map((item, index) => {
          return {
            ...item,
            checked: index === 0 && !item.is_expired
          };
        });
      }

      return paymentList;
    },
    generateBalanceInfo(arrayList) {
      const groupType = "balance";
      let paymentType = ["balance"];
      if (this.allowAchPull) {
        paymentType = ["balance", "ach_balance"];
      }

      let balanceTypeList = this.generatePaymentInfo(
        arrayList,
        groupType,
        paymentType
      );

      // 是balance且余额不足，并且也不支持achPull,默认都不选中
      if (!this.isEnoughBalance && !this.allowAchPull) {
        balanceTypeList = balanceTypeList.map(item => {
          return { ...item, checked: false };
        });
      }

      const balanceInfo = balanceTypeList.find(item => item.checked);
      this.setDefaultActivedPaymentMethodInfo(groupType, balanceInfo);

      if (balanceTypeList.length) {
        const moreOrLess =
          this.defaultPaymentItemActived[groupType]?.more ?? false;

        return {
          title: "Fiat Balance",
          description: "Instant, lowest fee",
          list: balanceTypeList,
          groupType: "balance",
          showMoreOrLess: balanceTypeList.length > 1,
          txtMoreOrLess: moreOrLess ? "Show Less" : "Show All"
        };
      } else {
        return null;
      }
    },
    generateBankTransferInfo(arrayList) {
      const groupType = "bank_transfer";
      let paymentType = ["bank_transfer", "cash_payment"];
      if (this.allowAchPull) {
        paymentType = ["bank_transfer", "cash_payment", "ach"];
      }

      const bankTransferTypeList = this.generatePaymentInfo(
        arrayList,
        groupType,
        paymentType
      );

      const banTransferInfo = bankTransferTypeList.find(item => item.checked);
      this.setDefaultActivedPaymentMethodInfo(groupType, banTransferInfo);

      if (this.allowPagsmile || this.allowAchPull) {
        this.updateSelectedBankTransferInfo(banTransferInfo ?? {});

        const moreOrLess =
          this.defaultPaymentItemActived[groupType]?.more ?? false;

        return {
          title: "Bank Transfer",
          description: "Instant, lowest fee",
          list: bankTransferTypeList,
          groupType: groupType,
          showMoreOrLess: bankTransferTypeList.length > 1,
          txtMoreOrLess: moreOrLess ? "Show Less" : "Show All"
        };
      } else {
        return null;
      }
    },
    generateCreditCardInfo(arrayList) {
      const groupType = "credit_card";
      const paymentType = ["credit_card"];

      let creditCardTypeList = this.generatePaymentInfo(
        arrayList,
        groupType,
        paymentType
      );

      // 在信用卡的tab中跳转到添加信用卡的页面。balance tab的add money 不用管
      if (this.paymentFrom === "home.trade.buy") {
        // 说明是新添加的信用卡，则默认选中第一个
        creditCardTypeList = creditCardTypeList.map((item, index) => {
          return { ...item, checked: index === 0 ? true : false };
        });
      }

      const creditCardInfo = creditCardTypeList.find(item => item.checked);
      this.setDefaultActivedPaymentMethodInfo(groupType, creditCardInfo);

      //有信用卡的list或者支持信用卡就展示，信用卡的tab卡片
      if (creditCardTypeList.length || this.allowCreditcard) {
        const moreOrLess =
          this.defaultPaymentItemActived[groupType]?.more ?? false;

        return {
          title: "Credit Cards",
          description: "Fast, takes a few mins",
          list: creditCardTypeList,
          groupType: "credit_card",
          showMoreOrLess: creditCardTypeList.length > 1,
          txtMoreOrLess: moreOrLess ? "Show Less" : "Show All"
        };
      } else {
        return null;
      }
    },
    generateGroupPaymentList(arrayList) {
      const dataArray = [];

      const balanceInfo = this.generateBalanceInfo(arrayList);
      !!balanceInfo && dataArray.push(balanceInfo);

      const bankTransferInfo = this.generateBankTransferInfo(arrayList);
      !!bankTransferInfo && dataArray.push(bankTransferInfo);

      const creditCardInfo = this.generateCreditCardInfo(arrayList);
      !!creditCardInfo && dataArray.push(creditCardInfo);

      return dataArray;
    },
    handleDefaultPaymentSelected() {
      const { defaultPaymentItemActived } = this.$route.params;
      if (this.allowPagsmile && !this.paymentFrom) {
        this.updateDefaultPaymentTabsActived("bank_transfer");
      } else if (this.allowAchPull) {
        // ACH PULL
        if (defaultPaymentItemActived) {
          this.updateDefaultPaymentTabsActived(defaultPaymentItemActived);
        } else {
          this.updateDefaultPaymentTabsActived("bank_transfer");
        }
      } else if (this.allowCreditcard) {
        this.updateDefaultPaymentTabsActived("credit_card");
      } else {
        this.updateDefaultPaymentTabsActived("balance");
      }
    },
    async queryPaymentMethods() {
      try {
        this.isLoading = true;
        const pair = `${this.tradePair}${this.asset}`;
        const side = this.tradeSide.toLowerCase();
        const result = await queryTradePaymentMethods({
          pair,
          side
        });
        if (!result.errors) {
          const arrayList = result;
          this.handleDefaultPaymentSelected();
          const dataArray = this.generateGroupPaymentList(arrayList);
          this.groupPaymentList = dataArray;
          this.startQuote();
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchData() {
      await this.queryPaymentMethods();

      // get sardine session
      // emitter.emit("legend-sardine:load", "quote");
    },
    onCancel() {
      this.isBaseModalVisible = false;
      if (this.modalView !== "EnterCvv") {
        if (this.workflow === "trade") {
          this.$router.push({ name: "home.trade" });
        }
      }
    },
    onIframeCancel() {
      this.spinning = false;
    },
    async handleWithdarw() {
      try {
        this.spinning = true;
        const quantity = this.orderConfirmInfo.quantity;
        const params = {
          amount_pay_to: quantity,
          asset_pay_to: this.tradePair,
          source_id: this.quoteParams.payment_method_id
        };
        const result = await confirmWithdraw(params);
        if (!result.errors) {
          this.updateModalView("WithdrawSuccess");
          this.reloadBalance();
          this.reloadCurrency();
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      } finally {
        this.spinning = false;
      }
    },
    async onOk(data) {
      if (this.modalView === "EnterCvv") {
        await this.debouncedHandleConfirm(data);
        window.setTimeout(() => {
          // keep the modal visible until next rendering
          // to prevent the clicking of confirm button triggered by press enter key
          this.isBaseModalVisible = false;
        }, 0);
        return;
      }

      if (
        [
          "OopsDismiss",
          "OopsFailed",
          "EddError",
          "RiskError",
          "RfiError"
        ].includes(this.modalView)
      ) {
        this.isBaseModalVisible = false;
        this.msgRedirect = "";
        return;
      }

      if (this.modalView === "WithdrawSuccess") {
        this.isBaseModalVisible = false;
        this.$router.push({ name: "home.trade" });
        return;
      }

      if (this.modalView === "OopsPending") {
        this.isBaseModalVisible = false;
        this.$router.push({ name: "home.trade" });
        return;
      }

      if (["AchOrderError"].includes(this.modalView)) {
        this.isBaseModalVisible = false;
        return;
      }

      if (this.autoWithdrawal) {
        this.isBaseModalVisible = false;
        this.$router.push({ name: "home.trade" });
      } else {
        await this.handleWithdarw();
      }
    },
    toReLink() {
      const paymentMethodId = this.getPaymentMethodId();
      let processType = "";
      if (this.workflow === "trade") {
        processType = "inTrade";
      } else {
        processType = "tradeInKyc";
      }

      this.$router.push({
        name: "plaid",
        params: {
          processType: processType,
          paymentMethodId: paymentMethodId
        }
      });
    },
    toTradeHistory() {
      this.isBaseModalVisible = false;
      this.$router.push("/history");
    },
    toUserAgreement() {
      window.open("https://legendtrading.com/terms", "_blank");
    },
    toDisclosures() {
      window.open("https://bakkt.com/disclosures", "_blank");
    },
    activePayment(paymentType) {
      return this.groupPaymentList.find(item => item.groupType === paymentType);
    },
    getPaymentMethodId() {
      const paymentInfo = this.activePayment(this.defaultPaymentTabsActived);
      const defaultPayment = paymentInfo?.list.find(item => item.checked);
      const paymentMethodId = defaultPayment?.payment_method_id ?? "";
      return paymentMethodId;
    }
  },
  activated() {
    this.fetchData();
  },
  deactivated() {
    this.updateApproxInfo({});
    this.updateQuoteLoadTimer(false);
    this.updateQuoteErrorMessage("");
    this.updateQuoteParams({
      pair: "",
      side: "",
      size: "",
      payment_method_id: ""
    });
    this.checked = false;
    this.isIframeVisible = false;
    this.isBaseModalVisible = false;
    this.groupPaymentList = [];
  }
};
</script>

<style module lang="scss">
@import "~rfs/scss";

.checkBox {
  @include rfs(0.75rem);

  display: flex;
  justify-content: center;
  color: var(--grey-2);
  span {
    text-decoration: underline;
    color: var(--primary);
  }
}

.errors {
  @include rfs(0 auto 0.625rem auto, margin);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 0px;
  color: var(--danger);
  width: 70%;

  & svg {
    @include rfs(1rem, width);
    @include rfs(1rem, min-width);
    @include rfs(0.5rem, margin-right);
  }
}

.baseButton {
  @include rfs(0 auto 0.9375rem auto, margin);
}

.cancelButton {
  @include rfs(0.875rem);

  font-weight: bold;
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
  width: max-content;
  margin: auto;
  &:hover {
    color: var(--primary-3);
  }

  &:active {
    color: var(--dark);
  }
}

.userAgreement {
  @include rfs(0 auto 2.5rem auto, margin);

  max-width: 32.5rem;
  span {
    cursor: pointer;
    text-decoration: underline;
    color: var(--primary);
  }
}
.achPullTips {
  @include rfs(11px);

  color: var(--grey-2);
  text-align: left;
}

.iconBakkt {
  @include rfs(30px, margin-top);
}
</style>
