<template>
  <section>
    <PaymentList workflow="trade" />
  </section>
</template>

<script>
import PaymentList from "@/components/localPaymentProcess/PaymentList.vue";

export default {
  name: "Trade.Payment.List.Process",
  props: {},
  data() {
    return {};
  },
  computed: {},
  components: {
    PaymentList
  },
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style module lang="scss"></style>
