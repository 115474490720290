<template>
  <section :class="$style.help">
    <icon-help />
    <h6>
      {{ $t("Need help") }}?
      <span @click.prevent="toggleZendesk(!open)">{{ $t("Contact us") }}</span>
    </h6>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import IconHelp from "@/assets/img/common/icon-help.svg?inline";

export default {
  name: "NeedHelp",
  computed: mapState({
    open: state => state.isZendeskOpened
  }),
  components: {
    IconHelp
  },
  methods: {
    ...mapActions(["toggleZendesk"])
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.help {
  display: flex;
  align-items: center;

  svg {
    @include rfs(1.125rem, max-width);
  }

  h6 {
    @include rfs(0.875rem);
    @include rfs(0rem, margin-bottom);
    @include rfs(1rem, margin-left);

    color: currentColor;

    span {
      font-weight: 600;
      color: var(--primary);
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: var(--primary-3);
      }

      &:active {
        color: var(--legend-c-text);
      }
    }
  }
}
</style>
