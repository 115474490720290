<template>
  <section :class="$style.wrapper">
    <h2>
      {{ $t("Order Successful!") }}
    </h2>
    <icon-success :class="$style.iconSuccess" />
    <div :class="$style.desc">
      {{
        $t(
          "You've initiated a deposit from your bank account and successfully purchased crypto. However, it will take 5-7 days for your funds to reach our bank account. Once we receive the funds, we'll promptly release the crypto from your Legend Trading wallet to your exchange wallet."
        )
      }}
    </div>
    <BaseButton @click="handleClick" :class="$style.baseButton">
      {{ $t("START ANOTHER ORDER") }}
    </BaseButton>

    <label @click="toWallet">
      {{ $t("Check your Legend Trading Wallet") }}
    </label>
  </section>
</template>

<script>
import { Icon } from "ant-design-vue";
import { currencyLoadMixin } from "@/mixin/currencyLoadMixin";
import BaseButton from "@/components/BaseButton.vue";
import IconSuccess from "@/assets/img/common/icon-success.svg?inline";

export default {
  name: "Modal.PurchasedProcessed4AchPull",
  mixins: [currencyLoadMixin],
  components: {
    [Icon.name]: Icon,
    BaseButton,
    IconSuccess
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.reloadBalance();
      this.$emit("ok");
    },
    toWallet() {
      this.$emit("navigate");
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 3.3125rem 3.125rem 3.3125rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);

  h2 {
    @include rfs(2rem);
    @include rfs(1.75rem, margin-bottom);

    color: var(--dark);
    text-align: center;
  }

  .desc {
    @include rfs(1rem);
    @include rfs(1.75rem, margin-bottom);
    @include rfs(1.25rem, margin-top);

    text-align: center;
    color: var(--grey-cool);
  }

  .iconSuccess {
    @include rfs(4.375rem, max-width);
  }

  .baseButton {
    @include rfs(1.875rem, margin-bottom);
  }

  label {
    @include rfs(0.875rem);
    @include rfs(0rem, margin-bottom);

    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary);

    &:hover {
      color: var(--primary-3);
    }

    &:active {
      color: var(--dark);
    }
  }
}
</style>
