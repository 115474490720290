var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{class:[
    _vm.$style.main,
    _vm.$style[this.bgColor],
    _vm.isFaqView ? _vm.$style.mainBgColor : null
  ],style:(_vm.inlineMainStyle)},[_c('banner-top'),(_vm.showMenu)?_c('header-menu'):_vm._e(),_c('section',{class:( _obj = {}, _obj[_vm.$style.container] = true, _obj[_vm.$style.containerBgColor] = !_vm.isFaqView && _vm.isBgExtend, _obj )},[_c('div',{class:_vm.$style.content},[(_vm.isLoading)?_c('half-circle-spinner',{class:_vm.$style.spinner,attrs:{"color":_vm.color}}):_c('main-content')],1)]),(_vm.showSupportPaymentList)?_c('support-payment-list'):_vm._e(),(_vm.isDemo || _vm.isDev)?_c('debug-panel',{on:{"changeStatus":_vm.changeStatus}}):_vm._e(),_c('v-page-guide',{model:{value:(_vm.showGuide),callback:function ($$v) {_vm.showGuide=$$v},expression:"showGuide"}}),((_vm.isDemo || _vm.isDev) && _vm.token)?_c('vac',{ref:"countdown",class:_vm.$style.countdown,attrs:{"autoStart":false,"left-time":_vm.expiresIn * 1000},on:{"finish":_vm.onCountdownFinish},scopedSlots:_vm._u([{key:"process",fn:function(ref){
    var timeObj = ref.timeObj;
return _c('span',{},[_vm._v(" "+_vm._s(timeObj.ceil.s)+" ")])}}],null,false,3289680662)},[_c('span',{attrs:{"slot":"prev"},slot:"prev"},[_vm._v("session expires in: ")]),_c('span',{attrs:{"slot":"finish"},slot:"finish"},[_vm._v("Expired!")])]):_vm._e(),_c('a-modal',{attrs:{"cancelText":_vm.$t('Cancel'),"okText":_vm.$t('Okay, keep me connected.'),"getContainer":function () { return _vm.$root.$el; },"closable":false,"cancelButtonProps":{ props: { size: _vm.uiSize } }},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.isModalVisible),callback:function ($$v) {_vm.isModalVisible=$$v},expression:"isModalVisible"}},[_c('h5',{class:_vm.$style.title,attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{class:_vm.$style.alarm,attrs:{"component":_vm.AlarmClock}}),_vm._v(" "+_vm._s(_vm.$t("Session Time-out"))+" ")],1),_c('p',[_vm._v(_vm._s(_vm.$t("session_messages[0]")))])]),_c('modal-base',{attrs:{"visible":_vm.baseModalVisible},on:{"update:visible":function($event){_vm.baseModalVisible=$event},"cancel":_vm.onCancel,"ok":_vm.onOk}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }