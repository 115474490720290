// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BaseCheckBox_checkBoxWrapper_3AyU6{display:-webkit-box;display:-ms-flexbox;display:flex;cursor:pointer}.BaseCheckBox_checkBoxWrapper_3AyU6 .BaseCheckBox_chkIcon_88YFR{margin-top:.125rem;min-width:calc(1.25625rem + .075vw);width:calc(1.25625rem + .075vw);height:calc(1.25625rem + .075vw);border-radius:.125rem;background:var(--white);border:solid .0625rem var(--silver)}@media(min-width:1200px){.BaseCheckBox_checkBoxWrapper_3AyU6 .BaseCheckBox_chkIcon_88YFR{min-width:1.3125rem;width:1.3125rem;height:1.3125rem}}.BaseCheckBox_checkBoxWrapper_3AyU6 .BaseCheckBox_chkIcon_88YFR svg{width:100%;height:100%}.BaseCheckBox_checkBoxWrapper_3AyU6 .BaseCheckBox_chkTitle_1mrC6{margin-left:.875rem;color:currentColor;text-align:left}", ""]);
// Exports
exports.locals = {
	"checkBoxWrapper": "BaseCheckBox_checkBoxWrapper_3AyU6",
	"chkIcon": "BaseCheckBox_chkIcon_88YFR",
	"chkTitle": "BaseCheckBox_chkTitle_1mrC6"
};
module.exports = exports;
