import Vue from "vue";
import { base } from "ant-design-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import dayjs from "dayjs";

Vue.use(base);

var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
dayjs.extend(utc);

Promise.all([
  import(/* webpackChunkName: "vueAwesomeCountdown" */ "vue-awesome-countdown")
]).then(([vueAwesomeCountdown]) => {
  Vue.use(vueAwesomeCountdown.default, "vac");
});

Vue.component("Cryptoicon", () => ({
  component: new Promise(reslove => {
    Promise.all([
      import(/* webpackChunkName: "cryptoicon" */ "vue-cryptoicon"),
      import(/* webpackChunkName: "cryptoicon" */ "vue-cryptoicon/src/icons")
    ]).then(([{ Cryptoicon }, icon]) => {
      Cryptoicon.add(icon.default);
      Cryptoicon.props.size.default = "40";
      reslove(Cryptoicon);
    });
  })
}));

Vue.component("HalfCircleSpinner", () => ({
  component: new Promise(reslove => {
    Promise.all([
      import(/* webpackChunkName: "HalfCircleSpinner" */ "epic-spinners")
    ]).then(([{ HalfCircleSpinner }]) => {
      reslove(HalfCircleSpinner);
    });
  })
}));

extend("required", required);
extend("positive", value => {
  const float = Number.parseFloat(value);
  return !Number.isNaN(float) && float > 0;
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

Vue.directive("page-guide", {
  bind(el, binding) {
    el.setAttribute("v-page-guide", binding.value);

    const modifiers = Object.getOwnPropertyNames(binding.modifiers);
    if (modifiers.length > 0) {
      el.setAttribute("v-page-guide-placement", modifiers[0]);
    }
  }
});

Vue.filter("formatBalance", (number, fixed = 2) =>
  Number.parseFloat(number).toFixed(fixed)
);
Vue.filter("formatDate", datetime => {
  return dayjs
    .utc(datetime)
    .local()
    .format("YYYY-MM-DD");
});

Vue.filter("formatTime", datetime => {
  return dayjs
    .utc(datetime)
    .local()
    .format("HH:mm:ss");
});

Vue.filter("formatDateTime", datetime => {
  if (!datetime) return "";
  return dayjs
    .utc(datetime)
    .local()
    .format("YYYY-MM-DD HH:mm");
});

Vue.filter("formatMail", str => {
  if (!str) return "";
  const ref = /(.{6}).+(.{2}@.+)/g;
  const email = str.replace(ref, "$1****$2");
  return email;
});

Vue.filter("uppercase", str => {
  if (!str) return "";
  return str.toUpperCase();
});
