<template>
  <section :class="$style.account">
    <banner-left />
    <account-status
      v-if="showAccountStatus && disabledCreditcard"
      ref="account"
    />
    <fiat-balance-card v-if="showFiatBalanceCard" />
    <crypto-portfolio-card v-if="disabledCreditcard" />
    <recent-orders-card />
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BannerLeft from "@/components/BannerLeft.vue";
import AccountStatus from "@/components/AccountStatus.vue";
import FiatBalanceCard from "@/components/FiatBalanceCard.vue";
import CryptoPortfolioCard from "@/components/CryptoPortfolioCard.vue";
import RecentOrdersCard from "@/components/RecentOrdersCard.vue";

export default {
  name: "AccountInfo",
  components: {
    BannerLeft,
    AccountStatus,
    FiatBalanceCard,
    CryptoPortfolioCard,
    RecentOrdersCard
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      kycStatus: state => state.kycStatus,
      nextKycStep: state => state.nextKycStep,
      accountType: state => state.accountType
    }),
    ...mapGetters({
      isEnabled: "isAccountEnabled",
      balance: "balance",
      isVerifyFinished: "isVerifyFinished",
      disabledCreditcard: "disabledCreditcard"
    }),

    showAccountStatus() {
      const { nextKycStep } = this;
      return ["verify_id", "enter_bank", "reviewing_id"].includes(nextKycStep);
    },
    showIncreaseLimit() {
      const { nextKycStep } = this;
      return (
        ["questionnaire", "rejected_questionnaire"].includes(nextKycStep) &&
        this.accountType === "individual"
      );
    },
    showFiatBalanceCard() {
      //仅支持信用卡  this.disabledCreditcard = false
      //fiat 为空对象
      //则 隐藏 其他都展示
      return !(
        this.disabledCreditcard === false &&
        JSON.stringify(this.balance.fiat) === "{}"
      );
    }
  },
  methods: {},
  activated() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.account {
  flex: 1;
}
</style>
