// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DebugPanel_nav_2wgOK{bottom:0;position:fixed;right:0;z-index:1000}.DebugPanel_nav_2wgOK nav{-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column nowrap;flex-flow:column nowrap;margin:-16px;max-height:60vh;overflow-y:auto;padding:8px}.DebugPanel_nav_2wgOK a[href]{color:var(--ayanami)}.DebugPanel_nav_2wgOK a[href].router-link-active,.DebugPanel_nav_2wgOK a[href].router-link-exact-active{color:var(--red)}.DebugPanel_debug_252cp{top:calc(1.425rem + 2.1vw);-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column nowrap;flex-flow:column nowrap;position:fixed;right:0;z-index:1000}@media(min-width:1200px){.DebugPanel_debug_252cp{top:3rem}}.DebugPanel_debug_252cp .DebugPanel_swatches_1CN1R,.DebugPanel_debug_252cp .ant-radio-group,.DebugPanel_debug_252cp .ant-switch{margin-top:.25rem}.DebugPanel_debug_252cp .DebugPanel_swatches_1CN1R{display:-webkit-box;display:-ms-flexbox;display:flex}.DebugPanel_debug_252cp .DebugPanel_swatches_1CN1R .vue-swatches__wrapper{-webkit-box-sizing:content-box;box-sizing:content-box}.DebugPanel_debug_252cp .DebugPanel_swatches_1CN1R .vue-swatches__fallback__wrapper{-webkit-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;display:-webkit-box;display:-ms-flexbox;display:flex}.DebugPanel_debug_252cp .DebugPanel_swatches_1CN1R .DebugPanel_swatchesInput_3Us98{height:100%;padding:0}.DebugPanel_debug_252cp .DebugPanel_swatches_1CN1R .DebugPanel_swatchesOk_uR6Q0{background-color:var(--legend)}.DebugPanel_debug_252cp .DebugPanel_btnChange_3Vryh{border-radius:var(--border-radius);margin-top:.5rem;height:auto;padding:.2rem}", ""]);
// Exports
exports.locals = {
	"nav": "DebugPanel_nav_2wgOK",
	"debug": "DebugPanel_debug_252cp",
	"swatches": "DebugPanel_swatches_1CN1R",
	"swatchesInput": "DebugPanel_swatchesInput_3Us98",
	"swatchesOk": "DebugPanel_swatchesOk_uR6Q0",
	"btnChange": "DebugPanel_btnChange_3Vryh"
};
module.exports = exports;
