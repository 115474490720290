import HomeTradeBuy from "../../views/trade/PaymentList.vue";

const routes = [
  {
    path: "",
    name: "home.trade.buy",
    component: HomeTradeBuy
  },
  {
    path: "payment/bankinfo",
    name: "home.trade.buy.payment.bankinfo",
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../views/payment/thirdParty/EnterBankInfo.vue"
      )
  },
  {
    path: "payment/result",
    name: "home.trade.buy.payment.result",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/trade/PaymentResult.vue"
      )
  },
  {
    path: "/achPull/orderSummary",
    name: "home.trade.buy.achPull.orderSummary",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/trade/achPull/OrderSummary.vue"
      ),
    meta: {
      type: "achPull_in_trade",
      showBackBtn: false
    }
  }
];

export default routes;
