const routes = [
  {
    path: "",
    name: "payment.card",
    meta: {
      type: "credit_card_in_trade",
      showBackBtn: false
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../views/payment/creditCard/BillingAddress.vue"
      )
  },
  {
    path: "/payment/billingAddress",
    name: "payment.billingAddress",
    meta: {
      type: "credit_card_in_trade",
      showBackBtn: false
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../views/payment/creditCard/BillingAddress.vue"
      )
  },
  {
    path: "/payment/creditCard/details",
    name: "payment.creditCard.details",
    meta: {
      type: "credit_card_in_trade",
      showBackBtn: false
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../views/payment/creditCard/CreditCardDetails.vue"
      )
  },
  {
    path: "/payment/creditCard/failed",
    name: "payment.creditCard.failed",
    meta: {
      type: "credit_card_in_trade",
      showBackBtn: false
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../views/payment/creditCard/CreditCardFailed.vue"
      )
  },
  {
    path: "/payment/creditCard/successed",
    name: "payment.creditCard.successed",
    meta: {
      type: "credit_card_in_trade",
      showBackBtn: false
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../views/payment/creditCard/CreditCardSuccessed.vue"
      )
  }
];

export default routes;
