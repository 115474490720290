// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TradeRfq_section_2g_ib label{font-size:calc(1.28125rem + .375vw);margin-bottom:.4375rem;margin-left:calc(1.375rem + 1.5vw);display:block;font-weight:600;color:var(--legend-c-text);line-height:2.1875rem}@media(min-width:1200px){.TradeRfq_section_2g_ib label{font-size:1.5625rem;margin-left:2.5rem}}.TradeRfq_section_2g_ib .TradeRfq_cardRfq_1yxuY{min-width:calc(5.875rem + 55.5vw);padding:calc(1.375rem + 1.5vw) calc(1.6875rem + 5.25vw) calc(1.5125rem + 3.15vw) calc(1.6875rem + 5.25vw);border-radius:.625rem;-webkit-box-shadow:0 .375rem 1.125rem 0 var(--dark-70-5);box-shadow:0 .375rem 1.125rem 0 var(--dark-70-5);background:var(--white);text-align:center}@media(min-width:1200px){.TradeRfq_section_2g_ib .TradeRfq_cardRfq_1yxuY{min-width:47.5rem;padding:2.5rem 5.625rem 3.875rem 5.625rem}}.TradeRfq_section_2g_ib .TradeRfq_cardRfq_1yxuY>h2:first-child{font-size:calc(1.275rem + .3vw);margin-bottom:1rem;line-height:2.0625rem;font-weight:700}@media(min-width:1200px){.TradeRfq_section_2g_ib .TradeRfq_cardRfq_1yxuY>h2:first-child{font-size:1.5rem}}.TradeRfq_section_2g_ib .TradeRfq_cardRfq_1yxuY .TradeRfq_link_HmQz7{margin:.5rem 0 -.5rem;text-align:right}", ""]);
// Exports
exports.locals = {
	"section": "TradeRfq_section_2g_ib",
	"cardRfq": "TradeRfq_cardRfq_1yxuY",
	"link": "TradeRfq_link_HmQz7"
};
module.exports = exports;
