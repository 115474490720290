// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BuyConfirm_tradeBuyConfirm_13xSn .content{min-width:calc(5.875rem + 55.5vw);padding:calc(1.375rem + 1.5vw) calc(1.6875rem + 5.25vw) calc(1.5125rem + 3.15vw) calc(1.6875rem + 5.25vw);border-radius:.625rem;-webkit-box-shadow:0 .375rem 1.125rem 0 var(--dark-70-5);box-shadow:0 .375rem 1.125rem 0 var(--dark-70-5);background:var(--white);text-align:center}@media(min-width:1200px){.BuyConfirm_tradeBuyConfirm_13xSn .content{min-width:47.5rem;padding:2.5rem 5.625rem 3.875rem 5.625rem}}.BuyConfirm_tradeBuyConfirm_13xSn h3{margin-bottom:0;color:var(--dark)}.BuyConfirm_tradeBuyConfirm_13xSn p{font-size:.875rem;margin-bottom:1.25rem;color:var(--grey-cool)}", ""]);
// Exports
exports.locals = {
	"tradeBuyConfirm": "BuyConfirm_tradeBuyConfirm_13xSn"
};
module.exports = exports;
