// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SupportPaymentList_partnerList_9ksQz{padding:1rem;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:var(--white);z-index:1;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%}.SupportPaymentList_partnerList_9ksQz svg{width:calc(1.4625rem + 2.55vw);height:calc(1.375rem + 1.5vw)}@media(min-width:1200px){.SupportPaymentList_partnerList_9ksQz svg{width:3.375rem;height:2.5rem}}.SupportPaymentList_partnerList_9ksQz svg:not(:last-child){margin-right:calc(1.28125rem + .375vw)}@media(min-width:1200px){.SupportPaymentList_partnerList_9ksQz svg:not(:last-child){margin-right:1.5625rem}}", ""]);
// Exports
exports.locals = {
	"partnerList": "SupportPaymentList_partnerList_9ksQz"
};
module.exports = exports;
