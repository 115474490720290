// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Home_home_16VbE{padding:.3125rem calc(2.125rem + 10.5vw) calc(2.2875rem + 12.45vw) calc(2.125rem + 10.5vw);padding-top:.3125rem;display:-webkit-box;display:-ms-flexbox;display:flex;z-index:1;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}@media(min-width:1200px){.Home_home_16VbE{padding:.3125rem 10rem 11.625rem 10rem}}.Home_home_16VbE .Home_homeContent_2WFYk{max-width:calc(5.875rem + 55.5vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}@media(min-width:1200px){.Home_home_16VbE .Home_homeContent_2WFYk{max-width:47.5rem}}.Home_home_16VbE .Home_homeContent_2WFYk .Home_btmContent_2u5ld{margin-top:1.25rem;display:-webkit-box;display:-ms-flexbox;display:flex}.Home_home_16VbE .Home_homeContent_2WFYk .Home_btmContent_2u5ld .Home_needHelp_15pMo{color:var(--grey-cool);-webkit-box-flex:1;-ms-flex:1;flex:1}.Home_home_16VbE .Home_homeContent_2WFYk .Home_btmContent_2u5ld .Home_poweredBy_3ONU2{-webkit-box-flex:2;-ms-flex:2;flex:2}", ""]);
// Exports
exports.locals = {
	"home": "Home_home_16VbE",
	"homeContent": "Home_homeContent_2WFYk",
	"btmContent": "Home_btmContent_2u5ld",
	"needHelp": "Home_needHelp_15pMo",
	"poweredBy": "Home_poweredBy_3ONU2"
};
module.exports = exports;
