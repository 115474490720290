// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PurchasedProcessed4AchPull_wrapper_18vWS{padding:calc(1.375rem + 1.5vw) calc(1.45625rem + 2.475vw) calc(1.4375rem + 2.25vw) calc(1.45625rem + 2.475vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--white)}@media(min-width:1200px){.PurchasedProcessed4AchPull_wrapper_18vWS{padding:2.5rem 3.3125rem 3.125rem 3.3125rem}}.PurchasedProcessed4AchPull_wrapper_18vWS h2{font-size:calc(1.325rem + .9vw);margin-bottom:calc(1.3rem + .6vw);color:var(--dark);text-align:center}@media(min-width:1200px){.PurchasedProcessed4AchPull_wrapper_18vWS h2{font-size:2rem;margin-bottom:1.75rem}}.PurchasedProcessed4AchPull_wrapper_18vWS .PurchasedProcessed4AchPull_desc_1UiR-{font-size:1rem;margin-bottom:calc(1.3rem + .6vw);margin-top:1.25rem;text-align:center;color:var(--grey-cool)}@media(min-width:1200px){.PurchasedProcessed4AchPull_wrapper_18vWS .PurchasedProcessed4AchPull_desc_1UiR-{margin-bottom:1.75rem}}.PurchasedProcessed4AchPull_wrapper_18vWS .PurchasedProcessed4AchPull_iconSuccess_1gJC0{max-width:calc(1.5625rem + 3.75vw)}@media(min-width:1200px){.PurchasedProcessed4AchPull_wrapper_18vWS .PurchasedProcessed4AchPull_iconSuccess_1gJC0{max-width:4.375rem}}.PurchasedProcessed4AchPull_wrapper_18vWS .PurchasedProcessed4AchPull_baseButton_oCKqu{margin-bottom:calc(1.3125rem + .75vw)}@media(min-width:1200px){.PurchasedProcessed4AchPull_wrapper_18vWS .PurchasedProcessed4AchPull_baseButton_oCKqu{margin-bottom:1.875rem}}.PurchasedProcessed4AchPull_wrapper_18vWS label{font-size:.875rem;margin-bottom:0;font-weight:700;text-decoration:underline;cursor:pointer;color:var(--primary)}.PurchasedProcessed4AchPull_wrapper_18vWS label:hover{color:var(--primary-3)}.PurchasedProcessed4AchPull_wrapper_18vWS label:active{color:var(--dark)}", ""]);
// Exports
exports.locals = {
	"wrapper": "PurchasedProcessed4AchPull_wrapper_18vWS",
	"desc": "PurchasedProcessed4AchPull_desc_1UiR-",
	"iconSuccess": "PurchasedProcessed4AchPull_iconSuccess_1gJC0",
	"baseButton": "PurchasedProcessed4AchPull_baseButton_oCKqu"
};
module.exports = exports;
