// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EddPendingDeposit_wrapper_2KSNl{padding:calc(1.375rem + 1.5vw) calc(1.325rem + .9vw) calc(1.4375rem + 2.25vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--white);color:var(--dark)}@media(min-width:1200px){.EddPendingDeposit_wrapper_2KSNl{padding:2.5rem 2rem 3.125rem}}.EddPendingDeposit_wrapper_2KSNl .EddPendingDeposit_title_8AyMi{font-size:calc(1.275rem + .3vw);margin:0 0 calc(1.3rem + .6vw);position:relative;color:#df943e;font-weight:700;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}@media(min-width:1200px){.EddPendingDeposit_wrapper_2KSNl .EddPendingDeposit_title_8AyMi{font-size:1.5rem;margin:0 0 1.75rem}}.EddPendingDeposit_wrapper_2KSNl .EddPendingDeposit_title_8AyMi img{width:calc(1.325rem + .9vw);position:absolute;top:0;left:-3rem}@media(min-width:1200px){.EddPendingDeposit_wrapper_2KSNl .EddPendingDeposit_title_8AyMi img{width:2rem}}.EddPendingDeposit_wrapper_2KSNl p{font-size:1rem;width:100%;text-align:left}.EddPendingDeposit_wrapper_2KSNl p span{color:#4fad5b;font-weight:500}.EddPendingDeposit_wrapper_2KSNl svg{width:9.375rem;margin-bottom:calc(1.3rem + .6vw)}@media(min-width:1200px){.EddPendingDeposit_wrapper_2KSNl svg{margin-bottom:1.75rem}}", ""]);
// Exports
exports.locals = {
	"wrapper": "EddPendingDeposit_wrapper_2KSNl",
	"title": "EddPendingDeposit_title_8AyMi"
};
module.exports = exports;
