// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RejectedAccount_wrapper_2avbZ{padding:calc(1.375rem + 1.5vw) calc(1.45625rem + 2.475vw) calc(1.4375rem + 2.25vw) calc(1.45625rem + 2.475vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--white)}@media(min-width:1200px){.RejectedAccount_wrapper_2avbZ{padding:2.5rem 3.3125rem 3.125rem 3.3125rem}}.RejectedAccount_wrapper_2avbZ>h2{margin-bottom:1.25rem;color:var(--dark);text-align:center}.RejectedAccount_wrapper_2avbZ p{font-size:1.25rem;margin-bottom:calc(1.4375rem + 2.25vw);text-align:center;color:var(--grey-cool)}@media(min-width:1200px){.RejectedAccount_wrapper_2avbZ p{margin-bottom:3.125rem}}.RejectedAccount_wrapper_2avbZ p span{color:var(--primary);font-weight:600;text-decoration:underline;cursor:pointer}.RejectedAccount_wrapper_2avbZ p span:hover{color:var(--primary-3)}.RejectedAccount_wrapper_2avbZ p span:active{color:var(--dark)}.RejectedAccount_wrapper_2avbZ .RejectedAccount_iconAlert_vn_LV{max-width:calc(1.5625rem + 3.75vw);margin-bottom:1rem}@media(min-width:1200px){.RejectedAccount_wrapper_2avbZ .RejectedAccount_iconAlert_vn_LV{max-width:4.375rem}}.RejectedAccount_wrapper_2avbZ .RejectedAccount_loadingTips_3T93u{margin-top:calc(1.4125rem + 1.95vw);margin-bottom:.5rem}@media(min-width:1200px){.RejectedAccount_wrapper_2avbZ .RejectedAccount_loadingTips_3T93u{margin-top:2.875rem}}", ""]);
// Exports
exports.locals = {
	"wrapper": "RejectedAccount_wrapper_2avbZ",
	"iconAlert": "RejectedAccount_iconAlert_vn_LV",
	"loadingTips": "RejectedAccount_loadingTips_3T93u"
};
module.exports = exports;
