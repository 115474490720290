// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RefreshNeededPending_wrapper_14GQZ{padding:calc(1.375rem + 1.5vw) calc(1.325rem + .9vw) calc(1.4375rem + 2.25vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--white)}@media(min-width:1200px){.RefreshNeededPending_wrapper_14GQZ{padding:2.5rem 2rem 3.125rem}}.RefreshNeededPending_wrapper_14GQZ h2{font-size:calc(1.325rem + .9vw);margin:0 0 calc(1.425rem + 2.1vw);color:var(--dark);line-height:1.2;text-align:center}@media(min-width:1200px){.RefreshNeededPending_wrapper_14GQZ h2{font-size:2rem;margin:0 0 3rem}}.RefreshNeededPending_wrapper_14GQZ .RefreshNeededPending_waitWrapper_2lv3w{margin-bottom:calc(2.08125rem + 9.975vw)}@media(min-width:1200px){.RefreshNeededPending_wrapper_14GQZ .RefreshNeededPending_waitWrapper_2lv3w{margin-bottom:9.5625rem}}.RefreshNeededPending_wrapper_14GQZ .RefreshNeededPending_waitWrapper_2lv3w .ant-spin-dot{font-size:calc(2.125rem + 10.5vw)}@media(min-width:1200px){.RefreshNeededPending_wrapper_14GQZ .RefreshNeededPending_waitWrapper_2lv3w .ant-spin-dot{font-size:10rem}}.RefreshNeededPending_wrapper_14GQZ .RefreshNeededPending_waitWrapper_2lv3w .anticon-spin{-webkit-animation-duration:2s;animation-duration:2s}", ""]);
// Exports
exports.locals = {
	"wrapper": "RefreshNeededPending_wrapper_14GQZ",
	"waitWrapper": "RefreshNeededPending_waitWrapper_2lv3w"
};
module.exports = exports;
