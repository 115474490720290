<template>
  <component :is="getPaymentMethodsIcon" />
</template>

<script>
import IconBank from "@/assets/img/common/icon-bank-large.svg?inline";
import IconBalance from "@/assets/img/common/icon-wallet-large.svg?inline";
import IconCard from "@/assets/img/common/icon-credit-card.svg?inline";
import Visa from "@/assets/img/creditCard/visa.svg?inline";
import MasterCard from "@/assets/img/creditCard/masterCard.svg?inline";
import Amex from "@/assets/img/creditCard/amex.svg?inline";
import JCB from "@/assets/img/creditCard/jcb.svg?inline";
import Discover from "@/assets/img/creditCard/discover.svg?inline";
import PIX from "@/assets/img/bankTransfer/BRL/pix.svg?inline";
import Boleto from "@/assets/img/bankTransfer/BRL/boleto.svg?inline";
import Lottery from "@/assets/img/bankTransfer/BRL/lottery.svg?inline";
import SPEI from "@/assets/img/bankTransfer/MXN/spei.svg?inline";
import CoDi from "@/assets/img/bankTransfer/MXN/codi.svg?inline";
import Chase from "@/assets/img/payment/icon-chase.svg?inline";
import Citi from "@/assets/img/payment/icon-citi.svg?inline";
import WellsFargo from "@/assets/img/payment/icon-wellsfargo.svg?inline";
import BankofAmerica from "@/assets/img/payment/icon-bankofamerica.svg?inline";

export default {
  name: "IconCreditCard",
  data() {
    return {
      paymentIcon: {
        balance: "IconBalance",
        ach_balance: {
          chase: "Chase",
          citi: "Citi",
          wells_fargo: "WellsFargo",
          bank_of_america: "BankofAmerica"
        },
        wire: "IconBank",
        bank_transfer: {
          pix: "PIX",
          spei: "SPEI",
          codi: "CoDi"
        },
        cash_payment: {
          boleto: "Boleto",
          lottery: "Lottery"
        },
        credit_card: {
          visa: "Visa",
          mastercard: "MasterCard",
          amex: "Amex",
          jcb: "JCB",
          discover: "Discover",
          default: "IconCard"
        },
        ach: {
          chase: "Chase",
          citi: "Citi",
          wells_fargo: "WellsFargo",
          bank_of_america: "BankofAmerica"
        }
      }
    };
  },
  props: {
    type: {
      type: [String],
      default: ""
    },
    flag: {
      type: [String],
      default: ""
    }
  },
  components: {
    IconBank,
    IconBalance,
    IconCard,
    Visa,
    MasterCard,
    Amex,
    JCB,
    Discover,
    PIX,
    Boleto,
    Lottery,
    SPEI,
    CoDi,
    Chase,
    Citi,
    WellsFargo,
    BankofAmerica
  },
  computed: {
    getPaymentMethodsIcon() {
      const { type, flag = "" } = this;
      let iconName = "";
      if (type === "credit_card") {
        iconName =
          this.paymentIcon?.[type]?.[flag?.toLowerCase()] ?? "IconCard";
      } else if (["bank_transfer", "cash_payment"].includes(type)) {
        iconName =
          this.paymentIcon?.[type]?.[flag?.toLowerCase()] ?? "IconBank";
      } else if (type === "ach" || type === "ach_balance") {
        iconName =
          this.paymentIcon?.[type]?.[flag?.toLowerCase().replace(/ /g, "_")] ??
          "IconBank";
      } else {
        iconName = this.paymentIcon?.[type] ?? "IconBank";
      }
      return iconName;
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
</style>
