// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VerifyReviewing_wrapper_1gby-{padding:calc(1.375rem + 1.5vw) calc(1.45625rem + 2.475vw) calc(1.4375rem + 2.25vw) calc(1.45625rem + 2.475vw);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--white)}@media(min-width:1200px){.VerifyReviewing_wrapper_1gby-{padding:2.5rem 3.3125rem 3.125rem 3.3125rem}}.VerifyReviewing_wrapper_1gby- h2{margin-bottom:calc(1.3rem + .6vw);color:var(--dark);text-align:center}@media(min-width:1200px){.VerifyReviewing_wrapper_1gby- h2{margin-bottom:1.75rem}}.VerifyReviewing_wrapper_1gby- p{font-size:1.25rem;margin-bottom:calc(1.3rem + .6vw);text-align:center;color:var(--grey-cool)}@media(min-width:1200px){.VerifyReviewing_wrapper_1gby- p{margin-bottom:1.75rem}}", ""]);
// Exports
exports.locals = {
	"wrapper": "VerifyReviewing_wrapper_1gby-"
};
module.exports = exports;
