import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const locales = require.context(
  "./locales",
  true,
  /[A-Za-z0-9-_,\s]+\.json$/i,
  "lazy"
);

function loadLocaleMessagesAsync() {
  const messages = { en: {} };
  locales.keys().forEach(async key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = await locales(key);
    }
  });

  return messages;
}

const numberFormats = {
  USD: {
    currency: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "narrowSymbol"
    }
  },
  GBP: {
    currency: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "narrowSymbol"
    }
  },
  EUR: {
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "narrowSymbol"
    }
  },
  AUD: {
    currency: {
      style: "currency",
      currency: "AUD",
      currencyDisplay: "narrowSymbol"
    }
  },
  CAD: {
    currency: {
      style: "currency",
      currency: "CAD",
      currencyDisplay: "narrowSymbol"
    }
  },
  CHF: {
    currency: {
      style: "currency",
      currency: "CHF",
      currencyDisplay: "narrowSymbol"
    }
  },
  JPY: {
    currency: {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "narrowSymbol"
    }
  }
};

export const SUPPORT_LOCALES = locales
  .keys()
  .map(n => n.replace(/(?:.*[/\\])([A-Za-z0-0-]+)\..*$/i, "$1"));

export function sortSupportLocales() {
  // 把en放到第一位
  const defaultLang = "en";
  let supportLocales = SUPPORT_LOCALES;

  supportLocales = supportLocales.filter(item => item !== defaultLang);
  supportLocales.unshift(defaultLang);
  return supportLocales;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false,
  messages: loadLocaleMessagesAsync(),
  numberFormats
});
