<template>
  <main :class="{ [$style.main]: true, [$style.mainExtend]: needExtend }">
    <IconBg :class="$style.iconBg" v-if="!needExtend && !isFaqView" />
    <IconBgFaq :class="$style.iconBgFaq" v-if="isFaqView" />
    <transition>
      <keep-alive :exclude="['RegisterIndex']">
        <router-view :class="$style.content">
          <slot name="title" slot="title" />
        </router-view>
      </keep-alive>
    </transition>
  </main>
</template>

<script>
import IconBg from "@/assets/img/common/icon-bg.svg?inline";
import IconBgFaq from "@/assets/img/common/icon-bg-faq.svg?inline";

export default {
  name: "MainContent",
  components: {
    IconBg,
    IconBgFaq
  },
  computed: {
    needExtend() {
      return [
        "home",
        "trade.index",
        "account.userinfo",
        "trade.history",
        "transfer.history",
        "account.paymentList",
        "home.trade",
        "home.trade.entry",
        "home.trade.buy",
        "home.trade.sell",
        "home.withdraw",
        "home.withdraw.fiat.destination",
        "home.withdraw.fiat.confirm",
        "home.withdraw.crypto.destination",
        "home.withdraw.crypto.confirm",
        "account.balance",
        "refuses",
        "forbidden"
      ].includes(this.$route.name);
    },
    isFaqView() {
      return this.$route?.name?.startsWith("faq");
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="less" module>
.main {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  min-height: calc(100vh - 6.25rem);
  overflow: visible;
}
.mainExtend {
  margin-top: -8.25rem;
}
.iconBg {
  max-width: 90rem;
  position: absolute;
  margin: auto;
  top: 2rem;
  left: 0;
  right: 0;
  pointer-events: none;
  user-select: none;
}
.iconBgFaq {
  max-width: 81.625rem;
  position: absolute;
  margin: auto;
  top: -3.75rem;
}
.content {
  flex: 1;
  z-index: 1;
}
</style>
