const routes = [
  {
    path: "/achPull/ssnInfo",
    name: "home.trade.buy.achPull.ssnInfo",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/trade/achPull/SsnInfo.vue"
      ),
    meta: {
      type: "achPull_in_trade",
      title: ""
    }
  },
  {
    path: "/achPull/verifyIdentify",
    name: "home.trade.buy.achPull.verifyIdentify",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/trade/achPull/VerifyIdentify.vue"
      ),
    meta: {
      type: "achPull_in_trade",
      title: "VERIFY IDENTITY"
    }
  },
  {
    path: "/achPull/autoVerify",
    name: "home.trade.buy.achPull.autoVerify",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/trade/achPull/AutoVerify.vue"
      ),
    meta: {
      type: "achPull_in_trade",
      title: "VERIFY YOUR IDENTITY"
    }
  },
  {
    path: "/achPull/verifySuccess",
    name: "home.trade.buy.achPull.verifySuccess",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/trade/achPull/VerifySuccess.vue"
      ),
    meta: {
      type: "achPull_in_trade",
      showBackBtn: false
    }
  },
  {
    path: "/achPull/verifyFailed",
    name: "home.trade.buy.achPull.verifyFailed",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/trade/achPull/VerifyFailed.vue"
      ),
    meta: {
      type: "achPull_in_trade",
      showBackBtn: false
    }
  },
  {
    path: "/achPull/verifyEmail",
    name: "home.trade.buy.achPull.verifyEmail",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../views/trade/achPull/VerifyEmail.vue"
      ),
    meta: {
      type: "achPull_in_trade",
      showBackBtn: false
    }
  }
];

export default routes;
