const routes = [
  {
    path: "/deposit",
    name: "deposit",
    meta: { type: "achPull_deposit_in_trade" },
    component: () =>
      import(/* webpackChunkName: "deposit" */ "../views/deposit/Index.vue")
  },
  {
    path: "/wire/notice",
    name: "deposit.wire.notice",
    meta: { type: "achPull_deposit_in_trade" },
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../views/deposit/wireTransfer/WireNotice.vue"
      )
  },
  {
    path: "/wire/transfer",
    meta: { type: "achPull_deposit_in_trade" },
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../views/deposit/wireTransfer/WireTransfer.vue"
      ),
    children: [
      {
        path: "",
        name: "deposit.wire.transfer",
        meta: { type: "achPull_deposit_in_trade" },
        component: () =>
          import(
            /* webpackChunkName: "deposit" */ "../views/deposit/wireTransfer/ChooseCurrency.vue"
          )
      },
      {
        path: "/wire/confirm",
        name: "deposit.wire.confirm",
        meta: { type: "achPull_deposit_in_trade" },
        component: () =>
          import(
            /* webpackChunkName: "deposit" */ "../views/deposit/wireTransfer/ConfirmDeposit.vue"
          )
      },
      {
        path: "/wire/finished",
        name: "deposit.wire.finished",
        meta: { type: "achPull_deposit_in_trade" },
        component: () =>
          import(
            /* webpackChunkName: "deposit" */ "../views/deposit/wireTransfer/DepositFinished.vue"
          )
      }
    ]
  },
  {
    path: "/ach/notice",
    name: "deposit.ach.notice",
    meta: { type: "achPull_deposit_in_trade" },
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../views/deposit/bankTransfer/BankNotice.vue"
      )
  },
  {
    path: "/ach/transfer",
    meta: { type: "achPull_deposit_in_trade" },
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../views/deposit/bankTransfer/BankTransfer.vue"
      ),
    children: [
      {
        path: "",
        name: "deposit.ach.transfer",
        meta: { type: "achPull_deposit_in_trade" },
        component: () =>
          import(
            /* webpackChunkName: "deposit" */ "../views/deposit/bankTransfer/ConfirmDeposit.vue"
          )
      },
      {
        path: "/ach/finished",
        name: "deposit.ach.finished",
        meta: { type: "achPull_deposit_in_trade" },
        component: () =>
          import(
            /* webpackChunkName: "deposit" */ "../views/deposit/bankTransfer/DepositFinished.vue"
          )
      }
    ]
  },
  {
    path: "/deposit/achpull/enterAmount",
    name: "deposit.achpull.enterAmount",
    meta: { type: "achPull_deposit_in_trade" },
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../views/deposit/achPull/EnterAmount.vue"
      )
  },
  {
    path: "/deposit/achpull/verifyEmail",
    name: "deposit.achpull.verifyEmail",
    meta: { type: "achPull_deposit_in_trade" },
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../views/deposit/achPull/VerifyEmail.vue"
      )
  },
  {
    path: "/deposit/achpull/confirmDesposit",
    name: "deposit.achpull.confirmDesposit",
    meta: { type: "achPull_deposit_in_trade" },
    component: () =>
      import(
        /* webpackChunkName: "deposit" */ "../views/deposit/achPull/ConfirmDeposit.vue"
      )
  }
];

export default routes;
