import { mapActions } from "vuex";
import { getCurrencies, getBalance, getPairsInfo } from "@/api";
import { displayErrors, getCurrencyUnitList } from "@/utils";

export const currencyLoadMixin = {
  data() {
    return {};
  },
  computed: {},
  methods: {
    ...mapActions([
      "setCurrencies",
      "setBalance",
      "updateCurrencyUnitList",
      "setPairs"
    ]),
    async reloadCurrency() {
      try {
        const result = await getCurrencies();
        if (!result.errors) {
          const currencyUnitList = getCurrencyUnitList(result);

          this.setCurrencies(result);
          this.updateCurrencyUnitList(currencyUnitList);
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      }
    },
    async reloadBalance() {
      try {
        const result = await getBalance();
        if (!result.errors) {
          this.setBalance(result);
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      }
    },
    async reloadPairs() {
      try {
        const pairs = await getPairsInfo();
        this.setPairs(pairs);
      } catch (err) {
        displayErrors(err);
      }
    }
  }
};
