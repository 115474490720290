<template>
  <section :class="$style.wrapper">
    <h2>{{ $t("modal_messsages.remove_payment.title") }}?</h2>
    <p v-html="$t('modal_messsages.remove_payment.content')"></p>

    <section :class="$style.paymentInfo">
      <div :class="$style.paymentInfoContent">
        <div :class="$style.iconCard">
          <IconCreditCard
            :type="bankType"
            :flag="bankFlag"
            :class="{
              [$style.paymentIcon]: true,
              [$style.paymentIconAch]: bankType === 'ach'
            }"
          />
        </div>
        <div :class="$style.itemInfo">
          <div :class="$style.itemTitle">{{ bankTitle }}</div>
          <div :class="$style.itemDesc">{{ description }}</div>
        </div>
      </div>
    </section>

    <div :class="$style.btmPanel">
      <BaseButton @click="handleCancel" :class="$style.cancelButton">{{
        $t("Cancel") | uppercase
      }}</BaseButton>
      <BaseButton
        @click="handleClick"
        :loading="isLoading"
        :class="$style.baseButton"
        >{{ $t("Confirm") | uppercase }}</BaseButton
      >
    </div>
  </section>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import IconCreditCard from "@/components/IconCreditCard.vue";

export default {
  name: "Modal.RemovePayment",
  components: {
    BaseButton,
    IconCreditCard
  },
  data() {
    return {};
  },
  computed: {
    bankTitle() {
      const { paymentMethodsInfo } = this.$attrs;
      return paymentMethodsInfo?.title ?? "";
    },
    description() {
      const { paymentMethodsInfo } = this.$attrs;
      return paymentMethodsInfo?.description ?? "";
    },
    iconName() {
      const { paymentMethodsInfo } = this.$attrs;
      return paymentMethodsInfo?.iconName ?? "";
    },
    bankType() {
      const { paymentMethodsInfo } = this.$attrs;
      return paymentMethodsInfo?.type ?? "";
    },
    bankFlag() {
      const { paymentMethodsInfo } = this.$attrs;
      return paymentMethodsInfo?.flag ?? "";
    },
    isLoading() {
      const { isDelLoading } = this.$attrs;
      return isDelLoading;
    }
  },
  watch: {},
  methods: {
    handleClick() {
      this.$emit("ok");
    },
    handleCancel() {
      this.$emit("cancel");
    }
  },
  created() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.wrapper {
  @include rfs(2.5rem 0rem 3.125rem 0rem, padding);

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);

  h2 {
    @include rfs(1.75rem, margin-bottom);

    color: var(--dark);
    text-align: center;
  }

  p {
    @include rfs(1.25rem);
    @include rfs(1.75rem, margin-bottom);

    text-align: center;
    color: var(--grey-cool);
  }

  .paymentInfo {
    @include rfs(5rem, min-height);
    @include rfs(2rem, margin-bottom);
    width: 100%;
    background: rgba(206, 206, 208, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    .paymentInfoContent {
      @include rfs(22.375rem, min-width);
      @include rfs(0.9375rem 0, padding);
      display: flex;
      align-items: center;
      justify-content: center;

      .iconCard {
        width: 10%;
        display: flex;
        align-items: center;
      }

      .paymentIcon {
        @include rfs(100%, width);

        color: var(--silver);
      }

      .paymentIconAch {
        @include rfs(80%, width);
      }

      .itemInfo {
        @include rfs(0.9375rem, margin-left);
        @include rfs(17.9375rem, max-width);
        text-align: left;
        .itemTitle {
          @include rfs(1.25rem);
          @include rfs(0.2rem, margin-bottom);

          color: var(--dark);
        }

        .itemDesc {
          @include rfs(0.875rem);

          font-weight: 600;
          color: var(--grey-cool);
        }
      }
    }
  }

  .btmPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .baseButton {
      color: var(--white);
      background: var(--dark);
    }

    .cancelButton {
      @include rfs(1.25rem, margin-right);

      background: var(--silver);
    }
  }
}
</style>
