// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MainContent_main_Z3y86{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column nowrap;flex-flow:column nowrap;margin:0 auto;min-height:calc(100vh - 6.25rem);overflow:visible}.MainContent_mainExtend_3DkML{margin-top:-8.25rem}.MainContent_iconBg_2CpVI{max-width:90rem;top:2rem;left:0;right:0;pointer-events:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.MainContent_iconBg_2CpVI,.MainContent_iconBgFaq_GB21k{position:absolute;margin:auto}.MainContent_iconBgFaq_GB21k{max-width:81.625rem;top:-3.75rem}.MainContent_content_3qs4n{-webkit-box-flex:1;-ms-flex:1;flex:1;z-index:1}", ""]);
// Exports
exports.locals = {
	"main": "MainContent_main_Z3y86",
	"mainExtend": "MainContent_mainExtend_3DkML",
	"iconBg": "MainContent_iconBg_2CpVI",
	"iconBgFaq": "MainContent_iconBgFaq_GB21k",
	"content": "MainContent_content_3qs4n"
};
module.exports = exports;
