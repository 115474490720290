const routes = [
  {
    path: "/individual/payment/card",
    name: "individual.payment.card",
    meta: {
      type: "credit_card_in_kyc",
      showBackBtn: true
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../../views/register/individual/creditCard/BillingAddress.vue"
      )
  },
  {
    path: "/individual//payment/creditCard/details",
    name: "individual.payment.creditCard.details",
    meta: {
      type: "credit_card_in_kyc",
      showBackBtn: true
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../../views/register/individual/creditCard/CreditCardDetails.vue"
      )
  },
  {
    path: "/individual//payment/creditCard/failed",
    name: "individual.payment.creditCard.failed",
    meta: {
      type: "credit_card_in_kyc",
      showBackBtn: true
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../../views/register/individual/creditCard/CreditCardFailed.vue"
      )
  },
  {
    path: "/individual//payment/creditCard/successed",
    name: "individual.payment.creditCard.successed",
    meta: {
      type: "credit_card_in_kyc",
      showBackBtn: true
    },
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../../views/register/individual/creditCard/CreditCardSuccessed.vue"
      )
  }
];

export default routes;
