<template>
  <section :class="$style.partnerList">
    <component :is="item" v-for="item in paymenList" :key="item" />
  </section>
</template>

<script>
import SWIFT from "@/assets/img/creditCard/swift.svg?inline";
import Sepa from "@/assets/img/creditCard/sepa.svg?inline";
import FasterPayments from "@/assets/img/creditCard/fasterPayments.svg?inline";
import VISA from "@/assets/img/creditCard/visa.svg?inline";
import MasterCard from "@/assets/img/creditCard/masterCard.svg?inline";
import Discover from "@/assets/img/creditCard/discover.svg?inline";
import Amex from "@/assets/img/creditCard/amex.svg?inline";
import JCB from "@/assets/img/creditCard/jcb.svg?inline";
//暂时不支持
// import GooglePay from "@/assets/img/creditCard/googlePay.svg?inline";
// import ApplePay from "@/assets/img/creditCard/applePay.svg?inline";
// import ACH from "@/assets/img/creditCard/ach.svg?inline";

export default {
  name: "SupportPaymentList",
  components: {
    SWIFT,
    Sepa,
    FasterPayments,
    VISA,
    MasterCard,
    Discover,
    Amex,
    JCB
    // GooglePay,
    // ApplePay,
    // ACH,
  },
  data() {
    return {
      paymenList: [
        "SWIFT",
        "Sepa",
        "FasterPayments",
        "VISA",
        "MasterCard",
        "Discover",
        "Amex",
        "JCB"
        // "GooglePay",
        // "ApplePay",
        // "ACH",
      ]
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  activated() {},
  deactivated() {}
};
</script>

<style lang="scss" module>
@import "~rfs/scss";
.partnerList {
  @include rfs(1rem, padding);

  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  z-index: 1;
  justify-content: center;
  width: 100%;

  svg {
    @include rfs(3.375rem, width);
    @include rfs(2.5rem, height);

    &:not(:last-child) {
      @include rfs(1.5625rem, margin-right);
    }
  }
}
</style>
