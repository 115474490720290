// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Index_tradeWrapper_3_2Lf>label{font-size:calc(1.28125rem + .375vw);margin-bottom:.4375rem;margin-left:calc(1.375rem + 1.5vw);display:block;font-weight:600;color:var(--legend-c-text);line-height:2.1875rem}@media(min-width:1200px){.Index_tradeWrapper_3_2Lf>label{font-size:1.5625rem;margin-left:2.5rem}}", ""]);
// Exports
exports.locals = {
	"tradeWrapper": "Index_tradeWrapper_3_2Lf"
};
module.exports = exports;
