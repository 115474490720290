// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PoweredBy_powered_2z5xm{font-size:.75;font-weight:700;color:var(--legend-c-text);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer}.PoweredBy_powered_2z5xm svg{min-width:calc(1.9375rem + 8.25vw);height:auto;margin-left:.5625rem}@media(min-width:1200px){.PoweredBy_powered_2z5xm svg{min-width:8.125rem}}", ""]);
// Exports
exports.locals = {
	"powered": "PoweredBy_powered_2z5xm"
};
module.exports = exports;
