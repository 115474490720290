<template>
  <div class="alertMessageModal" v-if="visible">
    <div class="alertMessageBox">
      <div class="alertMessageContent">
        <div class="message">{{ title ? title : "Oops" }}</div>
        <div class="desc" v-if="message">{{ message }}</div>
        <div class="desc" v-else>
          We are sorry, but something went wrong. <br />
          Please try again later.
        </div>
        <div class="btnDismiss" @click="hide">DISMISS</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertMessage",
  components: {},
  props: {
    duration: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      visible: false,
      message: "",
      type: "error",
      title: "",
      callback: {}
    };
  },
  methods: {
    show() {
      this.visible = true;
      // setTimeout(this.hide, this.duration);
    },
    hide() {
      this.visible = false;
      if (this.callback) {
        this.callback();
      }
    },
    success(text, title = "", cb) {
      this.type = "success";
      this.message = text;
      this.title = title;
      this.callback = cb;
      this.show();
    },
    error(text, title = "", cb) {
      this.type = "error";
      this.message = text;
      this.title = title;
      this.callback = cb;
      this.show();
    },
    warning(text, title = "", cb) {
      this.type = "warning";
      this.message = text;
      this.title = title;
      this.callback = cb;
      this.show();
    }
  }
};
</script>

<style></style>
