import {
  UPDATE_WIRE_STEP_INDEX,
  UPDATE_WIRE_CURRENCY,
  UPDATE_ACH_STEP_INDEX,
  UPDATE_ACH_CONFIRM_DATA,
  UPDATE_NEED_ADD_BANK,
  UPDATE_ACH_PUSH_STEP_INDEX
} from "./mutation-types";

// inital state
const state = {
  wireStepIndex: 0,
  wireCurrency4Deposit: "",
  wireViewList: [
    {
      stepIndex: 0,
      view: "ChooseCurrency",
      title: "Select funds source"
    },
    {
      stepIndex: 1,
      view: "ConfirmDeposit",
      title: "Complete deposit"
    },
    {
      stepIndex: 2,
      view: "DepositFinished",
      title: ""
    }
  ],
  achStepIndex: 0,
  achViewList: [
    {
      stepIndex: 0,
      view: "SelectPayment"
    },
    {
      stepIndex: 1,
      view: "ConfirmDeposit"
    },
    {
      stepIndex: 2,
      view: "DepositSucceed"
    },
    {
      stepIndex: 3,
      view: "DepositFailed"
    }
  ],
  achConfirmData: {
    amount: "",
    from: "",
    to: "",
    type: "",
    payment_method_id: ""
  },
  achPushStepIndex: 0,
  achPushViewList: [
    {
      stepIndex: 0,
      view: "ConfirmDeposit",
      title: "Complete deposit"
    },
    {
      stepIndex: 1,
      view: "DepositFinished",
      title: ""
    }
  ],
  needAddBank: false
};
// getters
const getters = {};
// actions
const actions = {
  updateWireStepIndex({ commit }, value) {
    commit(UPDATE_WIRE_STEP_INDEX, value);
  },
  updateWireCurrency4Deposit({ commit }, data) {
    commit(UPDATE_WIRE_CURRENCY, data);
  },
  updateAchStepIndex({ commit }, value) {
    commit(UPDATE_ACH_STEP_INDEX, value);
  },
  updateAchConfirmData({ commit }, data) {
    commit(UPDATE_ACH_CONFIRM_DATA, data);
  },
  updateNeedAddBank({ commit }, value) {
    commit(UPDATE_NEED_ADD_BANK, value);
  },
  updateAchPushStepIndex({ commit }, value) {
    commit(UPDATE_ACH_PUSH_STEP_INDEX, value);
  }
};
// mutations
const mutations = {
  [UPDATE_WIRE_STEP_INDEX](state, value) {
    state.wireStepIndex = value;
  },
  [UPDATE_WIRE_CURRENCY](state, value) {
    state.wireCurrency4Deposit = value;
  },
  [UPDATE_ACH_STEP_INDEX](state, value) {
    state.achStepIndex = value;
  },
  [UPDATE_ACH_CONFIRM_DATA](state, data) {
    state.achConfirmData = { ...state.achConfirmData, ...data };
  },
  [UPDATE_NEED_ADD_BANK](state, value) {
    state.needAddBank = value;
  },
  [UPDATE_ACH_PUSH_STEP_INDEX](state, value) {
    state.achPushStepIndex = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
