// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModalBase_modal_25NYk .ant-modal-content{background:transparent;-webkit-box-shadow:none;box-shadow:none}.ModalBase_modal_25NYk .ant-modal-body{padding:0}", ""]);
// Exports
exports.locals = {
	"modal": "ModalBase_modal_25NYk"
};
module.exports = exports;
