<template>
  <section :class="$style.buyConfirm">
    <section class="content">
      <slot name="panel_header" />
      <OrderSummary v-if="!isAchPullProcess" />

      <keep-alive>
        <router-view />
      </keep-alive>
    </section>
  </section>
</template>
<script>
import { commonMixin } from "@/mixin/commonMixin";
import { quoteMixin } from "@/mixin/quoteMixin";
import OrderSummary from "@/components/OrderSummary.vue";

export default {
  name: "OrderConfirmPanel",
  mixins: [commonMixin, quoteMixin],
  props: {},
  data() {
    return {
      imgSrc: ""
    };
  },
  computed: {
    isAchPullProcess() {
      // 只有trade相关的流程需要判断，跟其他payment和deposit没有关系
      return (
        this.tradeBuyAchPullProcess() || this.tradeBuyAchPullInKycProcess()
      );
    }
  },
  components: {
    OrderSummary
  },
  methods: {},
  activated() {},
  deactivated() {
    this.stopQuote();
  }
};
</script>

<style module lang="scss">
@import "~rfs/scss";

.buyConfirm {
}
</style>
