// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TradeTabs_tabsList_1gykd{margin-bottom:1.25rem;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"tabsList": "TradeTabs_tabsList_1gykd"
};
module.exports = exports;
