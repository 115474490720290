const routes = [
  {
    path: "",
    name: "individual.buyConfirm",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../../views/register/individual/PaymentList.vue"
      ),
    meta: {
      type: "achPull_in_kyc",
      showBackBtn: false
    }
  },
  {
    path: "payment/bankinfo",
    name: "individual.trade.buy.payment.bankinfo",
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../../../views/register/individual/EnterBankInfo.vue"
      )
  },
  {
    path: "payment/result",
    name: "individual.trade.buy.payment.result",
    component: () =>
      import(
        /* webpackChunkName: "trade" */ "../../../views/register/individual/PaymentResult.vue"
      )
  }
];

export default routes;
